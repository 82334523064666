import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import styles from "./ButtonSend.module.scss";

export const ButtonSend = ({ onClick }: any) => {
  return (
    <div className={styles.sendBtn} onClick={onClick}>
      <PaperAirplaneIcon />
    </div>
  );
};
