import { FunctionComponent, ReactNode } from "react";
import { TextContainer, PropsTextContainer } from "../TextContainer";
import { ImageContainer, PropsImage } from "../ImageContainer";
import Image from "../../../atoms/Image";
import Text from "../../../atoms/Text";
import styles from "./Card.module.scss";
import Form from "../Form";
export interface PropsCard extends PropsTextContainer, PropsImage {
  intro?: boolean;
  isHalf?: boolean;
  isSmall?: boolean;
  isMicro?: boolean;
  textRight?: boolean;
  bgImage?: string;
  index?: number;
  smallTitle?: string;
  iconTitle?: ReactNode | ReactNode[];
  iconTitleSrc?: string | undefined;
  titleListType?: string;
  listPoints?: [{ title?: string; points: string[] | any[] }];
  hasForm?: boolean;
  cardStains?: string[] | undefined;
}
export const Card: FunctionComponent<PropsCard> = (props) => {
  const stains = props.cardStains;
  const setClassNames = (props: PropsCard): string => {
    const result: string[] = [];
    if (!props.isHalf && !props.isSmall) result.push("full-card");
    if (props.isHalf) result.push("half-card");
    if (props.isSmall) result.push("small-card");
    if (props.textRight) result.push("text-right");
    if (props.isMicro) result.push("micro-card");

    return result.join(" ");
  };
  return (
    <div
      className={`${styles.card} ${setClassNames(props)}`}
      style={{ backgroundImage: props.bgImage && `url('${props.bgImage}')` }}
    >
      {/* {props.index && (
        <Text
          type="h6"
          className={styles.index}
          children={props.index.toString()}
        />
      )} */}

      {props.isSmall && (
        <div className={styles.cardHead}>
          {props.iconTitleSrc && <Image src={props.iconTitleSrc} alt="" />}
          {props.iconTitle && (
            <div className={styles.iconCard}>{props.iconTitle}</div>
          )}
          {props.smallTitle && (
            <Text type={props.titleType} children={props.smallTitle} />
          )}
        </div>
      )}
      <TextContainer
        intro={props.intro}
        titleType={props.titleType}
        title={props.title}
        subtitleType={props.subtitleType}
        subtitle={props.subtitle}
        paragraphs={props.paragraphs}
        link={props.link}
        buttonTxt={props.buttonTxt}
        label={props.label}
        leftIconButton={props.leftIconButton}
        rightIconButton={props.rightIconButton}
        iconLeftLink={props.iconLeftLink}
        iconRigthLink={props.iconRigthLink}
        listPoints={props.listPoints}
        titleListType={props.titleListType}
        textStains={props.textStains}
        onClick={props.onClick}
      />
      {props.cardStains &&
        stains?.map((stain, index) => {
          return (
            <Image key={index} className={styles.stain} src={stain} alt="" />
          );
        })}
      {(props.src || props.srcList) && (
        <ImageContainer
          srcList={props.srcList}
          src={props.src}
          alt="image"
          imageStains={props.imageStains}
        />
      )}
      {props.hasForm && <Form />}
    </div>
  );
};
