import styles from "./Privacy.module.scss";
import { analyticsService } from "../../utils/analyticsService";
import { useEffect } from "react";

export const GeneralPrivacy = ({ className }) => {

  useEffect(() => {
    analyticsService({'event': 'virtualPageview', 'pageUrl': '/privacy-generale', 'pageTitle': 'General Privacy'});
  }, []);

  return (
    <div className={`${styles.privacy} ${className}`}>
      <h2>INFORMATIVA PRIVACY</h2>
      <div className={styles.subtitle}>
        La presente informativa privacy ha lo scopo di fornire tutte le
        informazioni sul trattamento dei dati personali effettuato da
        <span> Mymentis S.r.l. </span>quando l’Utente accede e utilizza la
        piattaforma MyMentis (come di seguito meglio indicato).
      </div>
      <div className={styles.articlesWrapper}>
        <div className={styles.article}>
          <div className={styles.articleTitle}>
            1. INTRODUZIONE - CHI SIAMO?
          </div>
          <div className={styles.articleDescription}>
            <span>Mymentis S.r.l.</span>, con sede legale in Varazze (SV), via
            Montegrappa n. 43, 17019, C.F. e P. IVA 01886110095 (di seguito,
            “Titolare” o “MyMentis”), proprietario della piattaforma online
            disponibile al link:{" "}
            <a href="https://mymentis.it/">https://mymentis.it/</a> (di seguito,
            la “Piattaforma”), in qualità di titolare del trattamento dei dati
            personali degli utenti che utilizzano la Piattaforma (di seguito,
            “Utenti”) fornisce qui di seguito l’informativa privacy ai sensi
            dell’art. 13 del Regolamento UE 2016/679 del 27 aprile 2016 (di
            seguito, <span>“Regolamento”</span>, o{" "}
            <span>“Normativa Applicabile”</span>).
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>2. COME CONTATTARCI?</div>
          <div className={styles.articleDescription}>
            Il Titolare tiene nella massima considerazione il diritto alla
            privacy ed alla protezione dei dati personali dei propri Utenti. Per
            ogni informazione in relazione alla presente informativa privacy,
            gli Utenti possono <span>contattare il Titolare</span> in qualsiasi
            momento, utilizzando le seguenti modalità:
            <ul>
              <li>
                Inviando una raccomandata a/r alla sede legale del Titolare in
                Varazze (SV), via Montegrappa n. 43, c.a.p. 17019;
              </li>
              <li>
                Inviando un messaggio di posta elettronica all’indirizzo{" "}
                <a href="mailto:privacy@mymentis.it">privacy@mymentis.it</a>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>
            3. COSA FACCIAMO? – FINALITÀ DEL TRATTAMENTO
          </div>
          <div className={styles.articleDescription}>
            <div>
              Tramite la Piattaforma, l’Utente può richiedere di essere messo in
              contatto con professionisti esperti nel settore sanitario tra
              psicologi, psicoterapeuti e psichiatri (di seguito, il
              “Professionista”) per ricevere dal Professionista prestazioni
              professionali inerenti il supporto psicologico e psicoterapeutico
              a distanza, utilizzando le funzionalità e gli strumenti
              informatici disponibili nella Piattaforma. Al riguardo, si precisa
              che la presente informativa si applica esclusivamente alle
              attività di trattamento poste in essere dal Titolare nel
              perseguimento delle finalità di seguito indicate e sono escluse
              quelle attività di trattamento effettuate nel perseguimento di
              finalità che il Professionista determina autonomamente o
              congiuntamente con MyMentis, le quali saranno disciplinate dalle
              rispettive informative privacy che vengono messe a disposizione
              dei soggetti interessati tramite apposite sezioni della
              Piattaforma.
            </div>
            <br />
            <div>
              In particolare, i dati personali degli Utenti saranno trattati
              lecitamente dal Titolare per le seguenti finalità di trattamento:
            </div>
            <ol className={styles.listLetter}>
              <li>
                <span className={styles.underline}>
                  Obblighi contrattuali e fornitura del servizio
                </span>
                , per permettere l’Utilizzo della Piattaforma ovvero per dare
                esecuzione alle Condizioni d’Uso, che sono accettate dall’Utente
                in fase di registrazione sulla Piattaforma e adempiere a
                specifiche richieste dell'Utente. I dati personali raccolti dal
                Titolare ai fini della registrazione alla Piattaforma: nome e
                cognome, età, sesso, indirizzo e-mail, numero di cellulare,
                nonché tutte le informazioni personali eventualmente e
                volontariamente inserite dall’Utente in Piattaforma. Fermo
                restando quanto previsto altrove in questa informativa privacy,
                in nessun caso il Titolare renderà accessibili a terzi i Suoi
                dati personali;
              </li>
              <li>
                <span className={styles.underline}>
                  finalità amministrativo-contabili
                </span>
                , ovvero per svolgere attività di natura organizzativa,
                amministrativa, finanziaria e contabile, quali attività
                organizzative interne ed attività funzionali all'adempimento di
                obblighi contrattuali e precontrattuali;
              </li>
              <li>
                <span className={styles.underline}>obblighi di legge</span>,
                ovvero per adempiere ad obblighi previsti dalla legge, da
                un’autorità, da un regolamento o dalla normativa europea.
              </li>
            </ol>
            <br />
            <div>
              Il conferimento dei dati personali per le finalità di trattamento
              sopra indicate è facoltativo ma necessario, poiché il mancato
              conferimento degli stessi comporterà l’impossibilità per l’Utente
              di utilizzare e usufruire dei servizi della Piattaforma.
            </div>
            <br />
            <div>
              I dati personali che sono necessari per il perseguimento delle
              finalità di trattamento descritte al presente paragrafo 3 sono
              indicati con un asterisco all’interno del modulo di registrazione
              sul Sito.
            </div>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>
            4.ULTERIORI FINALITÀ DI TRATTAMENTO
          </div>
          <div className={styles.subArticle}>4.1 Newsletter</div>
          <div className={styles.articleDescription}>
            <div>
              Alcuni dati personali dell’Utente (ovvero il nome e l’indirizzo di
              posta elettronica) potranno essere trattati dal Titolare anche per
              finalità di invio della newsletter. Pertanto, l’Utente riceverà
              dal Titolare una newsletter periodica che conterrà informazioni in
              relazione ai servizi e alle opportunità disponibili in
              Piattaforma.
            </div>
            <br />
            <div>
              In caso di mancato consenso, la possibilità di iscriversi alla
              Piattaforma non sarà in alcun modo pregiudicata.
            </div>
            <br />
            <div>
              In caso di consenso, l’Utente potrà in qualsiasi momento revocare
              lo stesso, facendone richiesta al Titolare con le modalità
              indicate al successivo paragrafo 8.
            </div>
            <br />
            <div>
              L’Utente potrà inoltre opporsi facilmente ad ulteriori invii di
              comunicazioni via e-mail anche cliccando sull’apposito link per la
              revoca del consenso, che è presente in ciascuna e-mail contenente
              la newsletter. Effettuata la revoca del consenso, il Titolare
              invierà all’Utente un messaggio di posta elettronica per
              confermare l’avvenuta revoca del consenso.
            </div>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>5. BASE GIURIDICA</div>
          <div className={styles.articleDescription}>
            <div>
              <span className={styles.underline}>
                Obblighi contrattuali e fornitura del Servizio
              </span>{" "}
              (così come descritto dal precedente par. 3, lett. a)): la base
              giuridica consiste nell’art. 6, comma 1, lett. b) del Regolamento,
              ovvero il trattamento è necessario all’esecuzione di un contratto
              di cui l’Utente è parte o all’esecuzione di misure precontrattuali
              adottate su richiesta dello stesso.
            </div>
            <br />
            <div>
              <span className={styles.underline}>
                Finalità amministrativo-contabili
              </span>{" "}
              (così come descritto dal precedente par. 3, lett. b)): la base
              giuridica consiste nell’art. 6, comma 1, lett. b) del Regolamento,
              in quanto il trattamento è necessario all’esecuzione di un
              contratto e/o all’esecuzione di misure precontrattuali adottate su
              richiesta dell’Utente.
            </div>
            <br />
            <div>
              <span className={styles.underline}>Obblighi di legge</span> (così
              come descritto dal precedente par. 3, lett. c)): la base giuridica
              consiste nell’art. 6, comma 1, lett. c) del Regolamento, in quanto
              il trattamento è necessario per adempiere un obbligo legale al
              quale è soggetto il Titolare del trattamento.
            </div>
            <br />
            <div>
              <span className={styles.underline}>
                Ulteriori finalità di trattamento
              </span>{" "}
              per il trattamento relativo alle attività di invio della
              newsletter, (così come descritto dal precedente par. 4), la base
              giuridica consiste nell’art. 6, comma 1, lett. a) del Regolamento,
              ovvero la prestazione da parte dell’interessato del consenso al
              trattamento dei propri dati personali per una o più specifiche
              finalità. Per questo motivo il Titolare chiede all’Utente la
              prestazione di uno specifico consenso libero e facoltativo, per
              perseguire tale finalità di trattamento.
            </div>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>
            6. MODALITÀ DI TRATTAMENTO E TEMPI DI CONSERVAZIONE DEI DATI
          </div>
          <div className={styles.articleDescription}>
            <div>
              Il Titolare tratterà i dati personali degli Utenti mediante
              strumenti manuali ed informatici, con logiche strettamente
              correlate alle finalità stesse e, comunque, in modo da garantire
              la sicurezza e la riservatezza dei dati stessi.
            </div>
            <br />
            <div>
              I dati personali degli Utenti della Piattaforma saranno conservati
              per i tempi strettamente necessari ad espletare le finalità
              primarie illustrate al precedente paragrafo 3, o comunque secondo
              quanto necessario per la tutela in sede civilistica degli
              interessi sia degli Utenti che del Titolare.
            </div>
            <br />
            <div>
              Nel caso di cui al precedente paragrafo 4 i dati personali degli
              Utenti saranno conservati per i tempi strettamente necessari ad
              espletare le finalità illustrate nello stesso e, in ogni caso,
              fintanto che l’Utente non revochi il proprio consenso.
            </div>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>
            7. AMBITO DI COMUNICAZIONE E DIFFUSIONE DEI DATI
          </div>
          <div className={styles.articleDescription}>
            <div>
              I dati personali dell’Utente potranno essere trasferiti al di
              fuori dell’Unione Europea e, in tal caso, il Titolare si
              assicurerà che il trasferimento avvenga in conformità alla
              Normativa Applicabile e, in particolare, in conformità agli artt.
              45 (Trasferimento sulla base di una decisione di adeguatezza) e 46
              (Trasferimento soggetto a garanzie adeguate) del Regolamento.
            </div>
            <br />
            <div>
              Potranno venire a conoscenza dei dati personali degli Utenti i
              dipendenti e/o collaboratori del Titolare incaricati di gestire il
              Sito e le richieste degli Utenti. Tali soggetti, che sono stati
              istruiti in tal senso dal Titolare ai sensi dell’art. 29 del
              Regolamento, tratteranno i dati degli Utenti esclusivamente per le
              finalità indicate nella presente informativa e nel rispetto delle
              previsioni della Normativa Applicabile.
            </div>
            <br />
            <div>
              Potranno inoltre venire a conoscenza dei dati personali degli
              Utenti i soggetti terzi che potranno trattare dati personali per
              conto del Titolare in qualità di Responsabili del Trattamento,
              quali, a titolo esemplificativo, fornitori di servizi informatici
              e logistici funzionali all’operatività del Sito, fornitori di
              servizi in outsourcing o cloud computing, professionisti e
              consulenti.
            </div>
            <br />
            <div>
              Gli Utenti hanno il diritto di ottenere una lista degli eventuali
              responsabili del trattamento nominati dal Titolare, facendone
              richiesta al Titolare con le modalità indicate al successivo
              paragrafo 8.
            </div>
            <br />
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>
            8. DIRITTI DEGLI INTERESSATI
          </div>
          <div className={styles.articleDescription}>
            <div>
              Gli Utenti potranno esercitare i diritti garantiti loro dalla
              Normativa Applicabile, contattando il Titolare con le seguenti
              modalità:
            </div>
            <ul>
              <li>
                Inviando una raccomandata a/r alla sede legale del Titolare in
                Varazze (SV), via Montegrappa n. 43, c.a.p. 17019;
              </li>
              <li>
                I Inviando un messaggio di posta elettronica all’indirizzo:{" "}
                <span className={styles.underline}>
                  <a href="'mailto:privacy@mymentis.it">privacy@mymentis.it</a>
                </span>
              </li>
            </ul>
            <br />
            <div>
              Ai sensi della Normativa Applicabile, il Titolare informa che gli
              Utenti hanno il diritto di ottenere l'indicazione (i) dell'origine
              dei dati personali; (ii) delle finalità e modalità del
              trattamento; (iii) della logica applicata in caso di trattamento
              effettuato con l'ausilio di strumenti elettronici; (iv) degli
              estremi identificativi del titolare e dei responsabili; (v) dei
              soggetti o delle categorie di soggetti ai quali i dati personali
              possono essere comunicati o che possono venirne a conoscenza in
              qualità di responsabili o incaricati.
            </div>
            <br />
            <div>Inoltre, gli Utenti hanno il diritto di ottenere:</div>
            <ol className={styles.listLetter}>
              <li>
                l’<span>accesso</span>, l’<span>aggiornamento</span>, la{" "}
                <span>rettificazione</span> ovvero, quando vi hanno interesse,
                l’<span>integrazione</span> dei dati;
              </li>
              <li>
                la <span>cancellazione</span>, la{" "}
                <span>trasformazione in forma anonima</span> o la{" "}
                <span>limitazione</span> dei dati trattati in violazione di
                legge, compresi quelli di cui non è necessaria la conservazione
                in relazione agli scopi per i quali i dati sono stati raccolti o
                successivamente trattati;
              </li>
              <li>
                l’attestazione che le operazioni di cui alle lettere a) e b)
                sono state portate a conoscenza, anche per quanto riguarda il
                loro contenuto, di coloro ai quali i dati sono stati comunicati
                o diffusi, eccettuato il caso in cui tale adempimento si rivela
                impossibile o comporta un impiego di mezzi manifestamente
                sproporzionato rispetto al diritto tutelato.
              </li>
            </ol>
            <div>Inoltre, gli Utenti hanno:</div>
            <ol className={styles.listLetter}>
              <li>
                il diritto di <span>revocare il consenso</span> in qualsiasi
                momento, qualora il trattamento si basi sul loro consenso;
              </li>
              <li>
                il diritto alla <span>portabilità dei dati</span> (diritto di
                ricevere tutti i dati personali che li riguardano in un formato
                strutturato, di uso comune e leggibile da dispositivo
                automatico);
              </li>
              <li>
                <span>il diritto di opporsi:</span>
                <ol className={styles.listRomanNumber}>
                  <li>
                    in tutto o in parte, per motivi legittimi al trattamento dei
                    dati personali che li riguardano, ancorché pertinenti allo
                    scopo della raccolta;
                  </li>
                  <li>
                    in tutto o in parte, al trattamento di dati personali che li
                    riguardano a fini di invio di materiale pubblicitario o di
                    vendita diretta o per il compimento di ricerche di mercato o
                    di comunicazione commerciale;
                  </li>
                </ol>
              </li>
              <li>
                qualora ritenessero che il trattamento che li riguarda violi il
                Regolamento, il diritto di{" "}
                <span>proporre reclamo a un’Autorità di controllo</span> (nello
                Stato membro in cui risiedono abitualmente, in quello in cui
                lavorano oppure in quello in cui si è verificata la presunta
                violazione). L’Autorità di controllo italiana è il{" "}
                <span>Garante per la protezione dei dati personali</span>, con
                sede in Piazza Venezia n. 11, 00187 – Roma (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open("http://www.garanteprivacy.it/", "_blank")
                  }
                  className={styles.underline}
                >
                  http://www.garanteprivacy.it/
                </span>
                ).
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        Il Titolare non è responsabile per l’aggiornamento di tutti i link
        visualizzabili nella presente Informativa; pertanto, ogni qualvolta un
        link non sia funzionante e/o aggiornato, gli Utenti riconoscono ed
        accettano che dovranno sempre far riferimento al documento e/o sezione
        dei siti internet richiamati da tale link.
      </div>
    </div>
  );
};
