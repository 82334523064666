const formatDate = (date) => {
  return date.toISOString().split('T')[0];
};

const generateDates = () => {
  const dates: any = [];
  const today = new Date();
  for (let i = 1; i <= 14; i += 1) {
    const date = new Date(today);
    date.setDate(today.getDate() + i);
    dates.push(date);
  }
  return dates;
};

const filterDatesByDaySlots = (dates, daySlotsData) => {
  const daySlotMap = {
    week: [1, 2, 3, 4, 5], // Monday to Friday
    saturday: [6], // Saturday
    sunday: [0] // Sunday
  };

  return dates.filter(date => {
    const day = date.getDay();
    return daySlotsData.some(slot => daySlotMap[slot].includes(day));
  });
};

const generateTimeSlots = (timeSlotsData) => {
  const timeSlotMap = {
    morning: ['08:00', '09:00', '10:00', '11:00', '12:00'],
    afternoon: ['13:00', '14:00', '15:00', '16:00', '17:00'],
    late_afternoon: ['18:00', '19:00'],
    evening: ['20:00', '21:00', '22:00']
  };

  return timeSlotsData.reduce((acc, slot) => {
    return acc.concat(timeSlotMap[slot]);
  }, []);
};

const filterTimeSlots = (date, timeSlotsData, appointments, offlineAppointments) => {
  const now = new Date();
  const currentHour = now.getHours();
  const currentMinute = now.getMinutes();
  const nextDayTimeBoundary = new Date(now);
  nextDayTimeBoundary.setDate(now.getDate() + 1);
  nextDayTimeBoundary.setHours(currentHour, currentMinute, 0, 0);

  return timeSlotsData.reduce((acc, time) => {
    const [hours, minutes] = time.split(':').map(Number);
    const dateTime = new Date(date);
    dateTime.setHours(hours, minutes, 0, 0);

    // Skip time slots next 24 hours from now
    if (dateTime < nextDayTimeBoundary) {
      return acc;
    }

    let available = true;
    let offline = false;

    // Filter based on appointments data
    const hasConflictingAppointment = appointments.some(appointment => {
      const start = new Date(appointment.startTime);
      const end = new Date(appointment.endTime);

      if (formatDate(start) !== formatDate(date)) {
        return false;
      }

      const startHour = start.getHours();
      const startMinute = start.getMinutes();
      const endHour = end.getHours();
      const endMinute = end.getMinutes();

      return (hours === startHour && startMinute > 0) ||
        (hours === endHour && endMinute > 0) ||
        (dateTime >= start && dateTime < end) ||
        (dateTime.getTime() > start.getTime() && dateTime < end);
    });

    // Filter based on offline appointments
    const hasConflictingOfflineAppointment = offlineAppointments.some(offlineAppointment => {
      const offlineDateTime = new Date(offlineAppointment.dateTime);
      const offlineDayOfWeek = offlineDateTime.getDay();
      const offlineHour = offlineDateTime.getHours();

      if (offlineAppointment.occurrence === 'once') {
        if (formatDate(offlineDateTime) !== formatDate(date)) {
          return false;
        }
        if (offlineHour === hours) {
          offline = true;
          return true;
        }
      } else if (offlineAppointment.occurrence === 'every_week') {
        if (offlineDayOfWeek !== date.getDay()) {
          return false;
        }
        if (offlineHour === hours) {
          offline = true;
          return true;
        }
      }

      return false;
    });

    available = available && !hasConflictingAppointment && !hasConflictingOfflineAppointment;

    acc.push({ time, available, offline });
    return acc;
  }, []);
};

// Sort time slots in ascending order
const sortTimeSlots = (timeSlots) => {
  return timeSlots.sort((a, b) => {
    const [aHours, aMinutes] = a.time.split(':').map(Number);
    const [bHours, bMinutes] = b.time.split(':').map(Number);
    return (aHours * 60 + aMinutes) - (bHours * 60 + bMinutes);
  });
};

// Function to get available dates and times
export const getAvailableDates = (daySlots, timeSlots, appointments, offlineAppointments) => {
  const dates = generateDates();
  const filteredDates = filterDatesByDaySlots(dates, daySlots);
  const availableDates: any = [];

  filteredDates.forEach(date => {
    const timeSlotsForDay = generateTimeSlots(timeSlots);
    const availableTimeSlots = filterTimeSlots(date, timeSlotsForDay, appointments, offlineAppointments);
    const sortedTimeSlots = sortTimeSlots(availableTimeSlots);
    if (sortedTimeSlots.length > 0) {
      availableDates.push({
        day: formatDate(date),
        availableTime: sortedTimeSlots
      });
    }
  });

  return availableDates;
};
