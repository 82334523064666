import { FunctionComponent, ReactNode, useState, useEffect } from "react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import Text from "../../../atoms/Text";
import styles from "./Accordion.module.scss";

interface PropsAccordion {
  id?: string;
  title: string;
  titleType: string;
  children?: ReactNode;
  accordionSelected?: string;
}

export const Accordion: FunctionComponent<PropsAccordion> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean | null>(null);

  const handlerAccordion = () => {
    setIsOpen(!isOpen);
  };

  const iconRotation = (accordionState: boolean | null): string => {
    if (accordionState) return `${styles.iconOpen}`;
    if (!accordionState) return `${styles.iconClose}`;
    return "";
  };
  useEffect(() => {
    if (props.title === props.accordionSelected) {
      setIsOpen(!isOpen);
    } else {
      setIsOpen(false);
    }
  }, [props.accordionSelected]);

  return (
    <div id={props.id} className={styles.accordion}>
      <div className={styles.title} onClick={handlerAccordion}>
        <Text type={props.titleType} children={props.title} />
        <ChevronRightIcon className={iconRotation(isOpen)} />
      </div>
      {isOpen && <div className={styles.content}>{props.children}</div>}
    </div>
  );
};
