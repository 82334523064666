import styles from "./Sidebar.module.scss";

interface Props {
  children?: any;
  className?: string;
  active?: boolean;
  onClick?: () => void;
  leftIcon?: any;
  badge?: any;
}

export const Sidebar = ({
  children,
  className = "",
  onClick,
  active = false,
  leftIcon = "",
  badge = "",
}: Props) => {
  return (
    <div className={`${styles.sidebar} ${className}`} onClick={onClick}>
      <div className={`${styles.leftSide} ${active && styles.active}`}>
        {leftIcon && <div className={styles.icon}>{leftIcon}</div>}
        <div className={styles.text}>{children}</div>
      </div>
      {badge && <div className={styles.badge}>{badge}</div>}
    </div>
  );
};
