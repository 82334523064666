import { useEffect, useState } from "react";
import styles from "./Faq.module.scss";
import FaqSection from "../../components/landingPageComponents/sections/FaqSection";
import Footer from "../../components/landingPageComponents/components/Footer";
import IntroductorySection from "../../components/landingPageComponents/sections/IntroductorySection";
import {
  IntroductoryFaqContent,
  SimpleSectionContent,
  FaqStains,
} from "./FaqContentText";
import SimpleSection from "../../components/landingPageComponents/sections/SimpleSection";
import { Helmet } from "react-helmet";
import { envs } from "utils/envs";
import routes from "routes";
import { analyticsService } from "../../utils/analyticsService";

export const FaqPage = () => {
  const [faqSelected, setFaqSelected] = useState("");

  useEffect(() => {
    analyticsService({'event': 'virtualPageview', 'pageUrl': '/faq', 'pageTitle': 'Faq Page'});
  }, []);

  const setTopicQuestion = (topic: string) => {
    const element = document.getElementById(topic);
    if (faqSelected === "" || faqSelected !== topic) {
      setFaqSelected(topic);
      if (element) {
        element.scrollIntoView({ behavior: "auto" });
      }
    } else if (topic === faqSelected) {
      setFaqSelected("");
    }
  };
  return (
    <main>
      <Helmet>
        <title>FAQ</title>
        <meta
          name="description"
          content="Qui troverai tutte le risposte ai tuoi dubbi."
        />
        <meta property="og:title" content="FAQ" />
        <meta
          property="og:description"
          content="Qui troverai tutte le risposte ai tuoi dubbi."
        />
        {envs.REACT_APP_ENVIRONMENT === "Prod" ? (
          <link
            rel="canonical"
            href={`https://mymentis.it${routes.FaqPage.path}`}
          />
        ) : null}
      </Helmet>
      <div className={styles.faq}>
        <IntroductorySection
          content={IntroductoryFaqContent}
          cardStains={FaqStains.introductorySection.cardStains}
          hasLabel
        />
        <SimpleSection
          content={SimpleSectionContent}
          handlerLabel={setTopicQuestion}
        />
        <FaqSection faqSelected={faqSelected} />
        <Footer />
      </div>
    </main>
  );
};
