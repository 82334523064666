import {
  CalendarDaysIcon,
  ClockIcon,
  HeartIcon,
    ChatBubbleBottomCenterIcon,
} from "@heroicons/react/24/solid";
import Button from "../../atoms/Button";
import introGreenShape from "theme/images/introGreenShape.png";
import introPurpleShape from "theme/images/registerPurpleShape.png";
import CardReview from "../../molecules/CardReview";
import Carousel from "../../molecules/Carousel";
import styles from "./IntroSurvey.module.scss";
import routes from "routes";
import { useNavigate } from 'react-router-dom'
import { useEffect } from "react";
import { analyticsService } from "../../../utils/analyticsService";
import { envs } from "../../../utils/envs";

const appSiteUrl = envs.REACT_APP_SITE_URL;

export const IntroSurvey = ({ startSurvey }: any) => {
  const navigate = useNavigate();
  const reviewList = [
    {
      vote: 1,
      reviewTitle: "1 Grazie a Mymentis ora sto davvero bene con me stessa",
      reviewText:
        "Mi sta aiutando molto a riflettere e capire davvero cosa vorrei nella mia vita. È una bellissima persona con cui mi sono sentita da subito a mio agio. Mi sta aiutando molto a riflettere e capire davvero cosa vorrei nella mia vita. È una bellissima persona con cui mi sono sentita da subito a mio agio.",
    },
    {
      vote: 2,
      reviewTitle: "2 Grazie a Mymentis ora sto davvero bene con me stessa",
      reviewText:
        "Mi sta aiutando molto a riflettere e capire davvero cosa vorrei nella mia vita. È una bellissima persona con cui mi sono sentita da subito a mio agio.",
    },
    {
      vote: 3,
      reviewTitle: "3 Grazie a Mymentis ora sto davvero bene con me stessa",
      reviewText:
        "Mi sta aiutando molto a riflettere e capire davvero cosa vorrei nella mia vita. È una bellissima persona con cui mi sono sentita da subito a mio agio.",
    },
    {
      vote: 3,
      reviewTitle: "4 Grazie a Mymentis ora sto davvero bene con me stessa",
      reviewText:
        "Mi sta aiutando molto a riflettere e capire davvero cosa vorrei nella mia vita. È una bellissima persona con cui mi sono sentita da subito a mio agio.",
    },
    {
      vote: 4,
      reviewTitle: "5 Grazie a Mymentis ora sto davvero bene con me stessa",
      reviewText:
        "Mi sta aiutando molto a riflettere e capire davvero cosa vorrei nella mia vita. È una bellissima persona con cui mi sono sentita da subito a mio agio.",
    },
    {
      vote: 5,
      reviewTitle: "6 Grazie a Mymentis ora sto davvero bene con me stessa",
      reviewText:
        "Mi sta aiutando molto a riflettere e capire davvero cosa vorrei nella mia vita. È una bellissima persona con cui mi sono sentita da subito a mio agio.",
    },
    {
      vote: 1,
      reviewTitle: "7 Grazie a Mymentis ora sto davvero bene con me stessa",
      reviewText:
        "Mi sta aiutando molto a riflettere e capire davvero cosa vorrei nella mia vita. È una bellissima persona con cui mi sono sentita da subito a mio agio.",
    },
    {
      vote: 2,
      reviewTitle: "8 Grazie a Mymentis ora sto davvero bene con me stessa",
      reviewText:
        "Mi sta aiutando molto a riflettere e capire davvero cosa vorrei nella mia vita. È una bellissima persona con cui mi sono sentita da subito a mio agio.",
    },
    {
      vote: 1,
      reviewTitle: "9 Grazie a Mymentis ora sto davvero bene con me stessa",
      reviewText:
        "Mi sta aiutando molto a riflettere e capire davvero cosa vorrei nella mia vita. È una bellissima persona con cui mi sono sentita da subito a mio agio.",
    },
    {
      vote: 2,
      reviewTitle: "10 Grazie a Mymentis ora sto davvero bene con me stessa",
      reviewText:
        "Mi sta aiutando molto a riflettere e capire davvero cosa vorrei nella mia vita. È una bellissima persona con cui mi sono sentita da subito a mio agio. Mi sta aiutando molto a riflettere e capire davvero cosa vorrei nella mia vita. È una bellissima persona con cui mi sono sentita da subito a mio agio.",
    },
    // {
    //   vote: 2,
    //   reviewTitle: "11 Grazie a Mymentis ora sto davvero bene con me stessa",
    //   reviewText:
    //     "Mi sta aiutando molto a riflettere e capire davvero cosa vorrei nella mia vita. È una bellissima persona con cui mi sono sentita da subito a mio agio.",
    // },
  ];

  useEffect(() => {
    analyticsService({
      'event': 'virtualPageview',
      'pageUrl': '/questionario/introduzione',
      'pageTitle': 'Intro Survey'
    });
  }, []);

  return (
    <div className={styles.introSurvey}>
      <div className={styles.top}>
        <div className={styles.greenShape}>
          <img src={introGreenShape} alt="background-shape"></img>
        </div>
        <div className={styles.card}>
          <div className={styles.title}>Ti faremo alcune domande</div>
          <div className={styles.contentWrapper}>
            <div className={styles.cardElement}>
              <div className={styles.icon}>
                <ClockIcon />
              </div>
              <div>
                Il questionario durerà al
                <span style={{ fontWeight: 600 }}> massimo 5 minuti</span>
              </div>
            </div>
            <div className={styles.cardElement}>
              <div className={styles.icon}>
                <HeartIcon />
              </div>
              <div>
                Selezioneremo il terapeuta{" "}
                <span style={{fontWeight: 600}}>più adatto a te</span>
              </div>
            </div>
            <div className={styles.cardElement}>
              <div className={styles.icon}>
                <CalendarDaysIcon />
              </div>
              <div>
                Potrai fissare un primo incontro gratuito
                <span style={{ fontWeight: 600 }}> senza impegno</span>
              </div>
            </div>
          </div>
          <Button onClick={startSurvey} className={styles.startBtn}>
            Cominciamo
          </Button>
        </div>
        <img
          className={styles.people}
          src="/assets/images/landingPageImgs/surveyImg.png"
          alt="people"
        />
      </div>
      <div className={styles.bottom}>
        <div className={styles.bottomContent}>
          <div className={styles.icon}>
            <ChatBubbleBottomCenterIcon/>
          </div>
          <div> Scopri <span onClick={() => window.open(`${appSiteUrl}/chi-siamo`, '_blank')} style={{
            color: "#0354aa",
            fontWeight: 600,
            cursor: "pointer",
          }}>Chi siamo</span></div>
        </div>
      </div>
      {/* <div className={styles.review}> TODO: recensioni nascoste per primo lancio
        <div className={styles.purpleShape}>
          <img src={introPurpleShape} alt="backgorund-shape" />
        </div>
        <Carousel>
          {reviewList.map((i: any, index) => {
            const position = index + 1;
            const lastPosition = reviewList.length;
            // if (reviewList.length === 1) {
            //   return (
            //     <div key={index} className={styles.reviewCarouselWrapper}>
            //       <CardReview
            //         className={styles.cardReviewInvisible}
            //         vote={i.vote}
            //         reviewText={i.reviewText}
            //         reviewTitle={i.reviewTitle}
            //       />
            //       <CardReview
            //         className={styles.cardReview}
            //         vote={i.vote}
            //         reviewText={i.reviewText}
            //         reviewTitle={i.reviewTitle}
            //       />
            //       <CardReview
            //         className={styles.cardReviewInvisible}
            //         vote={i.vote}
            //         reviewText={i.reviewText}
            //         reviewTitle={i.reviewTitle}
            //       />
            //     </div>
            //   );
            // }
            // if (reviewList.length === 2 && index > 0) {
            //   return (
            //     <div key={index} className={styles.reviewCarouselWrapper}>
            //       <CardReview
            //         className={styles.cardReview}
            //         vote={reviewList[index - 1].vote}
            //         reviewText={reviewList[index - 1].reviewText}
            //         reviewTitle={reviewList[index - 1].reviewTitle}
            //       />
            //       <CardReview
            //         className={styles.cardReview}
            //         vote={i.vote}
            //         reviewText={i.reviewText}
            //         reviewTitle={i.reviewTitle}
            //       />
            //       <CardReview
            //         className={styles.cardReviewInvisible}
            //         vote={i.vote}
            //         reviewText={i.reviewText}
            //         reviewTitle={i.reviewTitle}
            //       />
            //     </div>
            //   );
            // }
            // if (reviewList.length > 2) {
            //   if (position % 3 === 0) {
            //     return (
            //       <div key={index} className={styles.reviewCarouselWrapper}>
            //         <CardReview
            //           className={styles.cardReview}
            //           vote={reviewList[index - 2].vote}
            //           reviewText={reviewList[index - 2].reviewText}
            //           reviewTitle={reviewList[index - 2].reviewTitle}
            //         />
            //         <CardReview
            //           className={styles.cardReview}
            //           vote={reviewList[index - 1].vote}
            //           reviewText={reviewList[index - 1].reviewText}
            //           reviewTitle={reviewList[index - 1].reviewTitle}
            //         />
            //         <CardReview
            //           className={styles.cardReview}
            //           vote={i.vote}
            //           reviewText={i.reviewText}
            //           reviewTitle={i.reviewTitle}
            //         />
            //       </div>
            //     );
            //   }
            //   if (position === lastPosition && position % 3 === 2) {
            //     return (
            //       <div key={index} className={styles.reviewCarouselWrapper}>
            //         <CardReview
            //           className={styles.cardReview}
            //           vote={reviewList[index - 1].vote}
            //           reviewText={reviewList[index - 1].reviewText}
            //           reviewTitle={reviewList[index - 1].reviewTitle}
            //         />
            //         <CardReview
            //           className={styles.cardReview}
            //           vote={i.vote}
            //           reviewText={i.reviewText}
            //           reviewTitle={i.reviewTitle}
            //         />
            //         <CardReview
            //           className={styles.cardReviewInvisible}
            //           vote={i.vote}
            //           reviewText={i.reviewText}
            //           reviewTitle={i.reviewTitle}
            //         />
            //         <CardReview
            //           className={styles.cardReviewInvisible}
            //           key={index + 1}
            //           vote={i.vote}
            //           reviewText={i.reviewText}
            //           reviewTitle={i.reviewTitle}
            //         />
            //       </div>
            //     );
            //   }
            //   if (position === lastPosition && position % 3 === 1) {
            //     return (
            //       <div key={index} className={styles.reviewCarouselWrapper}>
            //         <CardReview
            //           className={styles.cardReviewInvisible}
            //           vote={i.vote}
            //           reviewText={i.reviewText}
            //           reviewTitle={i.reviewTitle}
            //         />
            //         <CardReview
            //           className={styles.cardReviewInvisible}
            //           key={index + 1}
            //           vote={i.vote}
            //           reviewText={i.reviewText}
            //           reviewTitle={i.reviewTitle}
            //         />
            //         <CardReview
            //           className={styles.cardReview}
            //           vote={i.vote}
            //           reviewText={i.reviewText}
            //           reviewTitle={i.reviewTitle}
            //         />
            //         <CardReview
            //           className={styles.cardReviewInvisible}
            //           vote={i.vote}
            //           reviewText={i.reviewText}
            //           reviewTitle={i.reviewTitle}
            //         />
            //         <CardReview
            //           className={styles.cardReviewInvisible}
            //           key={index + 2}
            //           vote={i.vote}
            //           reviewText={i.reviewText}
            //           reviewTitle={i.reviewTitle}
            //         />
            //       </div>
            //     );
            //   }
            // }
            if (index % 3 === 0) {
              return (
                <div key={index} className={styles.reviewCarouselWrapper}>
                  <CardReview
                    className={styles.cardReview}
                    vote={i.vote}
                    reviewText={i.reviewText}
                    reviewTitle={i.reviewTitle}
                  />
                  {reviewList[index + 1] && (
                    <CardReview
                      className={styles.cardReview}
                      vote={reviewList[index + 1].vote}
                      reviewText={reviewList[index + 1].reviewText}
                      reviewTitle={reviewList[index + 1].reviewTitle}
                    />
                  )}
                  {reviewList[index + 2] && (
                    <CardReview
                      className={styles.cardReview}
                      vote={reviewList[index + 2].vote}
                      reviewText={reviewList[index + 2].reviewText}
                      reviewTitle={reviewList[index + 2].reviewTitle}
                    />
                  )}
                </div>
              );
            }
          })}
        </Carousel>
      </div>
      <div className={styles.mobileReview}>
        <Carousel>
          {reviewList.map((i: any, index) => {
            return (
              <div key={index} className={styles.reviewCarouselWrapper}>
                <CardReview
                  className={styles.cardReview}
                  vote={i.vote}
                  reviewText={i.reviewText}
                  reviewTitle={i.reviewTitle}
                />
              </div>
            );
          })}
        </Carousel>
      </div> */}
    </div>
  );
};
