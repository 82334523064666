import {forwardRef} from 'react'
import styles from './Input.module.scss'

interface Option {
	name: any;
	value: any;
}

interface Props {
	className?: string;
	name?: string;
	leftIcon?: any;
	rightIcon?: any;
	iconQuestion?: any;
	label?: string;
	sublabel?: string;
	checkboxLabel?: any;
	checkboxId?: string;
	placeholder?: string;
	disabled?: boolean;
	value?: any;
	checked?: boolean;
	type?: React.HTMLInputTypeAttribute;
	textarea?: boolean;
	select?: boolean;
	multiple?: boolean;
	list?: string;
	options?: Option[];
	areaRows?: number;
	error?: string;
	accept?: string;
	onChange?: (e: any) => void;
	onBlur?: (e: any) => void;
	onKeyDown?: (e: any) => void;
	coupon?: boolean;
	width?: any;
}

export const Input = forwardRef<HTMLInputElement, Props>(
	(
		{
			className = '',
			name = '',
			leftIcon,
			rightIcon,
			iconQuestion,
			type,
			label = '',
			sublabel,
			checkboxLabel = '',
			checkboxId,
			placeholder = '',
			disabled = false,
			value,
			checked,
			textarea,
			select,
			multiple,
			list,
			options,
			areaRows = 5,
			error,
			accept,
			onChange,
			onBlur,
			onKeyDown,
			coupon,
			width,
			...props
		},
		ref
	) => {
		return (
			<div className={`${styles.inputWrapper} ${coupon ? styles.coupon : className}`}>
				<label className={`${styles.label} ${(error && styles.error) ?? ''}`}>
					{label}
				</label>
				{sublabel && (
					<div className={styles.sublabel}>
						<span>{sublabel}</span>
					</div>
				)}
				<div
					className={`${styles.Input} ${disabled && styles.disabled} ${coupon && styles.coupon} ${
						textarea && styles.textarea
					} ${(error && styles.error) ?? ''}`}
				>
					{leftIcon && <div className={styles.leftIcon}>{leftIcon}</div>}
					{iconQuestion && <div className={styles.iconQuestion}>{iconQuestion}</div>}
					<div className={styles.inputLabel}>
						{textarea && (
							<textarea
								name={name}
								onKeyDown={onKeyDown}
								rows={areaRows}
								value={value}
								onChange={onChange}
								onBlur={onBlur}
								placeholder={placeholder}
								disabled={disabled}
							/>
						)}
						{select && (
							<select
								multiple={multiple}
								id={list}
								name={list}
								onChange={onChange}
								onBlur={onBlur}
								value={value}
								disabled={disabled}
							>
								{options?.map((i, index) => (
									<option key={index} value={i.value} disabled={disabled}>
										{i.name}
									</option>
								))}
							</select>
						)}
						{!textarea && !select && (
							<input
								{...props}
								ref={ref}
								id={checkboxId}
								name={name}
								type={type}
								checked={checked}
								list={list}
								placeholder={placeholder}
								disabled={disabled}
								value={value}
								accept={accept}
								onChange={onChange}
								onBlur={onBlur}
								onKeyDown={onKeyDown}
								style={{width: width ? width : undefined}}
							/>
						)}
						{checkboxLabel && (
							<label htmlFor={checkboxId} className={styles.checkboxLabel}>
								{checkboxLabel}
							</label>
						)}
					</div>
					{rightIcon && <div className={styles.rightIcon}>{rightIcon}</div>}
				</div>
				{error && <label className={styles.errorLabel}>{error}</label>}
			</div>
		)
	}
)
