import {
	ChevronLeftIcon,
	UserIcon,
	UsersIcon,
} from '@heroicons/react/24/solid'
import { Progress } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { setInfo } from '../../../store/slices/infoSlice'
import { setSurvey } from '../../../store/slices/surveySlice'
import parentIcon from '../../../theme/images/parentIcon.png'
import sunIcon from '../../../theme/images/sunIcon.svg'
import afternoonIcon from '../../../theme/images/afternoonIcon.svg'
import lateAfternoonIcon from '../../../theme/images/lateAfternoonIcon.svg'
import eveningIcon from '../../../theme/images/eveningIcon.svg'
import idkIcon from '../../../theme/images/idk.webp'
import percorsoPersonalizzato from '../../../theme/images/percorso_personalizzato.webp'
import male from '../../../theme/images/genders/male.svg'
import female from '../../../theme/images/genders/female.svg'
import confusione from '../../../theme/images/confusione.webp'
import { trpc } from '../../../utils/trpc'
import Button from '../../atoms/Button'
import InfoBox from '../../atoms/InfoBox'
import Input from '../../atoms/Input'
import styles from './QuestionSurvey.module.scss'
import GenericForm from '../../molecules/GenericForm'
import routes from 'routes'
import { analyticsService } from "../../../utils/analyticsService";
import { envs } from "../../../utils/envs";
import { raiseException } from "../../../utils/raiseException";

const appSiteUrl = envs.REACT_APP_SITE_URL;

export const QuestionSurvey = ({ setPageNumber, pageNumber }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const {state} = location

	const [underageSurveyFromSingle, setUnderageSurveyFromSingle] =
		useState(false)
	const [questionType, setQuestionType] = useState<string>('')
	const [questionsToggle, setQuestionToggle] = useState(false)
	const [surveyId, setSurveyId] = useState('')
	const [progressPercentage, setProgressPercentage] = useState(0)
	const [beginQuestionLength, setBeginQuestionLength] = useState(0)
	const [firstCheckpoint, setFirstCheckpoint] = useState(false)
	const [secondCheckpoint, setSecondCheckpoint] = useState(false)
	const [underageSurvey, setUnderageSurvey] = useState(false)
	const [actualQuestion, setActualQuestion] = useState(0)
	const [actualAnswerValue, setActualAnswerValue] = useState<any>('')
	const [btnIsdisabled, setBtnIsDisabled] = useState(true)
	const [questions, setQuestions] = useState<any>([])
	const [answers, setAnswers] = useState<any>([])
	const [userName, setUserName] = useState('')
	const [userGender, setUserGender] = useState('')
	const [ageError, setAgeError] = useState('')
	const [invalidAnswer, setInvalidAnswer] = useState<number>(0);
	const [indices, setIndices] = useState<any>([]);

	const [searchParams, setSearchParams] = useSearchParams();
	let therapyTypeQueryParam = searchParams.get('therapy_type');

	const getPrefixedQuestionType = (questionType: any, question: any) => {
		const prefixes = {
			'5d904556-5caf-466c-9706-26ccfc9b6a80': 'I_',
			'762bbb63-a571-44ab-a135-607e4ae07968': 'C_',
			'a3b8aa4f-aebb-477e-a8c6-f07739b9e108': 'M_'
		};

		return `${prefixes[questionType] || ''}${question}`
	};

	const parseQuestionTypes = (type: string) =>{
		const prefixes = {
			'single': '5d904556-5caf-466c-9706-26ccfc9b6a80',
			'couple': '762bbb63-a571-44ab-a135-607e4ae07968',
			'minori': 'a3b8aa4f-aebb-477e-a8c6-f07739b9e108'
		};

		return prefixes[type];
	}

	const getLabelIndices = (questions, startLabel, endLabel) => {
		let startIndex = -1;
		let endIndex = -1;
		return questions.reduce((indices, question, index) => {
			if (question.label === startLabel) {
				startIndex = index;
			}
			if (question.label === endLabel) {
				endIndex = index;
			}
			if (startIndex !== -1 && index >= startIndex && (endIndex === -1 || index <= endIndex)) {
				indices.push(index);
			}
			return indices;
		}, []);
	};

	const countInvalidAnswers = (questions, answers, indices) => {
		return indices.reduce((count, questionIndex) => {
			const answerIndex = answers[questionIndex] && answers[questionIndex][0];
			const question = questions[questionIndex];
			const lastOptionIndex = question.options.length - 1;
			if (answerIndex === lastOptionIndex) {
				return count + 1;
			}
			return count;
		}, 0);
	};

	const removeAnswersBeforeIndex = (answers, indices) => {
		if (indices.length === 0) {
			return answers;
		}
		const firstIndex = indices[0];
		return answers.slice(0, firstIndex);
	};

	const getInvalidAnswer = () => {
		if (questionType === '5d904556-5caf-466c-9706-26ccfc9b6a80') {
			const indices = getLabelIndices(questions, "symptoms", "last_check");
			const invalidAnswersCount = countInvalidAnswers(questions, answers, indices);
			setIndices(indices)
			setInvalidAnswer(invalidAnswersCount);
		} else if (questionType === '762bbb63-a571-44ab-a135-607e4ae07968' || questionType === 'a3b8aa4f-aebb-477e-a8c6-f07739b9e108') {
			const indices = getLabelIndices(questions, "first_check", "last_check");
			const invalidAnswersCount = countInvalidAnswers(questions, answers, indices);
			setIndices(indices)
			setInvalidAnswer(invalidAnswersCount);
		}
	}

	const {data: getSurveys, refetch: refetchGetSurveys} =
		trpc.mainService.survey.getSurveys.useQuery(undefined, {enabled: false})

	const sendNewSurvey = trpc.mainService.patient.submitNewSurvey.useMutation()

	const getQuestions = trpc.mainService.survey.getSurvey.useQuery(
		{
			id: questionType,
		},
		{enabled: questionsToggle}
	)

  useEffect(() => {
    analyticsService({'event': 'virtualPageview', 'pageUrl': '/questionario/incorso', 'pageTitle': 'Survey Start'});
  }, []);

	const prevQuestion = () => {
		setBtnIsDisabled(false)
		setActualAnswerValue(answers[actualQuestion - 2])
	}

	const nextQuestion = () => {
		if ((beginQuestionLength > 0 && actualQuestion === beginQuestionLength) || (questionType === "a3b8aa4f-aebb-477e-a8c6-f07739b9e108" && actualQuestion === 2)) {
			setFirstCheckpoint(true)
		} else if (
			beginQuestionLength > 0 &&
			actualQuestion === beginQuestionLength + questions
		) {
			setSecondCheckpoint(true)
		} else {
			analyticsService({'event': `survey_${actualQuestion + 1}_${getPrefixedQuestionType(questionType, questions[actualQuestion - 1]?.tracking)}`});
			setActualQuestion(actualQuestion + 1)
		}
	}

	const submitAnswer = () => {
		const answersArray = answers
		const nextAnswerValue = answers[actualQuestion]
		answersArray[actualQuestion - 1] = actualAnswerValue
		setAnswers(answersArray)
		setActualAnswerValue(nextAnswerValue || '')
		setBtnIsDisabled(true)
		getInvalidAnswer()
	}

	const privacyDisclaimer = (breakpoint: 'mobile' | 'desktop') => {
		return (
			<div
				className={
					breakpoint === 'desktop'
						? styles.privacyDisclaimer
						: styles.privacyMobile
				}
			>
				Compilando il questionario l’utente dichiara di aver letto e compreso{' '}
				<span onClick={() => window.open(`${appSiteUrl}/privacy-paziente`, '_blank')}>
          l’informativa privacy
        </span>{' '}
				di Mymentis S.r.l. e del Professionista
			</div>
		)
	}
	const checkAge = () => {
		setAgeError('')
		if (questions[actualQuestion - 1].label === 'age') {
			if (actualAnswerValue > 100 || actualAnswerValue < 1) {
				setAgeError('Età non valida')
				return
			}
		} else if (questions[actualQuestion - 1].label === 'underage-age') {
			if (actualAnswerValue > 100 || actualAnswerValue < 1) {
				setAgeError('Età non valida')
				return
			}
			if (actualAnswerValue > 17) {
				setAgeError(
					'Età invalida per il tipo di percorso, tornare indietro e selezionare un altro tipo di terapia'
				)
				return
			}
		}

		if (
			questions[actualQuestion - 1].label === 'age' &&
			actualAnswerValue < 18
		) {
			const underageType = getSurveys?.surveys?.find(
				(i) => i.type === 'underage'
			)
			setQuestionType(underageType?.id!)
			setUnderageSurvey(true)
			setQuestionToggle(true)
			setAnswers([])
			setActualAnswerValue('')
			setUnderageSurveyFromSingle(true)
		} else {
			nextQuestion()
			submitAnswer()
		}
		cardRef.current?.scrollIntoView()
	}
	useEffect(() => {
		if (
			actualAnswerValue ||
			(typeof actualAnswerValue === 'number' && actualAnswerValue === 0)
		) {
			setBtnIsDisabled(false)
		}
		if (
			typeof actualAnswerValue === 'string' &&
			actualAnswerValue.trim() === ''
		) {
			setBtnIsDisabled(true)
		}
	}, [actualAnswerValue])

	useEffect(() => {
		if (questionsToggle) {
			if (!getQuestions.isFetching && getQuestions.status === 'success') {
				setQuestionToggle(false)
				const {survey} = getQuestions.data
				const {beginningQuestions, endingQuestions, questions, id, type} =
					survey
				setSurveyId(id)
				getQuestionsSurvey(id)
				if (type === 'underage') {
					setQuestions(questions.concat(endingQuestions))
				} else {
					setQuestions(
						beginningQuestions.concat(questions).concat(endingQuestions)
					)
				}
				setBtnIsDisabled(false)
				setBeginQuestionLength(beginningQuestions?.length)
			}
			if (getQuestions.status === 'error') {
				setQuestionToggle(false)
				dispatch(
					setInfo({text: getQuestions.error.message, variant: 'error'})
				)
			}
		}
	}, [getQuestions, questionsToggle])

	useEffect(() => {
		setProgressPercentage((actualQuestion / ((questions || []).length + 1)) * 100)

		if (
			questions &&
			questions[actualQuestion - 1] &&
			questions[actualQuestion - 1].label === 'given_name' &&
			state?.user
		) {
			setActualAnswerValue(state?.user?.given_name)
		}
	}, [actualQuestion])

	useEffect(() => {
		if (progressPercentage === 100) {
			if (state?.sendNewSurvey === 'sendNewSurvey') {
				sendNewSurvey
					.mutateAsync({survey: {answers, id: surveyId}})
					.then((data) => {
						navigate(routes.ChooseTherapist.path)
					})
					.catch((e) => {
						raiseException(e);
						console.error('ERROR', e?.shape?.message)
					})
			} else {
				navigate(routes.PatientRegister.path, {
					state: {
						name: userName,
						gender: userGender ?? 'other',
						answers: {id: surveyId, answers},
						campaignId: state?.campaignId,
					},
				})
				dispatch(setSurvey({id: surveyId, answers}))
			}
		}
	}, [progressPercentage])

	useEffect(() => {
		setAnswers([])
		setIndices([])
		setInvalidAnswer(0)
	}, [questionType])

	useEffect(() => {
		refetchGetSurveys().then((data) => {
			if (!data.isSuccess) {
				refetchGetSurveys()
			}
		})
	}, [])

	useEffect(() => {
		if (pageNumber > 1) {
			setActualQuestion(actualQuestion - 1)
			prevQuestion()
			cardRef.current?.scrollIntoView()
			setPageNumber(0)
		} else if (pageNumber === 1) {
			setActualQuestion(0)
			setUnderageSurvey(false)
			setQuestionType('')
			setUnderageSurveyFromSingle(false)
			cardRef.current?.scrollIntoView()
			setPageNumber(0)
		}
	}, [pageNumber])


	useEffect(() => {
		(async () => {
			if (!therapyTypeQueryParam) {
				if (actualQuestion === 0) {
					setPageNumber(0)
				} else {
					let pageNumberValue = pageNumber
					if (
						actualQuestion > 0 &&
						!firstCheckpoint &&
						!underageSurvey &&
						questions[actualQuestion - 1] &&
						!therapyTypeQueryParam
					) {
						switch (questions[actualQuestion - 1].type) {
							case 'checkbox':
								pageNumberValue = 5
								break
							case 'number':
								pageNumberValue = 3
								break
							case 'text':
								pageNumberValue = 2
								break
							case 'radio':
								pageNumberValue = 4
								break
							default:
								pageNumberValue = pageNumber
						}
					} else if (underageSurvey && !therapyTypeQueryParam) {
						pageNumberValue = 1
					}
					setPageNumber(pageNumberValue)
				}
			} else {
				const therapyType = parseQuestionTypes(therapyTypeQueryParam!);
				setQuestionType(therapyType);
				await chooseTherapyType();
			}
			// console.log('page' + pageNumberValue)
		})()
	}, [actualQuestion, firstCheckpoint, underageSurvey, questions])

	const chooseTherapyType = async () => {
		setQuestionToggle(true);

		if (questionType) {
			await getQuestions.refetch();
		}
	}

	const getQuestionsSurvey = (questionTypeData: string) => {
		if (
			questionTypeData === 'a3b8aa4f-aebb-477e-a8c6-f07739b9e108'
		) {
			setUnderageSurvey(true)
		}
		analyticsService({'event': `survey_${1}_${getPrefixedQuestionType(questionTypeData, 'Percorso')}`});
		setActualQuestion(1)
		setBtnIsDisabled(true)
		if (therapyTypeQueryParam) {
			searchParams.delete('therapy_type');
		}
		cardRef.current?.scrollIntoView()
	}

	const cardRef = useRef<HTMLDivElement>(null)

	return (
		<div className={`${styles.questionSurvey}`}>
			<Progress
				className={styles.progressBar}
				showInfo={false}
				percent={progressPercentage}
			/>
			<div ref={cardRef} className={styles.topAnchor}></div>
			<div className={styles.questionWrapper}>
				{firstCheckpoint && (
					<div className={styles.cardWrapper}>
						<div className={styles.questionCard}>
							<div className={styles.titleWrapper}>
								<div className={styles.questionCardTitle}>
									Ciao {answers[0]}, grazie di averci scelto!
								</div>
								<div className={styles.note}>
									Per offrirti il miglior aiuto possibile, abbiamo bisogno di conoscere meglio le tue
									esigenze. Cominciamo con qualche domanda?
								</div>
								<img className={styles.questionCardImage} src={percorsoPersonalizzato}
								     alt="percorsoPersonalizzato"/>
							</div>
							<div className={styles.btnWrapper}>
								<Button
									className={styles.soloBtn}
									onClick={() => {
										analyticsService({'event': `survey_${actualQuestion + 1}_${getPrefixedQuestionType(questionType, 'Intermezzo')}`});
										setActualQuestion(actualQuestion + 1)
										setFirstCheckpoint(false)
										cardRef.current?.scrollIntoView()
									}}
								>
									Cominciamo!
								</Button>
								{/*{privacyDisclaimer("mobile")}*/}
							</div>
						</div>
						{privacyDisclaimer('desktop')}
					</div>
				)}
				{secondCheckpoint && (
					<div className={styles.cardWrapper}>
						<div className={styles.questionCard}>
							<div className={styles.titleWrapper}>
								<div className={styles.questionCardTitle}>
									Grazie {answers[0]} di aver condiviso con noi le risposte!
								</div>
								<div className={styles.note}>
									Ti chiederemo le ultime informazioni riguardo alle tue
									preferenze. Faremo il possibile per soddisfarle!
								</div>
							</div>
							<div className={styles.btnWrapper}>
								<Button
									className={styles.soloBtn}
									onClick={() => {
                    analyticsService({ 'event': `survey_${actualQuestion + 1}` });
										setActualQuestion(actualQuestion + 1)
										setSecondCheckpoint(false)
										cardRef.current?.scrollIntoView()
									}}
								>
									Avanti
								</Button>
								{/*{privacyDisclaimer("mobile")}*/}
							</div>
						</div>
						{privacyDisclaimer('desktop')}
					</div>
				)}
				{invalidAnswer >= 3 && (
					<div className={styles.cardWrapper}>
						<div className={styles.questionCard}>
							<div className={`${styles.titleWrapper} ${styles.confusione}`}>
								<div className={styles.questionCardTitle}>
									Ci dispiace molto
								</div>
								<img className={`${styles.questionCardImage} ${styles.confusione}`} src={confusione}
										 alt="confusione"/>
								<div className={styles.note}>
									<p>In base alle risposte che hai fornito nella compilazione del questionario, non siamo riusciti ad
										individuare il terapeuta più adatto a te.</p>
									<p>Se vuoi, rispondi nuovamente al questionario, fornendo risposte più precise (diverse da “Nessuna di
										queste” e “Non so”). Ti ringraziamo per la comprensione, il nostro obiettivo è fornirti il miglior
										aiuto possibile.
									</p>
								</div>
							</div>
							<div className={styles.btnWrapper}>
								<Button
									className={styles.soloBtn}
									onClick={() => {
										analyticsService({'event': `survey_${getPrefixedQuestionType(questionType, 'Ci_dispiace_molto')}`});
										setAnswers(removeAnswersBeforeIndex(answers, indices))
										setActualQuestion(indices[0] + 1)
										setIndices([])
										setInvalidAnswer(0)
										cardRef.current?.scrollIntoView()
									}}
								>
									Rifai il questionario
								</Button>
								{/*{privacyDisclaimer("mobile")}*/}
							</div>
						</div>
						{privacyDisclaimer('desktop')}
					</div>
				)}
				{underageSurvey && (
					<GenericForm
						className={`${styles.questionCard} ${styles.underageAlert}`}
						title='Prima di proseguire'
						cta={[
							<div className={styles.btnWrapper}>
								<div className={styles.btnContainer}>
									<Button
										className={styles.backBtn}
										variant='tertiary'
										onClick={() => {
											setActualQuestion(0)
											setUnderageSurvey(false)
											setQuestionType('')
											setUnderageSurveyFromSingle(false)
											cardRef.current?.scrollIntoView()
										}}
									>
										{<ChevronLeftIcon className={styles.icon}/>} Indietro
									</Button>
									<Button
										onClick={() => {
											analyticsService({'event': `survey_${getPrefixedQuestionType(questionType, 'Disclaimer')}`});
											setUnderageSurvey(false)
											setActualQuestion(1)
										}}
									>
										Accetta e prosegui
									</Button>
								</div>
								{/*{privacyDisclaimer("mobile")}*/}
							</div>,
						]}
						description={
							<div className={styles.underageInfo}>
								{underageSurveyFromSingle && (
									<InfoBox
										variant='warn'
										text="Hai selezionato un'età sotto i 18 anni, quindi ti abbiamo
                  reindirizzato al percorso per minore."
									/>
								)}
								<div>
									La compilazione del questionario è riservata ai genitori o a chi ha la responsabilità del minore e
									ritiene che abbia bisogno di supporto psicologico.
								</div>
								<div>
									{' '}
									Sarebbe preferibile che il questionario fosse compilato insieme al minore.{' '}
								</div>
								<div>
									{' '}
									Prima del colloquio gratuito verrà chiesto a entrambi i genitori o a chi ha la responsabilità del
									minore di firmare il modulo di consenso informato. {' '}
								</div>
								<div>
									Se il minore ha solo un genitore o tutore può fornire un’autocertificazione e proseguire.
								</div>
							</div>
						}
					/>
				)}
				{(actualQuestion === 0 && !therapyTypeQueryParam) && (
					<div className={styles.cardWrapper}>
						<div className={styles.firstQuestionCard}>
							<div className={styles.titleWrapper}>
								<div className={styles.questionCardTitle}>
									Che tipo di terapia stai cercando?
								</div>
							</div>
							<div className={styles.answersWrapper}>
								{getSurveys?.surveys.map((i: any, index: number) => {
									const icon = () => {
										switch (i.type) {
											case 'single':
												return <UserIcon className={styles.icon}/>
											case 'couple':
												return <UsersIcon className={styles.icon}/>
											case 'underage':
												return (
													<img
														src={parentIcon}
														alt='parent icon'
														className={styles.icon}
													/>
												)
										}
									}
									return (
										<div
											tabIndex={0}
											key={index}
											onClick={() => {
												// setQuestionToggle(true);
												setQuestionType(i.id)
											}}
											className={`${styles.firstQuestion} ${
												questionType === i.id && styles.activeAnswer
											}`}
										>
											<div className={styles.firstQuestionIcon}>{icon()}</div>
											<div className={styles.firstQuestionDescription}>
												<div className={styles.firstQuestionTitle}>
													{i.title}
												</div>
												<div className={styles.firstQuestionSubtitle}>
													{i.subtitle}
												</div>
											</div>
										</div>
									)
								})}
							</div>
							<div className={styles.btnWrapper}>
								<Button
									className={styles.soloBtn}
									disabled={questionType.trim() === ''}
									onClick={async () => {
										await chooseTherapyType();
									}}
								>
									Avanti
								</Button>
								{privacyDisclaimer('mobile')}
							</div>
						</div>
						{privacyDisclaimer('desktop')}
					</div>
				)}
				{actualQuestion > 0 &&
					!firstCheckpoint &&
					!underageSurvey &&
					questions[actualQuestion - 1] &&
					questions[actualQuestion - 1].type === 'text' && (
						<div className={styles.cardWrapper}>
							<div className={styles.questionCard}>
								<div className={styles.titleWrapper}>
									<div className={styles.questionCardTitle}>
										{questions[actualQuestion - 1].title}
									</div>
									<div className={styles.note}>
										{questions[actualQuestion - 1].note}
									</div>
								</div>
								<div className={styles.questionWrapper}>
									<Input
										onKeyDown={(e) => {
											if (e.key === 'Enter') {
												if (
													questions[actualQuestion - 1].label === 'given_name'
												) {
													if (state?.user?.given_name) {
														setActualAnswerValue(state?.user?.given_name)
													}
													setUserName(actualAnswerValue)
												}
												nextQuestion()
												submitAnswer()
											}
										}}
										textarea={
											questions[actualQuestion - 1]?.textInputType === 'textarea'
										}
										areaRows={10}
										value={actualAnswerValue}
										onChange={(e: any) => {
											setActualAnswerValue(e.target.value)
										}}
									/>

									{questions[actualQuestion - 1].label === 'given_name' && (
										<InfoBox
											text={'Inserisci il tuo nome e il tuo cognome'}
										/>
									)}
									{questions[actualQuestion - 1].label === 'given_name_minori' && (
										<InfoBox
											text={'Inserisci il nome e il cognome del minore che dovrebbe iniziare il percorso di terapia.'}
										/>
									)}
								</div>
								<div className={styles.btnWrapper}>
									<div className={styles.btnContainer}>
										<Button
											className={styles.backBtn}
											variant='tertiary'
											onClick={() => {
												analyticsService({'event': `survey_${actualQuestion - 1}_${getPrefixedQuestionType(questionType, questions[actualQuestion - 2]?.tracking ? questions[actualQuestion - 2]?.tracking : 'Percorso')}`});
												setActualQuestion(actualQuestion - 1)
												prevQuestion()
												if (
													questions[actualQuestion - 1].label ===
													'given_name'
												) {
													setQuestionType('')
												}
												cardRef.current?.scrollIntoView()
											}}
										>
											{<ChevronLeftIcon className={styles.icon}/>} Indietro
										</Button>
										<Button
											disabled={
												(questions[actualQuestion - 1].textInputType ===
												'textarea'
													? false
													: btnIsdisabled) ||
												(questions[actualQuestion - 1].label === 'given_name' &&
													!actualAnswerValue)
											}
											onClick={() => {
												if (
													questions[actualQuestion - 1].label === 'given_name'
												) {
													setUserName(actualAnswerValue)
												}
												nextQuestion()
												submitAnswer()
												cardRef.current?.scrollIntoView()
											}}
										>
											Avanti
										</Button>
									</div>
									{/*{privacyDisclaimer("mobile")}*/}
								</div>
							</div>
							{privacyDisclaimer('desktop')}
						</div>
					)}
				{actualQuestion > 0 &&
					!firstCheckpoint &&
					!underageSurvey &&
					questions[actualQuestion - 1] &&
					questions[actualQuestion - 1].type === 'number' && (
						<div className={styles.cardWrapper}>
							<div className={styles.questionCard}>
								<div className={styles.titleWrapper}>
									<div className={styles.questionCardTitle}>
										{questions[actualQuestion - 1].title}
									</div>
									<div className={styles.note}>
										{questions[actualQuestion - 1].note}
									</div>
								</div>
								<div className={styles.questionWrapper}>
									<Input
										onKeyDown={(e) => {
											if (e.key === 'Enter') {
												checkAge()
												analyticsService({'event': `survey_${actualQuestion + 1}_${getPrefixedQuestionType(questionType, questions[actualQuestion - 1]?.tracking)}`});
												// if (
												//   questions[actualQuestion - 1].label === "age" &&
												//   actualAnswerValue < 18
												// ) {
												//   console.log("meno di 18");
												//   // TODO: invece che ripartire dal percorso minori lo facciamo ripartire dalla scelta del percorso terapia
												//   // const underageType = getSurveys?.surveys.find(
												//   //   (i) => i.type === "underage"
												//   // );
												//   // setQuestionType(underageType?.id!);
												//   // setQuestionToggle(true);
												//   // setUnderageSurvey(true);
												//   // setAnswers([]);
												//   // setActualAnswerValue("");
												//   // setUnderageSurveyFromSingle(true);
												//   setActualAnswerValue("");
												//   setActualQuestion(0);
												//   setQuestionType("");
												// } else {
												//   nextQuestion();
												//   submitAnswer();
												// }
											}
										}}
										error={
											(questions[actualQuestion - 1].label === 'underage-age' ||
												questions[actualQuestion - 1].label === 'age') &&
											ageError
												? ageError
												: undefined
										}
										type='number'
										value={actualAnswerValue ? actualAnswerValue : ''}
										onChange={(e: any) => {
											setActualAnswerValue(parseInt(e.target.value))
										}}
									/>
									{questions[actualQuestion - 1].label === 'underage-age' && (
										<InfoBox
											text={'Inserisci l’età del minore che dovrebbe iniziare il percorso di terapia.'}
										/>
									)}
								</div>
								<div className={styles.btnWrapper}>
									<div className={styles.btnContainer}>
										<Button
											className={styles.backBtn}
											variant='tertiary'
											onClick={() => {
												analyticsService({'event': `survey_${actualQuestion - 1}_${getPrefixedQuestionType(questionType, questions[actualQuestion - 2]?.tracking)}`});
												setActualQuestion(actualQuestion - 1)
												prevQuestion()
												cardRef.current?.scrollIntoView()
											}}
										>
											{<ChevronLeftIcon className={styles.icon}/>} Indietro
										</Button>
										<Button disabled={
											(questions[actualQuestion - 1].textInputType ===
											'textarea'
												? false
												: btnIsdisabled) ||
											(questions[actualQuestion - 1].label === 'age' &&
												(!actualAnswerValue || actualAnswerValue > 100 || actualAnswerValue <= 0))
										} onClick={() => {
											analyticsService({'event': `survey_${actualQuestion + 1}_${getPrefixedQuestionType(questionType, questions[actualQuestion - 1]?.tracking)}`});
											checkAge()
										}}>
											Avanti
										</Button>
									</div>
									{/*{privacyDisclaimer("mobile")}*/}
								</div>
							</div>
							{privacyDisclaimer('desktop')}
						</div>
					)}
				{actualQuestion > 0 &&
					!firstCheckpoint &&
					!underageSurvey &&
					questions[actualQuestion - 1] &&
					questions[actualQuestion - 1].type === 'radio' && invalidAnswer < 3 && (
						<div className={styles.cardWrapper}>
							<div className={styles.questionCard}>
								<div className={styles.titleWrapper}>
									<div className={styles.questionCardTitle}>
										{questions[actualQuestion - 1].title}
									</div>
									<div className={styles.note}>
										{questions[actualQuestion - 1].note}
									</div>
								</div>
								<div className={styles.answersWrapper}>
									{questions[actualQuestion - 1].options.map(
										(i: any, index: number) => {
											const icon = () => {
												switch (i.value) {
													case 'male':
														return <img
															src={male}
															alt='male icon'
															className={styles.icon}
														/>
													case 'female':
														return <img
															src={female}
															alt='female icon'
															className={styles.icon}
														/>
													case 'other':
														return
												}
											}
											return (
												<div
													tabIndex={0}
													key={index}
													className={`${styles.firstQuestion} ${
														actualAnswerValue === index && styles.activeAnswer
													}`}
													onClick={() => {
														// if (
														// 	questions[actualQuestion - 1].label === 'gender'
														// ) {
														// 	const gender =
														// 		i.value === 'male'
														// 			? 'male'
														// 			: i.value === 'female'
														// 				? 'female'
														// 				: 'other'
														// 	// setUserGender(gender)
														// }
														setActualAnswerValue(index)
													}}
												>
													<div className={styles.firstQuestionIcon}>{icon()}</div>
													<div className={styles.firstQuestionDescription}>
														<div className={styles.firstQuestionTitle}>
															{i.title}
														</div>
													</div>
												</div>
											)
										}
									)}
								</div>
								<div className={styles.btnWrapper}>
									<div className={styles.btnContainer}>
										<Button
											className={styles.backBtn}
											variant='tertiary'
											onClick={() => {
												analyticsService({'event': `survey_${actualQuestion - 1}_${getPrefixedQuestionType(questionType, questions[actualQuestion - 2]?.tracking)}`});
												setActualQuestion(actualQuestion - 1)
												prevQuestion()
												cardRef.current?.scrollIntoView()
											}}
										>
											{<ChevronLeftIcon className={styles.icon}/>} Indietro
										</Button>
										<Button
											disabled={btnIsdisabled}
											onClick={() => {
												nextQuestion()
												submitAnswer()
												cardRef.current?.scrollIntoView()
											}}
										>
											Avanti
										</Button>
									</div>
									{/*{privacyDisclaimer("mobile")}*/}
								</div>
							</div>
							{privacyDisclaimer('desktop')}
						</div>
					)}

				{actualQuestion > 0 &&
					!firstCheckpoint &&
					!underageSurvey &&
					questions[actualQuestion - 1] &&
					questions[actualQuestion - 1].type === 'checkbox' && invalidAnswer < 3 && (
						<div className={styles.cardWrapper}>
							<div className={styles.questionCard}>
								{questions[actualQuestion - 1].title?.length > 1 &&
									<div
										className={styles.titleWrapper}>
										<div className={styles.questionCardTitle}>
											{questions[actualQuestion - 1].title}
										</div>
										{
											questions[actualQuestion - 1].note &&
											<div className={styles.note}>
												{questions[actualQuestion - 1].note}
											</div>
										}
									</div>
								}

								{questions[actualQuestion - 1].step &&
									<div className={styles.stepWrapper}>
										{questions[actualQuestion - 1].step &&
											Array(questions[actualQuestion - 1].numberOfSteps)
												.fill(0)
												.map((s, index) => {
													if (questions[actualQuestion - 1].step >= index + 1) {
														return <div key={index} className={styles.darkBlue}></div>
													}
													return <div key={index} className={styles.lightBlue}></div>
												})}
									</div>
								}
								<div className={styles.answersWrapper}>
									{questions[actualQuestion - 1].options.map(
										(i: any, index: number) => {
											const answer =
												actualAnswerValue === '' ? [] : actualAnswerValue

											const noOneIndex = questions[
											actualQuestion - 1
												].options?.findIndex(
												(question) =>
													question.title === 'Nessuna di queste' ||
													question.title === 'Non so ancora' ||
													question.title === 'Non ho preferenze'
											)
											const icon = () => {
												switch (i.value) {
													case 'morning':
														return <img
															src={sunIcon}
															alt='sun icon'
															className={styles.icon}
														/>
													case 'afternoon':
														return <img
															src={afternoonIcon}
															alt='afternoon icon'
															className={styles.icon}
														/>
													case 'late_afternoon':
														return <img
															src={lateAfternoonIcon}
															alt='evening icon'
															className={styles.icon}
														/>
													case 'evening':
														return <img
															src={eveningIcon}
															alt='evening icon'
															className={styles.icon}
														/>
													case '-':
														return <img
															src={idkIcon}
															alt='evening icon'
															className={styles.icon}
														/>
												}
											}

											return (
												<div key={index} className={styles.checkboxAnswer}>
													<Input
														className={styles.checkboxInput}
														checked={
															answer?.find((a) => a === index) ||
															answer?.find((a) => a === index) === 0
														}
														type='checkbox'
														checkboxId={i.title}
														checkboxLabel={i.title}
														iconQuestion={questions[actualQuestion - 1].title === 'Indicaci una o più preferenze per l’orario delle sedute' &&
															<div
																className={styles.QuestionIcon}>{icon()}</div>}
														onChange={(e: any) => {
															if (e.target.checked) {
																if (
																	i.title === 'Nessuna di queste' ||
																	i.title === 'Non so ancora' ||
																	i.title === 'Non ho preferenze'
																) {
																	setActualAnswerValue([index])
																} else {
																	if (noOneIndex && actualAnswerValue) {
																		const noOneAnswer = actualAnswerValue?.find(
																			(answer) => answer === noOneIndex
																		)
																		if (noOneAnswer) {
																			setActualAnswerValue([index])
																			return
																		}
																	}

																	setActualAnswerValue([
																		...actualAnswerValue,
																		index,
																	])
																}
															} else {
																setActualAnswerValue(
																	actualAnswerValue.filter((a) => a !== index)
																)
															}
														}}
													/>
												</div>
											)
										}
									)}
								</div>
								<div className={styles.btnWrapper}>
									<div className={styles.btnContainer}>
										<Button
											className={styles.backBtn}
											variant='tertiary'
											onClick={() => {
												analyticsService({'event': `survey_${actualQuestion - 1}_${getPrefixedQuestionType(questionType, questions[actualQuestion - 2]?.tracking)}`});
												setActualQuestion(actualQuestion - 1)
												prevQuestion()
												cardRef.current?.scrollIntoView()
											}}
										>
											{<ChevronLeftIcon className={styles.icon}/>} Indietro
										</Button>
										<Button
											disabled={actualAnswerValue?.length === 0}
											onClick={() => {
												nextQuestion()
												submitAnswer()
												cardRef.current?.scrollIntoView()
											}}
										>
											Avanti
										</Button>
									</div>
									{/*{privacyDisclaimer("mobile")}*/}
								</div>
							</div>
							{privacyDisclaimer('desktop')}
						</div>
					)}
			</div>
		</div>
	)
}
