import routes from "routes";
import styles from "./Privacy.module.scss";
import { analyticsService } from "../../utils/analyticsService";
import { useEffect } from "react";

export const TermAndCondition = ({ className }) => {

  useEffect(() => {
    analyticsService({ 'event': 'virtualPageview', 'pageUrl': '/termini-e-condizioni', 'pageTitle': 'Terms And Condition' });
  }, []);

  return (
    <div className={`${styles.privacy} ${styles.termsCondition} ${className}`}>
      <h2>TERMINI E CONDIZIONI GENERALI DEL SERVIZIO</h2>
      <div style={{ textAlign: "start" }} className={styles.subtitle}>
        In questa sezione sono riportate le condizioni generali (le{" "}
        <span>“Condizioni Generali”</span>) che disciplinano i termini e le
        condizioni di utilizzo del sito mymentis.it (il <span>“Sito”</span> o la{" "}
        <span>“Piattaforma”</span>) di cui è titolare e che gestisce Mymentis
        S.r.l. con sede legale in Varazze (SV), via Montegrappa n. 43, codice
        fiscale e P. IVA 01886110095, in persona del suo legale rappresentante
        pro-tempore (<span>“Mymentis”</span> o la <span>“Società”</span>),
        nonché dello svolgimento dei relativi servizi di supporto e consulenza
        psicologica e/o psicoterapeutica online offerti dalla Piattaforma (i
        <span>“Servizi”</span>). L’utente navigatore e/o che accede al Sito (l’
        <span>“Utente”</span>) dichiara di aver letto, compreso e accettato le
        presenti Condizioni Generali e le informative privacy caricate sul Sito.
        <br />
        Mymentis si riserva il diritto di aggiornare e/o modificare in qualsiasi
        momento le presenti Condizioni Generali e/o qualsivoglia ulteriore
        documentazione presente sul Sito, nel caso in cui tali aggiornamenti o
        modifiche si rendano necessari per adeguare il Sito a disposizioni di
        legge o di regolamento sopravvenute, per implementare misure di
        sicurezza che si rendano necessarie per ottimizzare o per migliorare le
        caratteristiche del Sito medesimo. Il Cliente, pertanto, riconosce ed
        accetta che sarà proprio onere controllare di volta in volta, prima di
        utilizzare il Sito e/o di fruire dei servizi ivi contenuti, le
        Condizioni Generali e l’eventuale documentazione privacy aggiornata.
        <br />
        L’Utente dichiara e riconosce che per poter accedere al Sito e fruire
        dei servizi offerti dalla Piattaforma deve aver compiuto il diciottesimo
        anno di età, ovvero, nel caso in cui l’Utente non abbia raggiunto la
        maggiore età, di essere autorizzato da coloro o colui/colei che ne ha ex
        lege la responsabilità genitoriale.
      </div>
      <div className={styles.articlesWrapper}>
        <div className={styles.article}>
          <div className={styles.articleTitle}>
            1. Registrazione dell’Utente
          </div>
          <div className={styles.articleDescription}>
            <ul style={{ listStyleType: `"1."` }}>
              <li>
                1. Accedendo al Sito, l’Utente dichiara e riconosce di essere
                interessato ai Servizi offerti dalla Piattaforma, i quali
                saranno erogati da professionisti abilitati incaricati dalla
                Società (i <span>“Professionisti”</span>). Dopo aver effettuato
                l’accesso al Sito, al fine di trovare il Professionista più
                adatto alle proprie esigenze, l’Utente dovrà compilare apposito{" "}
                <span
                  onClick={() => window.open(routes.Survey.links.intro)}
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  questionario conoscitivo
                </span>{" "}
                (il <span>“Questionario”</span>). Dopo aver compilato il
                Questionario, per usufruire dei Servizi, l’Utente dovrà
                registrarsi sulla Piattaforma (la <span>“Registrazione”</span>).
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>
            2. Scelta del Professionista e modalità di svolgimento dei Servizi
          </div>
          <div className={styles.articleDescription}>
            <ul style={{ listStyleType: `"2."` }}>
              <li>
                2. Attraverso un algoritmo basato sulle informazioni inserite
                nel Questionario e in fase di Registrazione, la Piattaforma
                selezionerà e proporrà all’Utente tre Professionisti tra cui
                scegliere, mettendo a disposizione dell’Utente le informazioni
                ritenute necessarie dalla Società su ciascun Professionista. Una
                volta che l’Utente avrà selezionato il Professionista ritenuto
                maggiormente adatto alle proprie esigenze, potrà entrare in
                contatto diretto con quest’ultimo per il tramite di apposita
                chat, così da prendere accordi circa data e orario del primo
                colloquio, il quale sarà gratuito e avrà una durata
                complessiva di circa 15 minuti (il{" "}
                <span>“Colloquio gratuito”</span>). All’esito di tale
                Colloquio gratuito l’Utente potrà decidere liberamente e
                senza vincoli se iniziare il percorso terapeutico con il
                Professionista selezionato o rivolgersi ad un altro
                Professionista.
              </li>
              <li>
                3. Nel caso in cui l’Utente decida di iniziare un percorso
                terapeutico con il Professionista selezionato, potrà fissare con
                quest’ultimo la prima seduta a pagamento (la{" "}
                <span>“Seduta”</span>), previa sottoscrizione di apposito
                consenso informato fornitogli per il tramite della Piattaforma
                (in caso di terapia di coppia, entrambi gli Utenti dovranno
                sottoscrivere apposito consenso informato) all’interno della
                propria area personale. Nel caso in cui l’Utente sia un minore
                di età, il consenso informato dovrà essere sottoscritto da
                coloro o colui/colei che ne ha ex lege la responsabilità
                genitoriale. Ciascuna Seduta singola (Utente adulto o minore)
                avrà una durata complessiva di 50 minuti, la Seduta di coppia
                avrà una durata di circa 60 minuti. Ciascuna seduta si svolgerà
                esclusivamente in modalità telematica, per il tramite di
                apposita sezione video all’interno della Piattaforma e/o
                piattaforme terze individuate dalla Società o direttamente dalle
                parti.
              </li>
              <li>
                4. Una volta che l’Utente avrà fissato l’appuntamento con il
                Professionista, dovrà procedere con il pagamento del costo della
                relativa Seduta nelle modalità meglio individuate infra.
              </li>
              <li>
                5. Ciascuna Seduta sarà fissata, di volta in volta, dall’Utente
                in accordo con il Professionista tramite la chat all’interno
                della Piattaforma (la <span>“Chat”</span>). L’Utente potrà
                contattare in ogni momento il Professionista tramite la Chat al
                fine di gestire gli appuntamenti e/o per qualsivoglia ulteriori
                dubbio relativo alle Sedute.
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>3. Servizi</div>
          <div className={styles.articleDescription}>
            <ul style={{ listStyleType: `"3."` }}>
              <li>
                1. Al momento della compilazione del Questionario, l’Utente avrà
                la possibilità di selezionare la tipologia di percorso
                psicoterapeutico/psicologico che intende seguire (in base al
                quale quindi la Piattaforma effettuerà una prima selezione dei
                Professionisti da proporre all’Utente medesimo) (i “Percorsi”):
                (i) Percorso Individuale; (ii) Percorso di Coppia; (iii)
                Percorso per i minori di età.
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>
            4. Modifica o cancellazione della Seduta – Penale
          </div>
          <div className={styles.articleDescription}>
            <ul style={{ listStyleType: `"4."` }}>
              <li>
                1. L’Utente potrà spostare o annullare la Seduta nelle seguenti
                modalità:{" "}
                <ul>
                  <li>previo accordo con il Professionista tramite la Chat;</li>
                  <li>
                    attraverso il widget dell’appuntamento confermato che si
                    trova nella Chat con il Professionista, cliccando il tasto
                    “Modifica”, si aprirà una schermata tramite cui potrà
                    spostare o cancellare in autonomia la prenotazione;
                  </li>
                  <li>
                    entrando nella sezione “Gestione appuntamenti” e poi
                    “Programmati”, cliccando sul tasto “Sposta o cancella
                    prenotazione”.
                  </li>
                </ul>
              </li>
              <li>
                2. L’Utente riconosce e accetta che potrà spostare o cancellare
                la Seduta fino a 24 ore prima dell’appuntamento con il
                Professionista. Nel caso in cui l’Utente rispetti tale termine
                di preavviso, avrà diritto al rimborso integrale dell’importo
                pagato per la Seduta posticipata o cancellata; rimborso che
                verrà effettuato dalla Società nelle stesse modalità tramite cui
                l’Utente ha effettuato il pagamento. Nel caso in cui l’Utente
                non rispetti il predetto termine di preavviso (24 ore prima
                dell’appuntamento fissato con il Professionista), non avrà
                diritto al rimborso dell’importo pagato per la Seduta
                posticipata o cancellata, il quale sarà trattenuto dalla Società
                a titolo di penale.
              </li>
              <li>
                3. Nel caso in cui il Professionista cancelli o rinvii, per
                qualsivoglia ragione, un appuntamento (anche oltre le 24 ore di
                preavviso) la Società si impegna a far recuperare all’Utente
                gratuitamente la Seduta.
              </li>
              <li>
                4. In caso di problemi tecnici della Piattaforma tali da non
                consentire il regolare svolgimento della Seduta (si fa
                riferimento a malfunzionamenti tecnici imputabili alla Società,
                che non permettono l’accesso alla videochiamata e/o all’utilizzo
                della chat per poter accedere ad alternative con altre
                tecnologie di videocall), Mymentis si impegna, a discrezione
                dell’Utente, a far recuperare gratuitamente tale Seduta, ovvero
                a rimborsarne integralmente il costo.{" "}
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>
            5. Costo delle Sedute, modalità di pagamento e fatturazione
          </div>
          <div className={styles.articleDescription}>
            <ul style={{ listStyleType: `"5."` }}>
              <li>
                1. Per ciascuna Seduta l’Utente dovrà corrispondere alla
                Società, nelle modalità meglio indicate infra, un importo fisso
                pari (il “Corrispettivo”):
                <ul>
                  <li>
                    ad euro 50,00, per la Seduta individuale (maggiore o minore
                    di età);
                  </li>
                  <li>ad euro 60,00, per la Seduta di coppia.</li>
                </ul>
              </li>
              <li>
                2. L’Utente riconosce e accetta che dovrà pagare il
                Corrispettivo per ciascuna Seduta tramite apposita sezione del
                proprio account all’interno della web-app di Mymentis, tramite
                carta di credito, carta prepagata o qualsiasi altro sistema
                accettato dal sistema di pagamento Stripe. Dopo la prima Seduta,
                l’Utente avrà la possibilità di salvare in sicurezza i propri
                dati di pagamento e procedere al pagamento delle Sedute
                successive fino a 24 ore prima dell’appuntamento fissato con il
                Professionista.
              </li>
              <li>
                3. L’Utente avrà comunque la possibilità di rimuovere o cambiare
                il proprio metodo di pagamento precedentemente salvato, tramite
                apposita sezione all’interno del proprio account sulla web-app
                su Mymentis.it (entrando nella sezione “Profilo” e infine nella
                sezione “Metodi di pagamento”).
              </li>
              <li>
                4. Per qualsiasi problematica inerente ai pagamenti e/o al
                metodo di pagamento l’Utente potrà rivolgersi al servizio
                assistenza tramite apposita sezione all’interno della
                Piattaforma.
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>
            6. Impegni, dichiarazioni e garanzie dell’Utente
          </div>
          <div className={styles.articleDescription}>
            <ul style={{ listStyleType: `"6."` }}>
              <li>
                1. Fermi gli ulteriori obblighi previsti nelle Condizioni
                Generali, l’Utente si impegna, dichiara e garantisce:
                <ol className={styles.listLetter}>
                  <li>
                    che le informazioni fornite in occasione della compilazione
                    del Questionario e in fase di Registrazione sono autentiche
                    e veritiere;
                  </li>
                  <li>
                    che si asterrà da ogni forma di utilizzo, diretto e/o
                    indiretto, della Piattaforma contrario alla legge o non
                    conforme a quanto previsto nelle presenti Condizioni
                    Generali e/o nella Piattaforma.
                  </li>
                </ol>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>7. Privacy</div>
          <div className={styles.articleDescription}>
            <ul style={{ listStyleType: `"7."` }}>
              <li>
                1. Con “Normativa Privacy” s’intendono le disposizioni del
                d.lgs. 30 giugno 2003, n. 196, integrato con le modifiche
                introdotte dal D. Lgs. 10 agosto 2018, n. 101 (di seguito, il
                “Codice”), del Regolamento UE 679/2016 (di seguito, il
                “Regolamento”), nonché di qualsiasi altra normativa sulla
                protezione dei dati personali applicabile in Italia, già in
                vigore o che entrerà in vigore successivamente alla data di
                sottoscrizione del presente Contratto, ivi compresi i
                provvedimenti del Garante per la protezione dei Dati Personali
                emanati in attuazione del Codice e/o del Regolamento.
              </li>
              <li>
                2. La Società dichiara e garantisce che tutti i dati personali
                dell’Utente raccolti in occasione della compilazione del
                Questionario e/o in fase di Registrazione saranno utilizzati
                esclusivamente ai fini dello svolgimento dei Servizi e trattati
                nel pieno rispetto delle prescrizioni di cui alla Normativa
                Privacy.
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>8. Responsabilità e manleva</div>
          <div className={styles.articleDescription}>
            <ul style={{ listStyleType: `"8."` }}>
              <li>
                1. Fermo restando quanto previsto altrove all’interno delle
                presenti Condizioni Generali, la Società non è responsabile per
                qualsiasi accesso non autorizzato o utilizzo della Piattaforma
                e/o di tutte le informazioni ivi memorizzate, nonché per i c.d.
                “virus”, contaminazione o comunque per gli effetti dannosi che
                possono essere causati al sistema informatico dell’Utente,
                ovvero per ritardi, imprecisioni, errori o omissioni che
                derivino dall’utilizzo da parte dell’Utente medesimo della
                Piattaforma o riguardo al contenuto di quest’ultima, nei limiti
                in cui ciò non sia causato direttamente o indirettamente da
                negligenza della Società. La Società non sarà responsabile per
                alcun danno che possa derivare, direttamente o indirettamente,
                dall’uso ovvero dalla incapacità di usare la Piattaforma.
              </li>
              <li>
                2. La Società non è responsabile per qualsiasi danno che possa
                derivare a carico del computer dell’Utente da qualunque
                violazione della sicurezza o da qualunque virus, bug,
                manomissione, intervento non autorizzato, truffa, errore,
                omissione, interruzione, cancellazione, difetto, ritardo nelle
                operazioni o trasmissioni, guasto delle linee del computer o
                qualsiasi altro difetto tecnico o di altro tipo, qualora un tale
                evento sia posto in relazione con la trasmissione Internet
                iniziata attraverso la Piattaforma o in altro modo e che non
                siano un risultato diretto o indiretto della negligenza della
                Società.
              </li>
              <li>
                3. L’Utente si impegna a manlevare e tenere indenne la Società
                da qualsiasi danno, responsabilità, costo e spesa derivante (i)
                dall’utilizzo dei Servizi della Piattaforma, (ii) dalla
                violazione da parte dell’Utente medesimo delle Condizioni
                Generali e/o di qualsiasi disposizione normativa e/o regolamento
                applicabile, (iii) dall’accesso alla Piattaforma da parte di
                soggetti terzi tramite l’account dell’Utente.
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>9. Proprietà intellettuale</div>
          <div className={styles.articleDescription}>
            <ul style={{ listStyleType: `"9."` }}>
              <li>
                1. La Piattaforma e tutto il suo contenuto, ivi inclusi, a
                titolo esemplificativo ma non esaustivo, testi, elenchi,
                fotografie, illustrazioni, immagini, clips video e audio e testi
                pubblicitari, così come marchi, loghi, nomi a dominio, ditte
                ovvero marchi di servizio e ogni altro materiale che possa
                essere oggetto di diritti di privativa (inclusi codici sorgente)
                e/o qualsiasi altra forma di proprietà intellettuale (di
                seguito, collettivamente, “Materiale”), sono di proprietà della
                Società, e sono protetti contro l’uso non autorizzato, la copia
                e la diffusione dalle leggi nazionali in materia di diritto
                d’autore, marchi, pubblicità ed altre leggi e dai trattati
                internazionali applicabili.
              </li>
              <li>
                2. Niente di ciò che è contenuto nelle presenti Condizioni
                Generali e/o nella Piattaforma deve essere interpretato come un
                conferimento implicito, per acquiescenza o di altro tipo, di una
                licenza o del diritto di utilizzare qualsiasi Materiale in alcun
                modo senza il preventivo consenso scritto della Società. L’uso
                non autorizzato, la copia, la riproduzione, la modifica,
                l’aggiornamento, il download, l’invio, la trasmissione, la
                distribuzione o la duplicazione o qualsiasi altro uso abusivo
                del Materiale sono proibiti.
              </li>
              <li>
                3. In qualità di utilizzatore della Piattaforma, L’Utente
                accetta di non usare il Materiale per scopi illeciti e di non
                violare i diritti della Società e/o di terzi.
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>10. Varie</div>
          <div className={styles.articleDescription}>
            <ul style={{ listStyleType: `"10."` }}>
              <li>
                1. L’eventuale tolleranza da parte della Società verso
                comportamenti dell’Utente posti in essere in violazione di una
                qualunque disposizione delle Condizioni Generali non costituisce
                rinuncia ai diritti derivanti dalla disposizione violata né al
                diritto di esigere il corretto adempimento di tutte le
                disposizioni delle Condizioni Generali stesse.
              </li>
              <li>
                2. Il mancato o ritardato esercizio di un diritto spettante alla
                Società ai sensi delle Condizioni Generali non comporta rinuncia
                allo stesso.
              </li>
              <li>
                3. Le Condizioni Generali (così come potranno essere modificate
                in futuro a discrezione della Società) contengono l’accordo
                complessivamente raggiunto dalle parti rispetto all’oggetto
                delle stesse ed esse prevalgono su tutte le precedenti
                comunicazioni, dichiarazioni, nonché le intese e gli accordi,
                sia orali che scritti, raggiunti dalle parti.
              </li>
              <li>
                4. Qualora qualsiasi termine o altra disposizione delle presenti
                Condizioni Generali fosse dichiarata nulla, annullabile o
                inapplicabile, tutte le altre condizioni e disposizioni delle
                presenti Condizioni Generali rimarranno, comunque, pienamente
                valide ed efficaci.
              </li>
              <li>
                5. Le Condizioni Generali sono disciplinate dalla legge
                italiana.{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
