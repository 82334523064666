import { FunctionComponent } from 'react';
import { TextContainer } from '../../components/TextContainer';
import Card from '../../components/Card';
import { PropsCard } from "../../components/Card";
import ContentCard from './OurModel.json';
import styles from './OurModel.module.scss';

interface PropsOurModel extends PropsCard {}
export const OurModel: FunctionComponent<PropsOurModel> = (props) => {  
  return (
    <section className={styles.ourModel}>
      <TextContainer
      titleType='h3'
      title={ContentCard.title}
      textStains={props.textStains}

      />
      <div className={styles.cards}>
      {ContentCard.cards.map((card:any, index:number) => {
          return (
            <Card
              isSmall
              key={index}
              titleType="h6"
              paragraphs={card.paragraphs}
              iconTitleSrc={card.image}
              cardStains={index === 3 ? props.cardStains : []}
            />
          );
      })}
      </div>
    </section>
  )
}