import styles from "./ModalCurrentTherapist.module.scss";
import FormHeader from "../../atoms/FormHeader";
import Button from "../../atoms/Button";

export const ModalCurrentTherapist = ({therapist, modalClose, goToFeedbackModal}: any) => {

	const closeTherapistModal = () => {
		modalClose(false);
	}

	return (
		<div className={`${styles.modal}`}>
		  <div className={styles.changePreferences}>
			  <FormHeader
				  closeModal={closeTherapistModal}
				  title=''
				  closeButton
			  />
			  <div className={styles.descritpion}>Abbiamo
				  scelto <span style={{fontWeight: '600'}}>{therapist.given_name} {therapist.family_name}</span> come
				  tua
				  terapeuta perché riteniamo che le sue competenze siano perfettamente adatte alle tue esigenze emerse
				  dal
				  questionario.
				  <br/>
				  <br/>
				  Ti incoraggiamo a dare una
				  possibilità a <span style={{fontWeight: '600'}}>{therapist.given_name} {therapist.family_name}</span>,
				  incontrandola e approfondendo la vostra conoscenza.
				  <br/>
				  <br/>
				  Tuttavia, se desideri procedere con il cambio, rispetteremo la tua scelta.
				  <br/>
				  <br/>
				  Ricorda che se decidi di cambiare terapeuta, verrai assegnato ad uno e non potrai più
				  contattare <span style={{fontWeight: '600'}}>{therapist.given_name} {therapist.family_name}</span>.
			  </div>
			  <div className={styles.btnWrapper}>
				  <Button
					  variant="secondary"
					  onClick={closeTherapistModal}
				  >
					  Annulla
				  </Button>
				  <Button
					  onClick={() => {
							modalClose(false);
							goToFeedbackModal(true)
						}}
				  >
					  Prosegui
				  </Button>
			  </div>
		  </div>
		</div>
	)
};