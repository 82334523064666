import styles from './InformedConsentView.module.scss';
import { Fragment, useEffect, useRef, useState } from 'react';
import 'jspdf-autotable';
import Button from '../../components/atoms/Button'
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs'
import { analyticsService } from '../../utils/analyticsService';
import { useParams } from 'react-router-dom';
import { trpc } from "../../utils/trpc";
import Modal from "../../components/organisms/Modal";
import { Page, Document, PDFDownloadLink } from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import ReactDOMServer from 'react-dom/server';


export const InformedConsentView = ({className}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const {id}: any = useParams();
  const [loadingToggle, setLoadingToggle] = useState(true);
  const [selectedPatientId, setSelectedPatientId] = useState("");

  const userInfo: any = trpc.mainService.therapist.getPatient.useQuery(
    {
      patientId: selectedPatientId,
    },
    {
      enabled: !!selectedPatientId,
    }
  ).data;


  const getinformedConsentResponse =
    trpc.mainService.patient.getinformedConsent.useQuery({
      patientId: selectedPatientId
    });

  const getCurrentUserInfoResponse =
    trpc.mainService.user.getCurrentUserInfo.useQuery();

  const informedConsentResponse: any = getinformedConsentResponse?.data
  const therapist = getCurrentUserInfoResponse?.data?.user

  useEffect(() => {
    analyticsService({
      'event': 'virtualPageview',
      'pageUrl': '/visualizza-il-consenso-informatow',
      'pageTitle': 'Informed Consent View'
    });
  }, []);

  useEffect(() => {
    if (id) {
      setSelectedPatientId(id)
    }
    if (userInfo && therapist && informedConsentResponse) {
      setLoadingToggle(false)
    }
  }, [id, informedConsentResponse, therapist, userInfo]);

  const content = <>
    {userInfo?.patient?.therapyType === "single"
      ? informedConsentResponse?.informedConsent && (
      <div className={styles.TherapistParty}>
        Il sottoscritto{" "}
        {informedConsentResponse.informedConsent.givenName}{" "}
        {informedConsentResponse.informedConsent.familyName},
        nato/a a{" "}
        {informedConsentResponse.informedConsent.placeOfBirth} il{" "}
        {new Date(
          informedConsentResponse.informedConsent.dateOfBirth
        ).toLocaleDateString("it", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })}
        , C.F.: {informedConsentResponse.informedConsent.taxId},
        residente a{" "}
        {informedConsentResponse.informedConsent.residence}, in
        via {informedConsentResponse.informedConsent.cap} (di
        seguito, il <span>Paziente</span>)
      </div>
    )
      : userInfo?.patient?.therapyType === "couple"
        ? informedConsentResponse?.informedConsent && (
        <div className={styles.TherapistParty}>
          Il sottoscritto{" "}
          {informedConsentResponse.informedConsent.givenName}{" "}
          {informedConsentResponse.informedConsent.familyName},
          nato/a a{" "}
          {informedConsentResponse.informedConsent.placeOfBirth} il{" "}
          {new Date(
            informedConsentResponse.informedConsent.dateOfBirth
          ).toLocaleDateString("it", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}
          , C.F.: {informedConsentResponse.informedConsent.taxId},
          residente a{" "}
          {informedConsentResponse.informedConsent.residence}, in
          via {informedConsentResponse.informedConsent.cap}
          {informedConsentResponse.otherInformedConsent && (
            <Fragment>
              {" "}
              e Il sottoscritto{" "}
              {
                informedConsentResponse.otherInformedConsent
                  .givenName
              }{" "}
              {
                informedConsentResponse.otherInformedConsent
                  .familyName
              }
              , nato/a a{" "}
              {
                informedConsentResponse.otherInformedConsent
                  .placeOfBirth
              }{" "}
              il{" "}
              {new Date(
                informedConsentResponse.otherInformedConsent.dateOfBirth
              ).toLocaleDateString("it", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
              , C.F.:{" "}
              {informedConsentResponse.otherInformedConsent.taxId},
              residente a{" "}
              {
                informedConsentResponse.otherInformedConsent
                  .residence
              }
              , in via{" "}
              {informedConsentResponse.otherInformedConsent.cap} (di
              seguito, i <span>Pazienti</span>)
            </Fragment>
          )}
        </div>
      )
        : userInfo?.patient?.therapyType === "underage" &&
        informedConsentResponse?.otherInformedConsent && (
          <div className={styles.TherapistParty}>
            Il sottoscritto{" "}
            {informedConsentResponse.otherInformedConsent.givenName}{" "}
            {informedConsentResponse.otherInformedConsent.familyName},
            nato/a a{" "}
            {
              informedConsentResponse.otherInformedConsent
                .placeOfBirth
            }{" "}
            il{" "}
            {new Date(
              informedConsentResponse.otherInformedConsent.dateOfBirth
            ).toLocaleDateString("it", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
            , C.F.:{" "}
            {informedConsentResponse.otherInformedConsent.taxId},
            residente a{" "}
            {informedConsentResponse.otherInformedConsent.residence},
            in via {informedConsentResponse.otherInformedConsent.cap}
            {informedConsentResponse.otherInformedConsentParent && (
              <Fragment>
                {" "}
                e Il sottoscritto{" "}
                {
                  informedConsentResponse.otherInformedConsentParent
                    .givenName
                }{" "}
                {
                  informedConsentResponse.otherInformedConsentParent
                    .familyName
                }
                , nato/a a{" "}
                {
                  informedConsentResponse.otherInformedConsentParent
                    .placeOfBirth
                }{" "}
                il{" "}
                {new Date(
                  informedConsentResponse.otherInformedConsentParent.dateOfBirth
                ).toLocaleDateString("it", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
                , C.F.:{" "}
                {
                  informedConsentResponse.otherInformedConsentParent
                    .taxId
                }
                , residente a{" "}
                {
                  informedConsentResponse.otherInformedConsentParent
                    .residence
                }
                , in via{" "}
                {
                  informedConsentResponse.otherInformedConsentParent
                    .cap
                }
              </Fragment>
            )}
            {informedConsentResponse.informedConsent && (
              <Fragment>
                {" "}
                nell’esercizio della responsabilità genitoriale
                sulla/sul minore (di seguito, il <span>Paziente</span>
                ) {informedConsentResponse.informedConsent.givenName}{" "}
                {informedConsentResponse.informedConsent.familyName},
                nato/a a{" "}
                {informedConsentResponse.informedConsent.placeOfBirth}{" "}
                il{" "}
                {new Date(
                  informedConsentResponse.informedConsent.dateOfBirth
                ).toLocaleDateString("it", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
                , C.F.:{" "}
                {informedConsentResponse.informedConsent.taxId},
                residente a{" "}
                {informedConsentResponse.informedConsent.residence},
                in via {informedConsentResponse.informedConsent.cap}
              </Fragment>
            )}
          </div>
        )}
    <br></br>
    {informedConsentResponse?.informedConsent && (
      <Fragment>
        <div className={styles.TherapistParty}>
          Affidandosi{" "}
          {therapist?.gender === "female"
            ? "alla dott.ssa"
            : "al dott."}{" "}
          {therapist?.given_name} {therapist?.family_name}{" "}
          {therapist?.gender === "female" ? "Iscritta" : "Iscritto"}{" "}
          all’Albo degli Psicologi della Regione{" "}
          {therapist?.licenceRegisterPlace}, assicurato con Polizza RC
          professionale sottoscritta con{" "}
          {therapist?.licenceRegisterWith} n.
          {therapist?.licenceRegisterNumber} (di seguito, il{" "}
          <span>Professionista</span>)
        </div>
        <br></br>
      </Fragment>
    )}
    <span>
							  {userInfo?.patient?.therapyType === "couple"
                  ? "I Pazienti sono informati che:"
                  : "Il Paziente è informato che:"}
							</span>
    {userInfo?.patient?.therapyType === "single" ? (
      <ol>
        <li>
          il Professionista è strettamente tenuto ad attenersi al
          Codice Deontologico degli Psicologi Italiani;
        </li>
        <li>
          la prestazione offerta riguarda la consulenza in ambito
          psicologico o psicoterapeutico attraverso colloqui in
          modalità videochiamata tramite l’utilizzo della piattaforma
          online “Mymentis”, nelle date concordate tra Paziente e
          Professionista;
        </li>
        <li>
          la prestazione è da considerarsi complessa in quanto è
          previsto il coinvolgimento di altri professionisti quali
          psicologi e/o psicoterapeuti;
        </li>
        <li>
          la prestazione è finalizzata ad attività professionali di
          promozione e tutela della salute e del benessere di persone,
          gruppi, organismi sociali e comunità. Comprende, di norma,
          tutte le attività previste ai sensi dell&#39;art. 1 della L.
          n.56/1989;
        </li>
        <li>
          per il conseguimento dell’obiettivo saranno utilizzati
          prevalentemente gli strumenti gestionali, di messaggistica e
          videochiamata disponibili sulla piattaforma online
          “Mymentis”;
        </li>
        <li>
          la durata globale dell’intervento sarà definita di comune
          accordo tra il Paziente e il Professionista;
        </li>
        <li>
          in qualsiasi momento è possibile interrompere il rapporto
          comunicando alla/al Professionista la volontà di
          interruzione;
        </li>
        <li>
          il/la Professionista può valutare ed eventualmente proporre
          l’interruzione del rapporto quando constata che non vi sia
          alcun beneficio dall’intervento e non è ragionevolmente
          prevedibile che ve ne saranno dal proseguimento dello
          stesso. Se richiesto può fornire le informazioni necessarie
          a ricercare altri e più adatti interventi (art. 27 del
          Codice Deontologico degli Psicologi Italiani);
        </li>
        <li>
          le Parti sono tenute alla scrupolosa osservanza delle date e
          degli orari degli appuntamenti che vengono concordati oppure
          all’inizio di ogni rapporto di consulenza e/o di
          psicoterapia. In caso di sopravvenuta impossibilità di
          rispettare l’appuntamento fissato, la Parte impossibilitata
          è tenuta a darne notizia all’altra in tempi congrui.
          Entrambe le Parti si impegnano a rendere attivi e
          raggiungibili i propri recapiti rispettivamente forniti;
        </li>
        <li>
          il compenso da corrispondere per la prestazione
          professionale psicologica è indicato sulla piattaforma
          online “Mymentis”. Si precisa che il compenso non può essere
          condizionato all’esito o ai risultati dell’intervento
          professionale.;
        </li>
        <li>
          Le attività di trattamento dei dati personali vengono
          effettuate in conformità con le disposizioni del Reg. UE
          2016/679 e del d.lgs. n. 196/2003 come meglio specificato
          nell’informativa privacy consegnata al Paziente.
        </li>
      </ol>
    ) : userInfo?.patient?.therapyType === "couple" ? (
      <ol>
        <li>
          il Professionista è strettamente tenuto ad attenersi al
          Codice Deontologico degli Psicologi Italiani;
        </li>
        <li>
          la prestazione offerta riguarda la consulenza in ambito
          psicologico o psicoterapeutico attraverso colloqui in
          modalità videochiamata tramite l’utilizzo della piattaforma
          online “Mymentis”, nelle date concordate tra Paziente e
          Professionista;
        </li>
        <li>
          la prestazione è da considerarsi complessa in quanto è
          previsto il coinvolgimento di altri professionisti quali
          psicologi e/o psicoterapeuti;
        </li>
        <li>
          la prestazione è finalizzata ad attività professionali di
          promozione e tutela della salute e del benessere di persone,
          gruppi, organismi sociali e comunità. Comprende, di norma,
          tutte le attività previste ai sensi dell&#39;art. 1 della L.
          n.56/1989;
        </li>
        <li>
          per il conseguimento dell’obiettivo saranno utilizzati
          prevalentemente gli strumenti gestionali, di messaggistica e
          videochiamata disponibili sulla piattaforma online
          “Mymentis”;
        </li>
        <li>
          la durata globale dell’intervento sarà definita di comune
          accordo tra i Pazienti e il Professionista;
        </li>
        <li>
          in qualsiasi momento è possibile interrompere il rapporto
          comunicando alla/al Professionista la volontà di
          interruzione;
        </li>
        <li>
          il/la Professionista può valutare ed eventualmente proporre
          l’interruzione del rapporto quando constata che non vi sia
          alcun beneficio dall’intervento e non è ragionevolmente
          prevedibile che ve ne saranno dal proseguimento dello
          stesso. Se richiesto può fornire le informazioni necessarie
          a ricercare altri e più adatti interventi (art. 27 del
          Codice Deontologico degli Psicologi Italiani);
        </li>
        <li>
          le Parti sono tenute alla scrupolosa osservanza delle date e
          degli orari degli appuntamenti che vengono concordati oppure
          all’inizio di ogni rapporto di consulenza e/o di
          psicoterapia. In caso di sopravvenuta impossibilità di
          rispettare l’appuntamento fissato, la Parte impossibilitata
          è tenuta a darne notizia all’altra in tempi congrui.
          Entrambe le Parti si impegnano a rendere attivi e
          raggiungibili i propri recapiti rispettivamente forniti;
        </li>
        <li>
          il compenso da corrispondere per la prestazione
          professionale psicologica è indicato sulla piattaforma
          online “Mymentis”. Si precisa che il compenso non può essere
          condizionato all’esito o ai risultati dell’intervento
          professionale.;
        </li>
        <li>
          Le attività di trattamento dei dati personali vengono
          effettuate in conformità con le disposizioni del Reg. UE
          2016/679 e del d.lgs. n. 196/2003 come meglio specificato
          nell’informativa privacy consegnata ai Pazienti.
        </li>
      </ol>
    ) : (
      userInfo?.patient?.therapyType === "underage" && (
        <ol>
          <li>
            il Professionista è strettamente tenuto ad attenersi al
            Codice Deontologico degli Psicologi Italiani;
          </li>
          <li>
            la prestazione offerta riguarda la consulenza in ambito
            psicologico o psicoterapeutico attraverso colloqui in
            modalità videochiamata tramite l’utilizzo della
            piattaforma online “Mymentis”, nelle date concordate tra
            Paziente e Professionista;
          </li>
          <li>
            la prestazione è da considerarsi complessa in quanto è
            previsto il coinvolgimento di altri professionisti quali
            psicologi e/o psicoterapeuti;
          </li>
          <li>
            la prestazione è finalizzata ad attività professionali di
            promozione e tutela della salute e del benessere di
            persone, gruppi, organismi sociali e comunità. Comprende,
            di norma, tutte le attività previste ai sensi
            dell&#39;art. 1 della L. n.56/1989;
          </li>
          <li>
            per il conseguimento dell’obiettivo saranno utilizzati
            prevalentemente gli strumenti gestionali, di messaggistica
            e videochiamata disponibili sulla piattaforma online
            “Mymentis”;
          </li>
          <li>
            la durata globale dell’intervento sarà definita di comune
            accordo tra il Paziente e il Professionista;
          </li>
          <li>
            in qualsiasi momento è possibile interrompere il rapporto
            comunicando alla/al Professionista la volontà di
            interruzione;
          </li>
          <li>
            il/la Professionista può valutare ed eventualmente
            proporre l’interruzione del rapporto quando constata che
            non vi sia alcun beneficio dall’intervento e non è
            ragionevolmente prevedibile che ve ne saranno dal
            proseguimento dello stesso. Se richiesto può fornire le
            informazioni necessarie a ricercare altri e più adatti
            interventi (art. 27 del Codice Deontologico degli
            Psicologi Italiani);
          </li>
          <li>
            le Parti sono tenute alla scrupolosa osservanza delle date
            e degli orari degli appuntamenti che vengono concordati
            oppure all’inizio di ogni rapporto di consulenza e/o di
            psicoterapia. In caso di sopravvenuta impossibilità di
            rispettare l’appuntamento fissato, la Parte
            impossibilitata è tenuta a darne notizia all’altra in
            tempi congrui. Entrambe le Parti si impegnano a rendere
            attivi e raggiungibili i propri recapiti rispettivamente
            forniti;
          </li>
          <li>
            il compenso da corrispondere per la prestazione
            professionale psicologica è indicato sulla piattaforma
            online “Mymentis”. Si precisa che il compenso non può
            essere condizionato all’esito o ai risultati
            dell’intervento professionale.;
          </li>
          <li>
            Le attività di trattamento dei dati personali vengono
            effettuate in conformità con le disposizioni del Reg. UE
            2016/679 e del d.lgs. n. 196/2003 come meglio specificato
            nell’informativa privacy consegnata al Paziente.
          </li>
        </ol>
      )
    )}
    {userInfo?.patient?.therapyType === "single" ? (
      <p>
        Visto e compreso tutto quanto sopra indicato, Il/la Paziente,
        avendo ricevuto apposita informativa professionale e
        informazioni adeguate in relazione a costi, fini e modalità
        della stessa dichiara e accetta di esprimere liberamente il
        proprio consenso informato per la prestazione professionale
        psicologica ivi descritta.
      </p>
    ) : userInfo?.patient?.therapyType === "couple" ? (
      <p>
        Visto e compreso tutto quanto sopra indicato, I Pazienti,
        avendo ricevuto apposita informativa professionale e
        informazioni adeguate in relazione a costi, fini e modalità
        della stessa dichiarano e accettano di esprimere liberamente
        il proprio consenso informato per la prestazione professionale
        psicologica ivi descritta.
      </p>
    ) : (
      userInfo?.patient?.therapyType === "underage" && (
        <p>
          Visto e compreso tutto quanto sopra indicato, Il/la
          Paziente, avendo ricevuto apposita informativa professionale
          e informazioni adeguate in relazione a costi, fini e
          modalità della stessa dichiara e accetta di esprimere
          liberamente il proprio consenso informato, nell’esercizio
          della responsabilità genitoriale sulla/sul minore, per la
          prestazione professionale psicologica ivi descritta.
        </p>
      )
    )}
    <p>
      Informativa Accettata da {informedConsentResponse?.informedConsent?.givenName}{" "}
      {informedConsentResponse?.informedConsent?.familyName} in{" "}
      {dayjs(informedConsentResponse?.informedConsent?.date_created).format('DD/MM/YYYY [ale ore] HH:mm')}
    </p>
  </>


  const stylesheet: any = {
    page: {
      paddingVertical: 32,
      paddingHorizontal: 32,
      color: 'rgba(0,0,0,0.80)',
    },
    div: {
      fontSize: 12,
      margin: 0,
    },
    span: {
      color: '#000000',
      fontSize: 12,
    },
    ol: {
      fontSize: 12,
    },
    p: {
      fontSize: 10,
      marginTop: 0,
      marginBottom: 16,
    }
  };

  const html = ReactDOMServer.renderToStaticMarkup(<html>{content}</html>);

  const Doc: any = () => (
    <Document>
      <Page size="A4" wrap={false} style={stylesheet.page}>
        <Html stylesheet={stylesheet}>{html}</Html>
      </Page>
    </Document>
  );


  return (
    <div className={`${styles.information} ${className}`}>
      {loadingToggle ?
        <Modal>
          <div>Loading...</div>
        </Modal>
        :
        <>
          <div className={styles.buttonWrapper}>
            <Button
              leftIcon={<DocumentArrowDownIcon/>}
            ><PDFDownloadLink document={<Doc/>}
                              fileName="consenso-informato.pdf">
              {({blob, url, loading, error}) =>
                loading ? 'Loading...' : 'Scarica il PDF'
              }
            </PDFDownloadLink></Button>
          </div>
          <div ref={contentRef}>
            {content}
          </div>
        </>
      }
    </div>
  );
};
