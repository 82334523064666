import { FunctionComponent } from 'react'
import Text from '../../../atoms/Text';
import Card from '../../components/Card';
import { PropsCard } from '../../components/Card';
import ContentSection from './ImprovedQualityOfLife.json'
import styles from './ImprovedQualityOfLife.module.scss';

export interface ImprovedQualityOfLifeProps extends PropsCard{}

export const ImprovedQualityOfLife:FunctionComponent<ImprovedQualityOfLifeProps> = (props) => {
  const imageStains = props.imageStains;
  return (
    <section className={styles.improvedQualityOfLife}>
      <Text type='h3' children={ContentSection.sectionTitle}/>
        <div className={styles.cards}>
          {ContentSection.cards.map((card:any, index:number) => {
            if(index < 4) {
              return (
                <Card
                key={index}
                titleType="h4"
                title={card.title}
                paragraphs={card.paragraphs}
                link={card.link}
                src={card.srcImage}
                textRight = {index===2 && true}
                // imageStains={imageStains && [imageStains[index]]}
                />
              );
            }
          })}
          <div className={styles.bottomCards}>
          {ContentSection.cards.map((card:any, index:number) => {
            if(index >= 4) {
              return (
                <Card
                key={index}
                titleType="h4"
                title={card.title}
                paragraphs={card.paragraphs}
                link={card.link}
                src={card.srcImage}
                imageStains={(imageStains && index === 5) ? [imageStains[3]] : []}
                />
              );
            }
          })}
          </div>
        </div>
    </section>
  )
}
