import styles from "./PresentationCard.module.scss";
import therapistPhoto from "../../../theme/images/therapistPhoto.png";
import Button from "../../atoms/Button";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { trpc } from "../../../utils/trpc";
import { useAppDispatch } from "../../../customHooks/reduxHooks";
import { setInfo } from "../../../store/slices/infoSlice";
import { getCPS } from "utils/general";
import { Fragment, useState } from "react";
import routes from "routes";
import { envs } from "utils/envs";
import AvatarImage from "theme/images/avatar.png";
import { queryClient } from "index";
import { analyticsService } from "../../../utils/analyticsService";
import { raiseException } from "../../../utils/raiseException";

interface Props {
  given_name: string;
  family_name: string;
  description: string;
  soloCard?: boolean;
  interventionAreas: string[];
  id: string;
  gender: "male" | "female";
  title: string;
  licenceRegisterPlace: string;
  graduationCourse: string;
  graduationPlace: string;
  className?: string;
}

export const PresentationCard = ({
  given_name,
  family_name,
  description,
  soloCard,
  interventionAreas,
  id,
  gender,
  title,
  licenceRegisterPlace,
  graduationCourse,
  graduationPlace,
  className,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [specToShow, setSpecToShow] = useState(3);

  const [photoSrc, setPhotoSrc] = useState(
    `${envs.REACT_APP_S3_ENDPOINT}/pic-${id}`
  );

  const chooseTherapist =
    trpc.mainService.patient.chooseTherapist.useMutation();
  const showDetail = () => {
    navigate(routes.TherapistDetail.path, {
      state: {
        given_name,
        family_name,
        description,
        id,
        specializations: interventionAreas,
        gender,
        graduationCourse,
        graduationPlace,
        licenceRegisterPlace,
        title,
      },
    });
  };

  const getTherapist = () => {
    chooseTherapist
      .mutateAsync({ therapistId: id, therapistChange: false })
      .then((data) => {
        queryClient.removeQueries();
        analyticsService({ 'event': 'therapist_selected', 'therapist': `${given_name ? given_name : ""} ${family_name ? family_name : ""}` });
        navigate(routes.Chat.path);
      })
      .catch((e) => {
        raiseException(e);
        dispatch(
          setInfo({
            variant: "error",
            text: e.message,
            title: "Errore scelta terapeuta",
          })
        );
      });
  };

  return (
    <div
      className={`${
        soloCard ? styles.soloCard : styles.presentationCard
      } ${className}`}
    >
      <img
        src={photoSrc}
        alt="therapist photo"
        className={styles.photo}
        onError={() => {
          setPhotoSrc(AvatarImage);
        }}
      />
      <div className={styles.infoWrapper}>
        <h5 className={styles.name}>
          {given_name ? given_name : ""} {family_name ? family_name : ""}
        </h5>
        <div
          className={`${styles.description} ${
            !soloCard ? styles.clipText : ""
          }`}
        >
          {description ? description : ""}
        </div>

        {soloCard && (
          <Fragment>
            <div className={styles.aboutDoc}>
              <div className={styles.aboutTitle}>
                Iscritt{gender === "male" ? "o" : "a"} all'albo degli psicologi
              </div>
              <div className={styles.aboutText}>
                Regione {licenceRegisterPlace}
              </div>
            </div>
            <div className={styles.aboutDoc}>
              <div className={styles.aboutTitle}>Laureato in</div>
              <div className={styles.aboutText}>
                {graduationCourse} presso {graduationPlace}
              </div>
            </div>{" "}
            <div className={styles.aboutDoc}>
              <span className={styles.aboutTitle}>{title}</span>
            </div>
          </Fragment>
        )}

        {soloCard && (
          <div className={styles.areaWrapper}>
            {getCPS(interventionAreas).map((i, index) => {
              if (index + 1 < specToShow)
                return (
                  <Button
                    key={index}
                    variant="secondary"
                    className={styles.areasBtn}
                  >
                    {i}
                  </Button>
                );
            })}
            {getCPS(interventionAreas!).length > 3 && specToShow === 3 && (
              <Button
                onClick={() => setSpecToShow(getCPS(interventionAreas!).length)}
                variant="secondary"
                className={styles.areasBtn}
              >
                + {interventionAreas && getCPS(interventionAreas).length - 3}
              </Button>
            )}
            {getCPS(interventionAreas!).length > 3 && specToShow > 3 && (
              <Button
                variant="secondary"
                className={styles.areasBtn}
                onClick={() => setSpecToShow(3)}
              >
                -
              </Button>
            )}
          </div>
        )}
        <div className={styles.btnWrapper}>
          <Button
            className={styles.backBtn}
            variant="tertiary"
            leftIcon={<ChevronLeftIcon />}
            onClick={() => {
              navigate(routes.ChooseTherapist.path);
            }}
          >
            Indietro
          </Button>
          <Button
            onClick={() => {
              soloCard ? getTherapist() : showDetail();
            }}
          >
            {soloCard ? "Scegli" : "Scopri di più"}
          </Button>
        </div>
      </div>
    </div>
  );
};
