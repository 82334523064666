import { raiseException } from "./raiseException";

export const decodeB64 = (string: string) => {
    try {
      const stringObj = atob(string);
      const jsonObj = JSON.parse(stringObj);
      return jsonObj;
    } catch (err: any) {
      raiseException(err);
      console.error(err);
    }
  };
  