import { useNavigate } from "react-router-dom";
import { IntroductorySection } from "../../components/landingPageComponents/sections/IntroductorySection/IntroductorySection";
import SimpleSection from "../../components/landingPageComponents/sections/SimpleSection";
import OurModel from "../../components/landingPageComponents/sections/OurModel";
import Footer from "../../components/landingPageComponents/components/Footer";
import {
  SimpleSectionContent,
  IntroductoryAboutUsContent,
  AboutUsStains,
} from "./AboutUsContentText";
import styles from "./AboutUs.module.scss";
import { Helmet } from "react-helmet";
import { envs } from "utils/envs";
import routes from "routes";
import { analyticsService } from "../../utils/analyticsService";
import { useEffect } from "react";

const appSiteUrl = envs.REACT_APP_SITE_URL;

export const AboutUs = () => {
  const navigate = useNavigate();

  useEffect(() => {
    analyticsService({'event': 'virtualPageview', 'pageUrl': '/chi-siamo', 'pageTitle': 'About Us'});
  }, []);

  return (
    <main>
      <Helmet>
        <title>Chi siamo</title>
        <meta
          name="description"
          content="Con Mymentis vogliamo dimostrare che un servizio di psicologia online può essere d'eccellenza. La nostra missione è quella di garantire che ciascun individuo possa beneficiare del massimo della cura e dell'attenzione, indipendentemente da dove si trovi."
        />
        <meta property="og:title" content="Chi siamo" />
        <meta
          property="og:description"
          content="Con Mymentis vogliamo dimostrare che un servizio di psicologia online può essere d'eccellenza. La nostra missione è quella di garantire che ciascun individuo possa beneficiare del massimo della cura e dell'attenzione, indipendentemente da dove si trovi."
        />
        {envs.REACT_APP_ENVIRONMENT === "Prod" ? (
          <link
            rel="canonical"
            href={`${appSiteUrl}${routes.AboutUs.path}`}
          />
        ) : null}
      </Helmet>
      <div className={styles.aboutUs}>
        <IntroductorySection
          content={IntroductoryAboutUsContent}
          textStains={AboutUsStains.introductorySection.textStains}
          hasLabel
        />
        <SimpleSection
          content={SimpleSectionContent.ourHistory}
          imageStains={AboutUsStains.ourHistory.imageStains}
        />
        <OurModel
          textStains={AboutUsStains.ourModel.textStains}
          cardStains={AboutUsStains.ourModel.cardStains}
        />
        <SimpleSection
          content={SimpleSectionContent.ourStructure}
          imageStains={AboutUsStains.ourStructure.imageStains}
          textStains={AboutUsStains.ourStructure.textStains}
          cardStains={AboutUsStains.ourStructure.textStains}
        />
        <Footer />
      </div>
    </main>
  );
};
