import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface PatientNameState {
  name: string;
  surname: string;
  id: string;
}
const initialState: PatientNameState = {
  name: "",
  surname: "",
  id: "",
};

const patientInChatSlice = createSlice({
  name: "patientInChat",
  initialState,
  reducers: {
    setPatientName(state, action: PayloadAction<PatientNameState>) {
      state.name = action.payload.name;
      state.surname = action.payload.surname;
      state.id = action.payload.id;
    },
  },
});
export default patientInChatSlice.reducer;
export const { setPatientName } = patientInChatSlice.actions;
