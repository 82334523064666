import { PaperClipIcon } from '@heroicons/react/24/outline';
import ButtonSend from '../../atoms/ButtonSend';
import styles from './TextAreaInput.module.scss';
import { CalendarDaysIcon } from '@heroicons/react/20/solid';
import { useRouteLoaderData } from 'react-router-dom';
import { useRef } from 'react';
import { trpc } from 'utils/trpc';
import { Input } from 'antd';
import { Socket } from 'socket.io-client';
import { useAppDispatch, useAppSelector } from 'customHooks/reduxHooks';
import Button from 'components/atoms/Button';
import { setPatientOnBoarding } from 'store/slices/onBoardingDoneSlice';
import { raiseException } from '../../../utils/raiseException';

interface Props {
  sendMessage: () => void;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  checkIfTyping: () => void;
  scrollToBottom: () => void;
  value: string;
  chatId: string;
  appointmentAction?: () => void;
  onModify: (appointmentId: any, messageId: number) => void;
  oldMessageDateIsVisible: boolean;
  appointmentIcon?: boolean;
  className?: string;
  messagesList: any;
}
export const TextAreaInput = ({
  oldMessageDateIsVisible,
  scrollToBottom,
  sendMessage,
  value,
  onChange,
  checkIfTyping,
  chatId,
  appointmentIcon,
  appointmentAction,
  className,
  onModify,
  messagesList
}: Props) => {
  const dispatch = useAppDispatch();
  const socket = useRouteLoaderData("root") as Socket;
  const fileUploadInput = useRef<HTMLInputElement>(null);

  const onboarding = useAppSelector((store) => store.onBoardingDone);

  const generateUploadFileUrl =
    trpc.mainService.chat.generateUploadFileUrl.useMutation();
  const onboardingService =
    trpc.mainService.user.completeOnboarding.useMutation();

  const completeOnboarding = () => {
    onboardingService
      .mutateAsync()
      .then((data) => {
        dispatch(setPatientOnBoarding(false));
      })
      .catch((e) => {
        raiseException(e);
        console.error(e?.shape?.message)
      });
  };

  const getFirstAppointmentData = () => {
    if (messagesList.length) {
      const moreThanOneAppointment = messagesList.filter(el => el.appointment);
      if (moreThanOneAppointment.length) {
        return messagesList.find((el) => {
          const { appointment } = el || {};
          if (appointment?.id && appointment.isFirstAppointment && ["ready", "waiting_for_confirm"].includes(appointment.status)) {
            return appointment;
          }
        })?.appointment;
      }
    }
  }

  const openAppointmentWindow = () => {
    const appointment: any = getFirstAppointmentData();
    const firstAppointmentIsPast = new Date(appointment?.endTime).getTime() < new Date().getTime();
    if (!appointment?.isFirstAppointment || firstAppointmentIsPast) {
      if (appointmentAction) {
        appointmentAction();
      }
    } else {
      onModify(appointment, appointment?.id);
    }
  }

  return (
    <div
      className={`${styles.inputTextWrapper} ${className} ${
        onboarding.checkpoint && styles.onboarding
      }`}
    >
      {onboarding.checkpoint && (
        <div className={styles.onboardingDescription}>
          <div className={styles.triangle}></div>
          <span>
            Potrai parlare con il tuo terapeuta prima di iniziare a programmare
            gli appuntamenti.
          </span>
          <Button onClick={() => completeOnboarding()}>Inizia subito</Button>
        </div>
      )}
      <div className={styles.iconWrapper}>
        <input
          style={{ display: "none" }}
          ref={fileUploadInput}
          type="file"
          onChange={async (e) => {
            const { url, fileLocation } =
              await generateUploadFileUrl.mutateAsync({
                chatId,
              });
            const file = e.target.files?.item(0);
            if (file) {
              await fetch(url, {
                method: "PUT",
                body: file,
              });
              socket.emit(
                "sendFileMessage",
                {
                  type: "file",
                  fileLocation,
                  filename: file?.name ?? "file",
                },
                chatId
              );
            }
          }}
        />
        <div
          className={styles.icon}
          onClick={() => {
            fileUploadInput.current?.click();
          }}
        >
          {/* TODO: cambiare la funzione di onClick  */}
          <PaperClipIcon />
        </div>
      </div>
      {appointmentIcon && (
        <div className={styles.iconWrapper}>
          <div className={styles.icon}
             onClick={() => {
               openAppointmentWindow();
             }}>
            {/* TODO: cambiare la funzione di onClick  */}
            <CalendarDaysIcon />
          </div>
        </div>
      )}
      <div className={styles.Input}>
        <Input.TextArea
          placeholder="Scrivi il tuo messaggio"
          autoSize={{ minRows: 1, maxRows: 3 }}
          bordered={false}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              sendMessage();
            } else {
              checkIfTyping();
            }
          }}
        />
        <ButtonSend onClick={sendMessage} />
      </div>
      {/* TODO: feature non ancora richiesta
      <ButtonScrollToBottom
        isVisible={oldMessageDateIsVisible}
        scrollToBottom={scrollToBottom}
      /> */}
    </div>
  );
};
