const getTrackingCampaignCookiesValue = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts: any = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts?.pop().split(';').shift();
  }
  return null;
}

export const getTrackingCampaignUtmData = (name) => {
  const utmDataString = getTrackingCampaignCookiesValue(name);

  if (!utmDataString) {
    return null;
  }

  try {
    return JSON.parse(decodeURIComponent(utmDataString));
  } catch (e) {
    console.error('Error parsing utm_data cookie', e);
    return null;
  }
}

export const deleteCookie = (name: string) => {
  document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; secure; samesite=strict`;
}
