import { useLocation } from "react-router-dom";
import styles from "./TherapistDetail.module.scss";
import PresentationCard from "../../components/molecules/PresentationCard";
import { analyticsService } from "../../utils/analyticsService";
import { useEffect } from "react";

export const TherapistDetail = ({ className }: any) => {
  const location = useLocation();
  const { state } = location;
  const {
    gender,
    graduationCourse,
    graduationPlace,
    licenceRegisterPlace,
    title,
    description,
    given_name,
    family_name,
    id,
    specializations,
  } = state;

  useEffect(() => {
    analyticsService({
      'event': 'virtualPageview',
      'pageUrl': '/scelta-terapeuta/dettagli',
      'pageTitle': 'Therapist Detail'
    });
  }, []);

  return (
    <div className={`${styles.therapisttDetail} ${className}`}>
      <PresentationCard
        className={styles.soloCardVariant}
        description={description}
        family_name={family_name}
        given_name={given_name}
        id={id}
        interventionAreas={specializations}
        soloCard
        gender={gender}
        graduationCourse={graduationCourse}
        graduationPlace={graduationPlace}
        licenceRegisterPlace={licenceRegisterPlace}
        title={title}
      />
    </div>
  );
};
