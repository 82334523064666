import Button from "components/atoms/Button";
import Input from "components/atoms/Input";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { trpc } from "utils/trpc";
import * as yup from "yup";
import styles from "./TherapistBillingDetails.module.scss";
import { useAppDispatch } from "customHooks/reduxHooks";
import { setInfo } from "store/slices/infoSlice";
import Modal from "../Modal";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { analyticsService } from "../../../utils/analyticsService";
import { raiseException } from "../../../utils/raiseException";
import trimString from "../../../utils/trimString";

export const TherapistBillingDetails = () => {
  const dispatch = useAppDispatch();

  const [billingDetails, setBillingDetails] = useState<{
    given_name: string;
    family_name: string;
    address: string;
    phone_number: string;
    city: string;
    country: string;
    taxId: string;
    vatNumber: string;
    iban: string;
  }>({
    given_name: "",
    family_name: "",
    address: "",
    phone_number: "",
    city: "",
    country: "",
    taxId: "",
    vatNumber: "",
    iban: "",
  });

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const formik = useFormik({
    initialValues: {
      given_name: "",
      family_name: "",
      address: "",
      phone_number: "",
      city: "",
      country: "",
      taxId: "",
      vatNumber: "",
      iban: "",
    },
    validationSchema: yup.object().shape({
      taxId: yup
        .string()
        .length(16, "Il codice fiscale deve contenere 16 caratteri"),
      vatNumber: yup
        .string()
        .length(11, "La partita iva deve contenere 11 caratteri"),
    }),
    onSubmit: (values, actions) => {
      // register({
      //   givenName: values.givenName,
      //   familyName: values.familyName,
      //   email: values.email,
      //   emailPartner: values.emailPartner,
      //   password: values.password,
      // });
      saveBillingDetails({
        taxId: values.taxId,
        vatNumber: values.vatNumber,
      });
    },
  });

  useEffect(() => {
    analyticsService({
      'event': 'virtualPageview',
      'pageUrl': '/dashboard/profilo/fatturazione',
      'pageTitle': 'Therapist Billing Details'
    });
  }, []);

  const getBillingDetailsResponse =
    trpc.mainService.therapist.getBillingDetails.useQuery();

  const updateBillingDetails =
    trpc.mainService.therapist.updateBillingDetails.useMutation();

  useEffect(() => {
    if (getBillingDetailsResponse.isSuccess) {
      setBillingDetails({
        given_name:
          getBillingDetailsResponse.data.billingDetails.given_name ?? "",
        family_name:
          getBillingDetailsResponse.data.billingDetails.family_name ?? "",
        address: getBillingDetailsResponse.data.billingDetails.address ?? "",
        phone_number:
          getBillingDetailsResponse.data.billingDetails.phone_number ?? "",
        city: getBillingDetailsResponse.data.billingDetails.city ?? "",
        country: getBillingDetailsResponse.data.billingDetails.country ?? "",
        taxId: getBillingDetailsResponse.data.billingDetails.taxId ?? "",
        vatNumber:
          getBillingDetailsResponse.data.billingDetails.vatNumber ?? "",
        iban: getBillingDetailsResponse.data.billingDetails.iban ?? "",
      });
    }
  }, [getBillingDetailsResponse.data]);

  const saveBillingDetails = ({
    taxId,
    vatNumber,
  }: {
    taxId: string;
    vatNumber: string;
  }) => {
    updateBillingDetails
      .mutateAsync({
        givenName: trimString(billingDetails.given_name),
        familyName: trimString(billingDetails.family_name),
        address: trimString(billingDetails.address),
        phoneNumber: trimString(billingDetails.phone_number),
        city: trimString(billingDetails.city),
        country: trimString(billingDetails.country),
        taxId: trimString(taxId) || trimString(billingDetails.taxId),
        vatNumber: trimString(vatNumber) || trimString(billingDetails.vatNumber),
        iban: trimString(billingDetails.iban),
      })
      .then((data) => {
        getBillingDetailsResponse.refetch();
        dispatch(
          setInfo({ text: "Dati aggiornati con successo", variant: "confirm" })
        );
      })
      .catch((e) => {
        raiseException(e);
        dispatch(
          setInfo({
            variant: "error",
            text: "Si è verificato un errore durante il salvataggio delle informazioni",
          })
        );
      });
  };

  const cancelSaveBillingDetails = async () => {
    if (getBillingDetailsResponse.isSuccess) {
      setBillingDetails({
        given_name:
          getBillingDetailsResponse.data.billingDetails.given_name ?? "",
        family_name:
          getBillingDetailsResponse.data.billingDetails.family_name ?? "",
        address: getBillingDetailsResponse.data.billingDetails.address ?? "",
        phone_number:
          getBillingDetailsResponse.data.billingDetails.phone_number ?? "",
        city: getBillingDetailsResponse.data.billingDetails.city ?? "",
        country: getBillingDetailsResponse.data.billingDetails.country ?? "",
        taxId: getBillingDetailsResponse.data.billingDetails.taxId ?? "",
        vatNumber:
          getBillingDetailsResponse.data.billingDetails.vatNumber ?? "",
        iban: getBillingDetailsResponse.data.billingDetails.iban ?? "",
      });
    }
  };

  return (
    <div className={styles.TherapistBillingDetails}>
      {updateBillingDetails.isLoading && (
        <Modal className="loadingModal">
          <Spin
            indicator={
              <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
            }
          />
        </Modal>
      )}
      <h1 className={styles.title}>Dati di fatturazione</h1>
      <div>
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <div className={styles.billingDetailsForm}>
            <div className={styles.billingDetailsInputContainer}>
              <Input
                className={styles.billingDetailsInput}
                label="Nome"
                name="given_name"
                type="text"
                value={billingDetails.given_name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setBillingDetails((state) => ({
                    ...state,
                    given_name: e.target.value,
                  }))
                }
                // onChange={formik.handleChange}
              />
              <Input
                className={styles.billingDetailsInput}
                label="Cognome"
                type="text"
                value={billingDetails.family_name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setBillingDetails((state) => ({
                    ...state,
                    family_name: e.target.value,
                  }))
                }
              />
            </div>
            <div className={styles.billingDetailsInputContainer}>
              <Input
                className={styles.billingDetailsInput}
                label="Indirizzo"
                type="text"
                value={billingDetails.address}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setBillingDetails((state) => ({
                    ...state,
                    address: e.target.value,
                  }))
                }
              />
              <Input
                className={styles.billingDetailsInput}
                label="Telefono"
                type="text"
                value={billingDetails.phone_number}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setBillingDetails((state) => ({
                    ...state,
                    phone_number: e.target.value,
                  }))
                }
              />
            </div>
            <div className={styles.billingDetailsInputContainer}>
              <Input
                className={styles.billingDetailsInput}
                label="Città"
                type="text"
                value={billingDetails.city}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setBillingDetails((state) => ({
                    ...state,
                    city: e.target.value,
                  }))
                }
              />
              <Input
                className={styles.billingDetailsInput}
                label="Paese"
                type="text"
                value={billingDetails.country}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setBillingDetails((state) => ({
                    ...state,
                    country: e.target.value,
                  }))
                }
              />
            </div>
            <div className={styles.billingDetailsInputContainer}>
              <Input
                className={styles.billingDetailsInput}
                label="Partita IVA"
                type="text"
                name="vatNumber"
                value={formik.values.vatNumber || billingDetails.vatNumber}
                // onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                //   setBillingDetails((state) => ({
                //     ...state,
                //     vatNumber: e.target.value,
                //   }))
                // }
                onChange={(e) => {
                  if (e.target.value.trim() === "") {
                    setBillingDetails((state) => ({
                      ...state,
                      vatNumber: "",
                    }));
                  } else {
                    formik.handleChange(e);
                  }
                }}
                error={
                  (formik.errors.vatNumber &&
                    formik.touched.vatNumber &&
                    formik.errors.vatNumber) as string
                }
              />
              <Input
                className={styles.billingDetailsInput}
                label="Codice fiscale"
                type="text"
                name="taxId"
                value={formik.values.taxId || billingDetails.taxId}
                // onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                //   setBillingDetails((state) => ({
                //     ...state,
                //     taxId: e.target.value,
                //   }))
                // }
                onChange={(e) => {
                  if (e.target.value.trim() === "") {
                    setBillingDetails((state) => ({
                      ...state,
                      taxId: "",
                    }));
                  } else {
                    formik.handleChange(e);
                  }
                }}
                error={
                  (formik.errors.taxId &&
                    formik.touched.taxId &&
                    formik.errors.taxId) as string
                }
              />
            </div>
            <Input
              className={styles.billingDetailsInput}
              label="IBAN"
              type="text"
              value={billingDetails.iban}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setBillingDetails((state) => ({
                  ...state,
                  iban: e.target.value,
                }))
              }
            />
          </div>
          <button
            type="submit"
            className={styles.hiddenButton}
            ref={submitButtonRef}
          />
        </form>
        <div className={styles.formButtonsContainer}>
          <Button onClick={cancelSaveBillingDetails} variant="secondary">
            Annulla
          </Button>
          <Button
            onClick={() => {
              submitButtonRef.current?.click();
            }}
          >
            Salva
          </Button>
        </div>
      </div>
    </div>
  );
};
