import {FunctionComponent} from 'react'
import styles from './Image.module.scss'
interface props {
  src: string | undefined;
  alt: string;
  className?: string
}
export const Image:FunctionComponent<props> = (props) => {
  return (
    <>
    <img className={`${styles.image} ${props.className}`} src={props.src} alt={props.alt} />
    </>
  )
}
