import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface Therapist {
  id: string;
  given_name: string;
  family_name: string;
  gender: string;
  description: string;
}

interface messageState {
  myTherapist: Therapist | null | undefined;
}

const initialState: messageState = {
  myTherapist: null,
};

const myTherapistSlice = createSlice({
  name: "myTherapist",
  initialState,
  reducers: {
    setMyTherapist(state, action: PayloadAction<any>) {
      state.myTherapist = action.payload;
    },
  },
});

export default myTherapistSlice.reducer;
export const { setMyTherapist } = myTherapistSlice.actions;
