const recountAmountValue = (couponInfo: any, surveys: any, amountValue: number): number => {
  if (!couponInfo?.id) {
    return amountValue;
  }

  if (couponInfo.discount_type === 'percentage') {
    if (couponInfo.discount_value === 100) {
      return 0;
    }
    return amountValue - (amountValue * (couponInfo.discount_value / 100));
  } else {
    const surveyType = surveys?.at(0)?.type;
    if (
      (couponInfo.discount_value === 50 && surveyType !== 'couple') ||
      (couponInfo.discount_value === 60 && surveyType === 'couple')
    ) {
      return 0;
    } else {
      return amountValue - couponInfo.discount_value;
    }
  }
}

export default recountAmountValue;
