import { useEffect, useState } from "react";
import styles from "./VerifyEmail.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { trpc } from "utils/trpc";
import { decodeB64 } from "utils/decodeB64";
import GenericForm from "components/molecules/GenericForm";
import Button from "components/atoms/Button";
import { getAccessToken } from "utils/getAccessToken";
import routes from "routes";
import { getToken } from "utils/getToken";
import { analyticsService } from "../../utils/analyticsService";
import { raiseException } from "../../utils/raiseException";

export const VerifyEmail = ({ className }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [verifiedSuccesfully, setVerifiedSuccesfully] = useState<boolean>();

  const verifyEmail = trpc.mainService.user.verifyEmail.useMutation();
  const getCurrentUserInfoResponse =
    trpc.mainService.user.getCurrentUserInfo.useQuery(undefined, {
      enabled: !!getAccessToken(),
    });

  useEffect(() => {
    analyticsService({ 'event': 'virtualPageview', 'pageUrl': `/verifica-email/`, 'pageTitle': 'Verify Email' });
  }, []);

  useEffect(() => {
    (async () => {
      if (loading) {
        try {
          const decodeParam = decodeB64(params.code!);

          await verifyEmail.mutateAsync({
            email: decodeParam.email!,
            code: decodeParam.code!,
          });

          setVerifiedSuccesfully(true);
        } catch (e: any) {
          raiseException(e);
          setVerifiedSuccesfully(false);
        }
        setLoading(false);
      }
    })();
  }, [loading]);

  return (
    <div className={`${styles.verifyEmail} ${className}`}>
      {(() => {
        if (loading) {
          return "";
        } else {
          if (verifiedSuccesfully) {
            return (
              <GenericForm
                className={styles.verifyResult}
                title={"Email verificata con successo"}
                description={"La verifica della mail è andata a buon fine."}
                cta={[
                  <Button
                    variant="primary"
                    onClick={async () => {
                      const accessToken = getToken("accessToken");
                      const result = await getCurrentUserInfoResponse.refetch();
                      if (accessToken) {
                        if (result.data?.user?.role === "therapist") {
                          return navigate(routes.ChooseTherapist.path);
                        }

                        if (!result.data?.user?.therapistId) {
                          navigate(routes.ChooseTherapist.path, {
                            ...(result.data?.user?.emailVerified
                              ? {}
                              : {
                                  state: result.data?.user?.email,
                                }),
                          });
                        } else {
                          navigate(routes.ChooseTherapist.path);
                        }
                      } else {
                        navigate(routes.Login.path);
                      }
                    }}
                  >
                    Accedi
                  </Button>,
                ]}
              />
            );
          } else {
            return (
              <GenericForm
                className={styles.verifyResult}
                title={"Link non valido."}
                description={
                  "Questo link per la verifica email è scaduto o non valido. Accedi per richiederne un altro."
                }
                cta={[
                  <Button
                    variant="primary"
                    onClick={async () => {
                      const accessToken = getToken("accessToken");
                      const result = await getCurrentUserInfoResponse.refetch();
                      if (accessToken) {
                        if (result.data?.user?.role === "therapist") {
                          return navigate(routes.ChooseTherapist.path);
                        }

                        if (!result.data?.user?.therapistId) {
                          navigate(routes.ChooseTherapist.path, {
                            ...(result.data?.user?.emailVerified
                              ? {}
                              : {
                                  state: result.data?.user?.email,
                                }),
                          });
                        } else {
                          navigate(routes.ChooseTherapist.path);
                        }
                      } else {
                        navigate(routes.Login.path);
                      }
                    }}
                  >
                    Accedi
                  </Button>,
                ]}
              />
            );
          }
        }
      })()}
    </div>
  );
};
