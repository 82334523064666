import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface onboardingState {
  checkpoint: boolean;
}

const initialState: onboardingState = {
  checkpoint: false,
};

const onBoardingSlice = createSlice({
  name: "onBoarding",
  initialState,
  reducers: {
    setPatientOnBoarding(state, action: PayloadAction<boolean>) {
      state.checkpoint = action.payload;
    },
  },
});
export default onBoardingSlice.reducer;
export const { setPatientOnBoarding } = onBoardingSlice.actions;
