import styles from "./Chip.module.scss";

interface Props {
  children: any;
  className?: string;
  size?: "small" | "medium" | "large";
  onClick?: () => void;
  active?: boolean;
}

export const Chip = ({
  children,
  className = "",
  size = "small",
  onClick,
  active,
}: Props) => {
  
  return (
    <div
      onClick={onClick}
      className={`${styles.chip} ${styles[className]} ${styles[size]} ${
        active && styles.active
      }`}
    >
      {children}
    </div>
  );
};
