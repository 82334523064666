import { CalendarDaysIcon, UserIcon, UsersIcon } from "@heroicons/react/24/solid";
import styles from "./PatientChatCard.module.scss";
import { dateToShow } from "../../../utils/showDate";
import { trpc } from "../../../utils/trpc";
import parentIcon from "../../../theme/images/parentIcon.png";

interface Props {
  patientId?: any;
  patientName: string;
  unreadMessages: number;
  lastMessage: any;
  lastAppointment: any;
  isSelected?: boolean;
}

export const PatientChatCard = ({
  patientId,
  patientName,
  unreadMessages,
  lastAppointment,
  lastMessage,
  isSelected = false,
}: Props) => {

  const getPatient = (patientId: any) => {
    if (patientId) {
      return trpc.mainService.therapist.getPatient.useQuery(
        {
          patientId: patientId,
        },
        {
          enabled: true
        }
      ).data;
    }
  };

  const therapyTypeObject: any = getPatient(patientId);
  const therapyType = therapyTypeObject?.patient?.therapyType

  const icon = () => {
    if (therapyType) {
      switch (therapyType) {
        case 'single':
          return (
            <>
              <div className={styles.typeIcon}>
                <UserIcon className={styles.icon}/>
              </div>
              <p>Individuale</p>
            </>
          )
        case 'couple':
          return (
            <>
              <div className={styles.typeIcon}>
                <UsersIcon className={styles.icon}/>
              </div>
              <p>Di coppia</p>
            </>
          )
        case 'underage':
          return (
            <>
              <div className={styles.typeIcon}>
                <img
                  src={parentIcon}
                  alt='parent icon'
                  className={styles.icon}
                />
              </div>
              <p>Minori</p>
            </>
          )
      }
    }
  }

  return (
    <div
      className={`${styles.patientChatCard} ${
        isSelected ? styles.isChatSelected : ""
      }`}
    >
      <div className={styles.patientChatCardLeft}>
        {lastAppointment && (
          <div className={styles.patientChatCardCalendar}>
            <CalendarDaysIcon className={styles.icon} />
            <span>
              {dateToShow(new Date(lastAppointment.startTime), "card")}
            </span>
          </div>
        )}
        <div className={styles.patientChatCardName}>
          <div className={styles.patientCardNameWrapper}>
            {therapyType && (
              <div className={styles.wrapper}>
                {icon()}
              </div>
            )}
          </div>
          <div className={styles.name}>{patientName}</div>
          {lastMessage && (
            <div className={styles.message}>{lastMessage.content.replaceAll('&moved', '')}</div>
          )}
        </div>
      </div>
      <div className={styles.patientChatCardRight}>
        {unreadMessages > 0 && (
          <div className={styles.patientChatCardNewMessage}>
            {unreadMessages}
          </div>
        )}
        {lastMessage && (
          <div className={styles.patientChatCardDate}>
            {dateToShow(new Date(lastMessage.when), "card")}
          </div>
        )}
      </div>
    </div>
  );
};
