import { FunctionComponent } from "react";
import Accordion from "../../components/Accordion";
import Text from "../../../atoms/Text";
import ContentSection from "./FaqSection.json";
import styles from "./FaqSection.module.scss";

interface PropsFaqSection {
	faqSelected?:string
}
export const FaqSection: FunctionComponent<PropsFaqSection> = (props) => {	
	return (
		<section className={styles.faqSection}>
			{ContentSection.Faqs.map((faq, index) => {
				return (
					<Accordion id={faq.label} accordionSelected={props.faqSelected} key={index} title={faq.label} titleType="h6">
						{faq.questionsAndAnswers.map((questionAndAnswer, index) => {
							return (
								<Accordion
									key={index}
									titleType="span"
									title={questionAndAnswer.question}
									children={<Text type="p" children={questionAndAnswer.answer} />}
								/>
							);
						})}
					</Accordion>
				);
			})}
		</section>
	);
};