import { FC, Fragment, useEffect, useRef, useState } from "react";
import styles from "./SupervisedPatientRegister.module.scss";
import Input from "components/atoms/Input";
import GenericForm from "components/molecules/GenericForm";
import Button from "components/atoms/Button";
import { Progress, Input as AntInput } from "antd";
import { trpc } from "utils/trpc";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAppDispatch } from "customHooks/reduxHooks";
import { setInfo } from "store/slices/infoSlice";
import { analyticsService } from "../../utils/analyticsService";
import { raiseException } from "../../utils/raiseException";
import trimString from "../../utils/trimString";

interface SupervisedPatientRegisterProps {
  className?: string;
}

export const SupervisedPatientRegister: FC<SupervisedPatientRegisterProps> = ({
  className,
}) => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [patientFormData, setPatientFomrData] = useState<{
    email: string;
    givenName: string;
    familyName: string;
    surveyAnswer: string;
  }>({
    email: "",
    givenName: "",
    familyName: "",
    surveyAnswer: "",
  });

  const formRef = useRef<HTMLDivElement>(null);
  const firstPageSubmitRef = useRef<HTMLButtonElement>(null);
  const secondPageSubmitRef = useRef<HTMLButtonElement>(null);

  const registerSupervisedPatient =
    trpc.authService.auth.registerSupervisedPatient.useMutation();

  useEffect(() => {
    analyticsService({
      'event': 'virtualPageview',
      'pageUrl': '/registrazione-supervisione/ncu8Aj_d8n',
      'pageTitle': 'Supervised Patient Register'
    });
  }, []);

  const formikFirstPage = useFormik({
    initialValues: {
      givenName: "",
      familyName: "",
      email: "",
    },
    validationSchema: yup.object().shape({
      givenName: yup.string().required("Il nome è un campo obbligatorio"),
      familyName: yup.string().required("Il cognome è un campo obbligatorio"),
      email: yup
        .string()
        .trim()
        .matches(
          /^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i,
          "L'email inserita ha un formato non valido"
        )
        .required("L'email è un campo obbligatorio"),
    }),
    onSubmit: (values, actions) => {
      setPatientFomrData({
        ...patientFormData,
        givenName: values.givenName,
        familyName: values.familyName,
        email: values.email,
      });
      setPage(page + 1);
      formRef.current?.scrollIntoView();
    },
  });

  const formikSecondPage = useFormik({
    initialValues: {
      answer: "",
    },
    validationSchema: yup.object().shape({
      answer: yup
        .string()
        .max(2048, "La risposta non può essere più lunga di 2048 caratteri")
        .required("La risposta alla domanda unica è obbligatoria"),
    }),
    onSubmit: async (values, actions) => {
      try {
        await registerSupervisedPatient.mutateAsync({
          email: trimString(patientFormData.email),
          given_name: trimString(patientFormData.givenName),
          family_name: trimString(patientFormData.familyName),
          surveyAnswer: values.answer,
        });
        setPage(page + 1);
        formRef.current?.scrollIntoView();
      } catch (e: any) {
        raiseException(e);
        console.error(e);
        dispatch(
          setInfo({
            variant: "error",
            text: "Errore nel servizio di registrazione",
          })
        );
      }
    },
  });

  const pages = [
    [
      <form className={styles.formPage} onSubmit={formikFirstPage.handleSubmit}>
        <Input
          label="1. Inserisci il tuo nome"
          placeholder="Nome"
          name="givenName"
          value={formikFirstPage.values.givenName}
          onChange={formikFirstPage.handleChange}
          error={
            (formikFirstPage.errors.givenName &&
              formikFirstPage.touched.givenName &&
              formikFirstPage.errors.givenName) as string
          }
        />
        <Input
          label="2. Inserisci il tuo cognome"
          placeholder="Cognome"
          name="familyName"
          value={formikFirstPage.values.familyName}
          onChange={formikFirstPage.handleChange}
          error={
            (formikFirstPage.errors.familyName &&
              formikFirstPage.touched.familyName &&
              formikFirstPage.errors.familyName) as string
          }
        />
        <Input
          label="3. Inserisci il tuo indirizzo email"
          placeholder="Email"
          name="email"
          type="email"
          value={formikFirstPage.values.email}
          onChange={formikFirstPage.handleChange}
          error={
            (formikFirstPage.errors.email &&
              formikFirstPage.touched.email &&
              formikFirstPage.errors.email) as string
          }
        />
        <button
          ref={firstPageSubmitRef}
          className={styles.hiddenButton}
          type="submit"
        ></button>
      </form>,
    ],
    [
      <form
        className={styles.formPage}
        onSubmit={formikSecondPage.handleSubmit}
      >
        <AntInput.TextArea
          rows={8}
          maxLength={2048}
          name="answer"
          value={formikSecondPage.values.answer}
          onChange={formikSecondPage.handleChange}
          className={
            formikSecondPage.errors.answer &&
            formikSecondPage.touched.answer &&
            formikSecondPage.errors.answer
              ? styles.textAreaError
              : ""
          }
        />
        {formikSecondPage.errors.answer &&
          formikSecondPage.touched.answer &&
          formikSecondPage.errors.answer && (
            <span className={styles.errorLabel}>
              {formikSecondPage.errors.answer}
            </span>
          )}
        <button
          ref={secondPageSubmitRef}
          className={styles.hiddenButton}
          type="submit"
        ></button>
      </form>,
    ],
  ];

  return (
    <div className={`${styles.SupervisedPatientRegister} ${className ?? ""}`}>
      <GenericForm
        ref={formRef}
        className={styles.form}
        answer={pages[page - 1]}
        title={
          page === 1 ? (
            "Ti diamo il benvenuto nel processo di registrazione per la supervisione di Mymentis"
          ) : page === 2 ? (
            <div className={styles.question}>
              <ul>
                <li>
                  Qual è il tuo livello di formazione? (studi, specializzazione)
                </li>
                <li>
                  Quali sono le motivazioni che ti spingono a richiedere la
                  supervisione di un esperto?
                </li>
                <li>Con che frequenza vorresti fare supervisione?</li>
              </ul>
            </div>
          ) : (
            ""
          )
        }
        description={
          page === 1 ? (
            "Compila tutti gli step seguenti con i tuoi dati e ti invieremo i dati per il login sulla tua email"
          ) : page === 2 ? (
            ""
          ) : (
            <div>
              Grazie per aver completato il processo di registrazione, controlla
              la tua casella email nelle prossime 48 ore, riceverai una mail da
              parte nostra con le tue credenziali per il primo accesso. <br />
              Se non le dovessi ricevere o hai bisogno di supporto puoi
              scriverci a: support@mymentis.it
            </div>
          )
        }
      />
      <div className={styles.btnWrapper}>
        {page < 3 && (
          <div className={styles.outsideButtonWrapper}>
            <div className={styles.buttonWrapper}>
              {page > 1 && (
                <Button
                  className={styles.btn}
                  onClick={() => {
                    setPage(page - 1);
                  }}
                  variant="secondary"
                >
                  Indietro
                </Button>
              )}
              <Button
                className={styles.btn}
                onClick={async () => {
                  if (page === 1) {
                    const result = await formikFirstPage.validateForm();
                    if (Object.keys(result).length !== 0) {
                      formRef.current?.scrollIntoView();
                    }
                    firstPageSubmitRef.current?.click();
                  }
                  if (page === 2) {
                    const result = await formikSecondPage.validateForm();
                    if (Object.keys(result).length !== 0) {
                      formRef.current?.scrollIntoView();
                    }
                    secondPageSubmitRef.current?.click();
                  }
                }}
              >
                Avanti
              </Button>
            </div>
            <div className={styles.pageNumber}>
              Pagina {page} di {3}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
