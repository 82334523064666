import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { envs } from "./envs";

export const getCPS = (interventionAreas: string[]) => {
  const interventionAreasLabels: string[] = [];
  interventionAreas.forEach((interventionArea) => {
    interventionAreasLabels.push(...cps[interventionArea]);
  });
  return interventionAreasLabels;
};

const cps = {
  CP1: ["Stress", "ansia", "difficoltà sul lavoro"],
  CP2: ["Disturbo ossessivo compulsivo"],
  CP3: ["Trauma", "lutto", "violenza", "bullismo"],
  CP4: ["Disturbi del comportamento alimentare"],
  CP5: ["Depressione"],
  CP6: ["Dipendenze"],
  CP7: ["Disturbi di personalità"],
  CP8: ["Psichiatria"],
  CP9: ["Crescita", "coaching", "comunicazione", "autostima"],
  CP10: ["Problematiche familiari"],
  CP11: ["Parent training", "sostegno alla genitorialità"],
  CP12: ["Difficoltà della sfera sessuale", "Identità sessuale"],
};

interface GenerateProformaPdfInput {
  transactions: { amount: number }[];
  // totalAppointmentsCost: number;
  numberOfAppointments: number;
  totalPeriodPayment: number;
  vatNumberType: "forfettaria" | "ordinaria";
  month: number;
  year: number;
  name: string;
  surname: string;
  vatNumber: string;
  taxId: string;
  iban: string;
  address: string;
}

const getMonth = (month: number) => {
  switch (month) {
    case 0:
      return "Gennaio";
    case 1:
      return "Febbraio";
    case 2:
      return "Marzo";
    case 3:
      return "Aprile";
    case 4:
      return "Maggio";
    case 5:
      return "Giugno";
    case 6:
      return "Luglio";
    case 7:
      return "Agosto";
    case 8:
      return "Settembre";
    case 9:
      return "Ottobre";
    case 10:
      return "Novembre";
    case 11:
      return "Dicembre";
    default:
      return "Mese";
  }
};

export const generateProformaPdf = ({
  transactions,
  // totalAppointmentsCost,
  totalPeriodPayment,
  vatNumberType,
  month,
  year,
  name,
  surname,
  vatNumber,
  taxId,
  iban,
  address,
}: GenerateProformaPdfInput) => {
  const doc = new jsPDF();

  doc.setFontSize(11);

  const img = new Image();
  img.src = "/assets/images/landingPageImgs/logo.png";
  doc.addImage(img, "png", 15, 15, 45, 15);

  doc.text(`Nome: ${name ?? ""} ${surname ?? ""}`, 15, 44);
  doc.text(`Indirizzo: ${address ?? ""}`, 15, 51);
  doc.text(`Partita IVA: ${vatNumber ?? ""}`, 15, 58);
  doc.text(`Codice fiscale: ${taxId ?? ""}`, 15, 65);
  doc.text("Banca:", 15, 72);
  doc.text(`IBAN: ${iban ?? ""}`, 15, 79);

  doc.text("MYMENTIS S.R.L.", 127, 44);
  doc.text("Via Montegrappa 43", 127, 51);
  doc.text("17019 Varazze (SV)", 127, 58);
  doc.text("Codice fiscale/Partita Iva 01886110095", 127, 65);
  doc.text("Cod SDI M5UXCR1", 127, 72);

  const occurences: { [key: string]: number } = {};

  transactions.forEach((transaction) => {
    if (occurences[transaction.amount]) {
      occurences[transaction.amount] += 1;
    } else {
      occurences[transaction.amount] = 1;
    }
  });

  const paymentsRows = Object.entries(occurences).map(
    ([unitCostKey, numberOfAppointments]) => {
      const unitCost = Number(unitCostKey);
      const unitCostWithoutEnpap = Math.ceil((unitCost / 1.02) * 100) / 100;
      const unitEnpap = unitCost - unitCostWithoutEnpap;
      const unitWithHoldingTax =
        Math.floor(unitCostWithoutEnpap * 0.2 * 100) / 100;

      let finalUnitCost: number;
      if (vatNumberType === "ordinaria") {
        finalUnitCost = unitCost - unitWithHoldingTax;
      } else {
        finalUnitCost = unitCost;
      }

      const total = finalUnitCost * numberOfAppointments;
      return {
        Descrizione: `Sedute psicologhe effettuate nel mese di ${getMonth(
          month
        )} ${year}`,
        "Lordo singola seduta": `${unitCostWithoutEnpap
          .toFixed(2)
          .toString()} €`,
        "Ritenuta d'acconto 20% singola seduta":
          vatNumberType === "ordinaria"
            ? `${unitWithHoldingTax.toFixed(2).toString()} €`
            : "",
        "Enpap 2% singola seduta": `${unitEnpap.toFixed(2).toString()} €`,
        "Importo netto singola seduta": `${finalUnitCost
          .toFixed(2)
          .toString()} €`,
        "Numero sedute": numberOfAppointments.toString(),
        "Totale da pagare": `${total.toFixed(2).toString()} €`,
      };
    }
  );

  const totalWithoutEnpap = paymentsRows.reduce(
    (acc, value) =>
      acc +
      Number(value["Lordo singola seduta"].replace(" €", "")) *
        Number(value["Numero sedute"].replace(" €", "")),
    0
  );

  const totalEnpap = paymentsRows.reduce(
    (acc, value) =>
      acc +
      Number(value["Enpap 2% singola seduta"].replace(" €", "")) *
        Number(value["Numero sedute"].replace(" €", "")),
    0
  );

  const totalForOrdinaria =
    paymentsRows.reduce(
      (acc, value) =>
        acc +
        (Number(value["Lordo singola seduta"].replace(" €", "")) +
          Number(value["Enpap 2% singola seduta"].replace(" €", ""))) *
          Number(value["Numero sedute"].replace(" €", "")),
      0
    );

  const TotalWithHoldingTax = paymentsRows.reduce(
    (acc, value) =>
      acc +
      Number(value["Ritenuta d'acconto 20% singola seduta"].replace(" €", "")) *
        Number(value["Numero sedute"].replace(" €", "")),
    0
  );

  const tableContent: {
    Descrizione: string;
    "Lordo singola seduta": string;
    "Ritenuta d'acconto 20% singola seduta": string;
    "Enpap 2% singola seduta": string;
    "Importo netto singola seduta": string;
    "Numero sedute": string;
    "Totale da pagare": string;
  }[] = [
    ...paymentsRows,
    {
      Descrizione:
        vatNumberType === "forfettaria"
          ? "Operazione senza applicazione dell'IVA ai sensi dell'articolo 1, comma 58, Legge n. 190/2014, regime forfettario. Si richiede la non applicazione della ritenuta alla fonte a titolo d'acconto come previsto dall'art. 1, comma 67, Legge n. 190/2014."
          : "Operazione esente iva art.10 c.1 n.18 dpr 633/72 e successive modificazioni",
      "Lordo singola seduta": "",
      "Ritenuta d'acconto 20% singola seduta": "",
      "Enpap 2% singola seduta": "",
      "Importo netto singola seduta": "",
      "Numero sedute": "",
      "Totale da pagare": "",
    },
    {
      Descrizione: "Totale prestazioni",
      "Lordo singola seduta": "",
      "Ritenuta d'acconto 20% singola seduta": "",
      "Enpap 2% singola seduta": "",
      "Importo netto singola seduta": "",
      "Numero sedute": "",
      "Totale da pagare": `${totalWithoutEnpap.toFixed(2).toString()} €`,
    },
    {
      Descrizione: "Totale enpap",
      "Lordo singola seduta": "",
      "Ritenuta d'acconto 20% singola seduta": "",
      "Enpap 2% singola seduta": "",
      "Importo netto singola seduta": "",
      "Numero sedute": "",
      "Totale da pagare": `${totalEnpap.toFixed(2).toString()} €`,
    },
    totalWithoutEnpap < 77.47 ?
      {
        Descrizione: "",
        "Lordo singola seduta": "",
        "Ritenuta d'acconto 20% singola seduta": "",
        "Enpap 2% singola seduta": "",
        "Importo netto singola seduta": "",
        "Numero sedute": "",
        "Totale da pagare": "",
      } :
      {
        Descrizione: "Si ricorda che l'onere relativo all'imposta di bollo di 2€ è a carico del professionista",
        "Lordo singola seduta": "",
        "Ritenuta d'acconto 20% singola seduta": "",
        "Enpap 2% singola seduta": "",
        "Importo netto singola seduta": "",
        "Numero sedute": "",
        "Totale da pagare": "",
      },
    {
      Descrizione: "Totale documento",
      "Lordo singola seduta": "",
      "Ritenuta d'acconto 20% singola seduta": "",
      "Enpap 2% singola seduta": "",
      "Importo netto singola seduta": "",
      "Numero sedute": "",
      "Totale da pagare":
        vatNumberType === "ordinaria"
          ? `${totalForOrdinaria.toFixed(2).toString()} €`
          : vatNumberType === "forfettaria"
          ? `${totalPeriodPayment.toFixed(2).toString()} €`
          : "",
    },
    vatNumberType === "ordinaria"
      ? {
          Descrizione: "Ritenuta d'acconto sul totale prestazioni",
          "Lordo singola seduta": "",
          "Ritenuta d'acconto 20% singola seduta": "",
          "Enpap 2% singola seduta": "",
          "Importo netto singola seduta": "",
          "Numero sedute": "",
          "Totale da pagare": `${TotalWithHoldingTax.toFixed(2).toString()} €`,
        }
      : {
          Descrizione: "",
          "Lordo singola seduta": "",
          "Ritenuta d'acconto 20% singola seduta": "",
          "Enpap 2% singola seduta": "",
          "Importo netto singola seduta": "",
          "Numero sedute": "",
          "Totale da pagare": "",
        },
    {
      Descrizione: "",
      "Lordo singola seduta": "",
      "Ritenuta d'acconto 20% singola seduta": "",
      "Enpap 2% singola seduta": "",
      "Importo netto singola seduta": "",
      "Numero sedute": "",
      "Totale da pagare": "",
    },
    {
      Descrizione: "Totale da pagare",
      "Lordo singola seduta": "",
      "Ritenuta d'acconto 20% singola seduta": "",
      "Enpap 2% singola seduta": "",
      "Importo netto singola seduta": "",
      "Numero sedute": "",
      "Totale da pagare": `${totalPeriodPayment.toFixed(2).toString()} €`,
    },
    {
      Descrizione: "Modalità di pagamento: bonifico bancario",
      "Lordo singola seduta": "",
      "Ritenuta d'acconto 20% singola seduta": "",
      "Enpap 2% singola seduta": "",
      "Importo netto singola seduta": "",
      "Numero sedute": "",
      "Totale da pagare": "",
    },
    {
      Descrizione: "Banca",
      "Lordo singola seduta": "",
      "Ritenuta d'acconto 20% singola seduta": "",
      "Enpap 2% singola seduta": "",
      "Importo netto singola seduta": "",
      "Numero sedute": "",
      "Totale da pagare": "",
    },
    {
      Descrizione: "IBAN",
      "Lordo singola seduta": "",
      "Ritenuta d'acconto 20% singola seduta": "",
      "Enpap 2% singola seduta": "",
      "Importo netto singola seduta": "",
      "Numero sedute": "",
      "Totale da pagare": "",
    },
  ];

  autoTable(doc, {
    margin: {
      top: 90,
    },
    columnStyles: {
      6: {cellWidth: 20}
    },
    headStyles: {
      fillColor: "#7595e0",
      textColor: "black",
    },
    head: [
      vatNumberType === "ordinaria"
        ? [
            "Descrizione",
            "Lordo singola seduta",
            "Ritenuta d'acconto 20% singola seduta",
            "Enpap 2% singola seduta",
            "Importo netto singola seduta",
            "Numero sedute",
            "Totale da pagare",
          ]
        : [
            "Descrizione",
            "Lordo singola seduta",
            "Enpap 2% singola seduta",
            "Importo netto singola seduta",
            "Numero sedute",
            "Totale da pagare",
          ],
    ],
    body: tableContent.map((content) => {
      if (vatNumberType === "ordinaria") {
        return [
          content.Descrizione,
          content["Lordo singola seduta"],
          content["Ritenuta d'acconto 20% singola seduta"],
          content["Enpap 2% singola seduta"],
          content["Importo netto singola seduta"],
          content["Numero sedute"],
          content["Totale da pagare"],
        ];
      } else {
        return [
          content.Descrizione,
          content["Lordo singola seduta"],
          content["Enpap 2% singola seduta"],
          content["Importo netto singola seduta"],
          content["Numero sedute"],
          content["Totale da pagare"],
        ];
      }
    }),
    didParseCell: function (data) {
      if (data.cell.raw === "Si ricorda che l'onere relativo all'imposta di bollo di 2€ è a carico del professionista") {
        data.cell.styles.fontStyle = 'bold';
      }
    },
  });

  doc.save("Proforma.pdf");
};

export const sleep = (millis: number) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(null), millis);
  });

export const isDevEnvironment = () => envs.REACT_APP_ENVIRONMENT !== "Prod";

export const time = {
  ONE_DAY_IN_MILLIS: 60 * 60 * 24 * 1000,
  ONE_HOUR_IN_MILLIS: 60 * 60 * 1000,
  ONE_MINUTE_IN_MILLIS: 60 * 1000,
  TEN_MINUTES_IN_MILLIS: 10 * 60 * 1000,
};

function isLeapYear(year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

export function isValidDate(day, month, year) {
  const daysInMonth = [
    31,
    isLeapYear(year) ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ];

  if (year < 1 || year > 9999 || month < 1 || month > 12) {
    return false; // Anno o mese non validi
  }

  if (day < 1 || day > daysInMonth[month - 1]) {
    return false; // Giorno non valido per il mese specificato
  }

  return true; // Data valida
}
