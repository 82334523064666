import { FunctionComponent, useEffect, useRef } from "react";
import Card from "../../components/Card";
import ContentSection from "./EmergencyNumbers.json";
import styles from "./EmergencyNumbers.module.scss";
import { useLocation } from "react-router-dom";
export const EmergencyNumbers: FunctionComponent = () => {
  
  const sectionRef = useRef<any>(null);
  const location = useLocation();
  const { hash } = location;
  useEffect(() => {
    if (hash && sectionRef.current) {
      sectionRef.current.scrollIntoView({ block: "end" });
    }
  }, [hash]);
  return (
      <section ref={sectionRef} className={styles.emergencyNumbers}>
        <Card
          titleType="h4"
          title={ContentSection.title}
          paragraphs={ContentSection.paragraphs}
          textRight
          src={ContentSection.image}
        />
        <div className={styles.cards}>
          {ContentSection.cards.map((card, index) => {
            const numbers = card.numbers;
            return (
              <Card
                key={index}
                isMicro
                listPoints={[{ points: numbers }]}
                src={ContentSection.icon}
                textRight
              />
            );
          })}
        </div>
      </section>
  );
};
