export const dateToShow = (date: Date, context: "chat" | "card") => {
  const today = new Date();

  if (date.toDateString() === today.toDateString()) {
    return "Oggi";
  }
  if (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate() - 1
  ) {
    return "Ieri";
  }
  if (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    today.getDate() - date.getDate() <= 7
  ) {
    return `${today.getDate() - date.getDate()} GIORNI FA`;
  }

  if (date.getTime() < today.getTime() - 1000 * 60 * 60 * 24 * 30) {
    return "PIÙ DI UN MESE FA";
  }
  if (date.getTime() < today.getTime() - 1000 * 60 * 60 * 24 * 365) {
    return "PIÙ DI UN ANNO FA";
  }

  if (context === "chat") {
    return `${date.getDate()} ${date.toLocaleString("default", {
      month: "long",
    })} ${date.getFullYear()}`;
  }
  if (context === "card") {
    return `${date.toLocaleString("default", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })} `;
  }
};
