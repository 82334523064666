import styles from "./Privacy.module.scss";
import { analyticsService } from "../../utils/analyticsService";
import { useEffect } from "react";

export const SurveyPrivacy = ({ className }) => {

  useEffect(() => {
    analyticsService({'event': 'virtualPageview', 'pageUrl': '/privacy-paziente', 'pageTitle': 'Survey Privacy'});
  }, []);

  return (
    <div className={`${styles.privacy} ${className}`}>
      <h2>INFORMATIVA PRIVACY</h2>
      <div className={styles.subtitle}>
        La presente informativa privacy ha lo scopo di fornire tutte le
        informazioni sul trattamento dei dati personali effettuato da{" "}
        <span>Mymentis S.r.l.</span> e il <span>Professionista</span> quando
        l’Utente compila il questionario Mymentis (come di seguito meglio
        indicato).
      </div>
      <div>
        I soggetti contitolari <span>Mymentis S.r.l.</span>, con sede legale in
        Varazze (SV), via Montegrappa n. 43, 17019, C.F. e P. IVA 01886110095
        (di seguito
        <span>“Mymentis”</span>) e il <span>Professionista</span> scelto
        dall’Utente (di seguito il <span>“Professionista”</span> o,
        congiuntamente a Mymentis, i <span>“Contitolari”</span>), in qualità di{" "}
        <span>Contitolari del trattamento dei dati personali</span> degli Utenti
        (di seguito, gli <span>“Utenti”</span>) che compilano il questionario
        per scegliere il Professionista con cui iniziare un percorso
        psicoterapeutico e/o di supporto psicologico e psicoterapeutico a
        distanza (di seguito, il <span>“Questionario”</span>) forniscono qui di
        seguito l’informativa privacy ai sensi dell’art. 13 del Regolamento UE
        2016/679 del 27 aprile 2016 (di seguito, <span>“Regolamento”</span>, o
        la <span>“Normativa Applicabile”</span>).
      </div>
      <br />
      <div>
        I Contitolari tengono nella massima considerazione il diritto alla
        privacy ed alla protezione dei dati personali degli Utenti. Per ogni
        informazione in relazione alla presente informativa privacy, gli Utenti
        possono <span>contattare i Contitolari</span> in qualsiasi momento,
        utilizzando i seguenti punti di contatto:
      </div>
      <ul>
        <li>
          Inviando una raccomandata a/r presso la sede legale di Mymentis:
          Varazze (SV), via Montegrappa n. 43;
        </li>
        <li>
          Inviando un messaggio di posta elettronica all’indirizzo:{" "}
          <a href="mailto:privacy@mymentis.it"> privacy@mymentis.it</a>
        </li>
      </ul>
      <br />
      <div className={styles.articlesWrapper}>
        <div className={styles.article}>
          <div className={styles.articleTitle}>1. FINALITÀ DEL TRATTAMENTO</div>
          <div className={styles.articleDescription}>
            <div>
              Gli Utenti, tramite la compilazione del Questionario, possono
              indicare ai Contitolari le proprie esigenze e le proprie
              preferenze in merito al percorso che intendono intraprendere con
              l’assistenza del Professionista scelto tramite la Piattaforma. Al
              riguardo, si precisa che la presente informativa si applica
              esclusivamente alle attività di trattamento poste in essere dai
              Contitolari nel perseguimento delle finalità di seguito indicate e
              sono escluse quelle attività di trattamento effettuate nel
              perseguimento di finalità che Mymentis o il Professionista
              determinano autonomamente, le quali saranno disciplinate dalle
              rispettive informative privacy che vengono messe a disposizione
              dei soggetti interessati tramite apposite sezioni della
              Piattaforma.
            </div>
            <br />
            <div>
              In particolare, i dati personali degli Utenti saranno lecitamente
              trattati per le seguenti finalità di trattamento:
            </div>
            <ol className={styles.listLetter}>
              <li>
                <span className={styles.underline}>
                  evadere la richiesta dell’Utente tramite la compilazione del
                  questionario:
                </span>{" "}
                i dati personali degli Utenti sono raccolti e trattati dai
                Contitolari, nel rispetto delle Condizioni d’Uso della
                Piattaforma, al solo fine di evadere la loro richiesta relativa
                alla individuazione/modifica del Professionista più adatto in
                relazione alle esigenze indicate dall’Utente e con il quale
                iniziare un percorso terapeutico. I dati dell’Utente raccolti
                dai Contitolari a tale fine includono: nome, cognome, età,
                sesso, indirizzo e- mail, numero di cellulare. Inoltre,
                l’Utente, potrà inserire dati personali appartenenti a categorie
                particolari, come definiti dall’art. 9 del Regolamento e, nello
                specifico, dati relativi alla salute, la vita sessuale o
                l’orientamento sessuale dell’Utente. Nessun altro trattamento
                verrà effettuato dai Contitolari in relazione ai dati personali
                degli Utenti. Fermo restando quanto previsto altrove in questa
                informativa privacy, in nessun caso i Contitolari renderanno
                accessibili agli altri Utenti e/o a terzi i dati personali degli
                Utenti.
              </li>
              <li>
                <span className={styles.underline}>
                  finalità amministrativo-contabili
                </span>
                , ovvero per svolgere attività di natura organizzativa,
                amministrativa, finanziaria e contabile, quali attività
                organizzative interne ed attività funzionali all’adempimento di
                obblighi contrattuali e precontrattuali;
              </li>
              <li>
                <span className={styles.underline}>obblighi di legge</span>,
                ovvero per adempiere ad obblighi previsti dalla legge, da
                un’autorità, da un regolamento o dalla normativa europea.
              </li>
            </ol>
            <div>
              Il conferimento dei dati personali per le finalità di trattamento
              sopra indicate è facoltativo ma necessario, poiché il mancato
              conferimento degli stessi comporterà l’impossibilità per l’Utente
              di compilare il Questionario e individuare il Professionista con
              cui iniziare un percorso terapeutico. I dati personali che sono
              necessari per il perseguimento delle finalità di trattamento
              descritte al presente paragrafo 3 sono indicati con un asterisco
              all’interno del Questionario.
            </div>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>4. BASE GIURIDICA</div>
          <div className={styles.articleDescription}>
            <div>
              <span>
                Evasione della richiesta dell’Utente tramite la compilazione del
                questionario
              </span>{" "}
              (così come descritto al par. 1, lett. a)): la base giuridica
              consiste nell’art. 6, comma 1, lett. b) del Regolamento, in quanto
              il trattamento è necessario all’esecuzione di un contratto e/o
              all’esecuzione di misure precontrattuali adottate su richiesta
              dell’Utente.
            </div>
            <br />
            <div>
              Con riguardo al trattamento dei dati appartenenti a categorie
              particolari, come i dati relativi alla salute, alla vita sessuale
              o all’orientamento sessuale, la base giuridica del trattamento
              consiste nell’art. 9, comma 2, lett. h) e art. 9, comma 3, del
              Regolamento, ovvero il trattamento è effettuato conformemente al
              con contratto con un professionista soggetto al segreto
              professionale conformemente al diritto dell’Unione o degli Stati
              membri.
            </div>
            <br />
            <div>
              <span className={styles.underline}>
                Finalità amministrativo-contabili
              </span>{" "}
              (così come descritto al par. 1, lett. b)): la base giuridica
              consiste nell’art. 6, comma 1, lett. b) del Regolamento, in quanto
              il trattamento è necessario all’esecuzione di un contratto e/o
              all’esecuzione di misure precontrattuali adottate su richiesta
              dell’Utente.
            </div>
            <br />
            <div>
              <span className={styles.underline}>Obblighi di legge</span> (così
              come descritto dal precedente par. 1, lett. c)): la base giuridica
              consiste nell’art. 6, comma 1, lett. c) del Regolamento, in quanto
              il trattamento è necessario per adempiere un obbligo legale al
              quale sono soggetti i Contitolari del Trattamento.
            </div>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>
            5. MODALITÀ DI TRATTAMENTO E TEMPI DI CONSERVAZIONE DEI DATI
          </div>
          <div className={styles.articleDescription}>
            I Contitolari tratteranno i dati personali degli Utenti mediante
            strumenti manuali ed informatici, con logiche strettamente correlate
            alle finalità stesse e, comunque, in modo da garantire la sicurezza
            e la riservatezza dei dati stessi. I dati personali che i soggetti
            interessati inseriscono nel Questionario Utenti saranno conservati
            per un periodo corrispondente al periodo in cui l’utente mantiene
            attivo il proprio account sulla piattaforma Mymentis o, ove occorra,
            secondo quanto necessario per la tutela in sede civilistica degli
            interessi dei Contitolari e degli Utenti.
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>
            6. AMBITO DI COMUNICAZIONE E DIFFUSIONE DEI DATI
          </div>
          <div className={styles.articleDescription}>
            <div>
              Potranno venire a conoscenza dei dati personali degli Utenti i
              dipendenti e/o collaboratori dei Contitolari incaricati di gestire
              le richieste degli Utenti. Tali soggetti, che sono stati istruiti
              in tal senso dai Contitolari ai sensi dell’art. 29 del
              Regolamento, tratteranno i dati degli Utenti esclusivamente per le
              finalità indicate nella presente informativa e nel rispetto delle
              previsioni della Normativa Applicabile.
            </div>
            <br />
            <div>
              Potranno inoltre venire a conoscenza dati personali degli Utenti i
              soggetti terzi che potranno trattare dati personali per conto dei
              Contitolari in qualità di{" "}
              <span>Responsabili del Trattamento</span> ai sensi dell’art. 28
              del Regolamento, quali, a titolo esemplificativo, fornitori di
              servizi informatici e logistici, fornitori di servizi in
              outsourcing o cloud computing, professionisti e consulenti.
            </div>
            <br />
            <div>
              L’Utente ha il diritto di ottenere una lista degli eventuali
              responsabili del trattamento nominati dai Contitolari, facendone
              richiesta ai Contitolari con le modalità indicate al successivo
              paragrafo 7.
            </div>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>
            7. DIRITTI DEGLI INTERESSATI
          </div>
          <div className={styles.articleDescription}>
            <div>
              Potranno venire a conoscenza dei dati personali degli Utenti i
              L’Utente potrà esercitare i diritti garantiti dalla Normativa
              Applicabile in qualsiasi momento, utilizzando i seguenti punti di
              contatto:
            </div>
            <ul>
              <li>
                Inviando una raccomandata a/r presso la sede legale di Mymentis:
                Varazze (SV), via Montegrappa n. 43;
              </li>
              <li>
                Inviando un messaggio di posta elettronica all’indirizzo:{" "}
                <a href="mailto:privacy@mymentis.it">privacy@mymentis.it</a>
              </li>
            </ul>
            <div>
              Ai sensi della Normativa Applicabile, l’Utente ha il diritto di
              ottenere l’indicazione (i) dell’origine dei dati personali; (ii)
              delle finalità e modalità del trattamento; (iii) della logica
              applicata in caso di trattamento effettuato con l’ausilio di
              strumenti elettronici; (iv) degli estremi identificativi del
              titolare e dei responsabili; (v) dei soggetti o delle categorie di
              soggetti ai quali i dati personali possono essere comunicati o che
              possono venirne a conoscenza in qualità di responsabili o
              incaricati. Inoltre, l’Utente ha il diritto di ottenere:
            </div>
            <ol className={styles.listLetter}>
              <li>
                l’<span>accesso</span>, l’<span>aggiornamento</span>, la{" "}
                <span>rettificazione</span> ovvero, quando vi hanno interesse,
                l’<span>integrazione</span> dei dati;
              </li>
              <li>
                la <span>cancellazione</span>, la{" "}
                <span>trasformazione in forma anonima</span> o la{" "}
                <span>limitazione</span> dei dati trattati in violazione di
                legge, compresi quelli di cui non è necessaria la conservazione
                in relazione agli scopi per i quali i dati sono stati raccolti o
                successivamente trattati;
              </li>
              <li>
                l’attestazione che le operazioni di cui alle lettere a) e b)
                sono state portate a conoscenza, anche per quanto riguarda il
                loro contenuto, di coloro ai quali i dati sono stati comunicati
                o diffusi, eccettuato il caso in cui tale adempimento si rivela
                impossibile o comporta un impiego di mezzi manifestamente
                sproporzionato rispetto al diritto tutelato.
              </li>
            </ol>
            <div>Nonché:</div>
            <ol className={styles.listLetter}>
              <li>
                il diritto di <span>revocare</span> il consenso in qualsiasi
                momento, qualora il trattamento si basi sul suo consenso;
              </li>
              <li>
                (qualora applicabile) il diritto alla{" "}
                <span>portabilità dei dati</span> (diritto di ricevere tutti i
                dati personali che lo riguardano in un formato strutturato, di
                uso comune e leggibile da dispositivo automatico);
              </li>
              <li>
                il <span>diritto di opporsi</span>:
                <ol className={styles.listRomanNumber}>
                  <li>
                    in tutto o in parte, per motivi legittimi al trattamento dei
                    dati personali che lo riguarda, ancorché pertinenti allo
                    scopo della raccolta;
                  </li>
                  <li>
                    in tutto o in parte, al trattamento di dati personali che lo
                    riguarda a fini di invio di materiale pubblicitario o di
                    vendita diretta o per il compimento di ricerche di mercato o
                    di comunicazione commerciale;
                  </li>
                </ol>
              </li>
              <li>
                qualora ritenesse che il trattamento che lo riguarda violi il
                Regolamento, il diritto di{" "}
                <span>proporre reclamo a un’Autorità di controllo</span> (nello
                Stato membro in cui risiede abitualmente, in quello in cui
                lavora oppure in quello in cui si è verificata la presunta
                violazione). L’Autorità di controllo italiana è il{" "}
                <span>Garante per la protezione dei dati personali</span>, con
                sede in Piazza Venezia, n. 11, 00187 – Roma (RM) (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open("http://www.garanteprivacy.it/", "_blank")
                  }
                  className={styles.underline}
                >
                  http://www.garanteprivacy.it/
                </span>
                ).
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        Tramite il presente documento i Contitolari, oltre a fornire agli Utenti
        le informazioni indicate dall’art. 13 del Regolamento, mettono a
        disposizione dell’interessato il contenuto essenziale dell’accordo di
        contitolarità sottoscritto, ai sensi dell’art. 26 del Regolamento, dai
        Contitolari.
      </div>
    </div>
  );
};
