import styles from "./TherapistFeedbackModal.module.scss"
import FormHeader from "../../atoms/FormHeader";
import Button from "../../atoms/Button";

import { useState } from "react";
import { trpc } from "../../../utils/trpc";
import { queryClient } from "../../../index";
import { setInfo } from "../../../store/slices/infoSlice";
import { useAppDispatch } from "../../../customHooks/reduxHooks";
import Emitter from "../../../utils/eventemitter";
import { raiseException } from "../../../utils/raiseException";

const ChangeTherapistFeedback = [
	{
		value: 'Il profilo del terapeuta attuale non rispecchia le mie esigenze'
	},
	{
		value: 'Ho bisogno di un terapeuta con competenze specifiche'
	},
	{
		value: 'Non mi sento a mio agio con il terapeuta attuale\n'
	},
	{
		value: 'Le mie disponibilità orarie non coincidono con quelle del terapeuta'
	},
	{
		value: 'Vorrei modificare le mie risposte al questionario'
	},
	{
		value: 'Altro'
	},
]


export const TherapistFeedbackModal = ({gender, modalClose}: any) => {
	const [changeMotive, setChangeMotive] = useState("");

	const RadioInput = ({id, option, checked, style, onChange}) => {
		return (
			<label htmlFor={id} className={checked ? style : ''}>
				<input
					id={id}
					type="radio"
					value={option.value}
					checked={checked}
					onChange={onChange}
				/>
				{option.value}
			</label>
		);
	}

	const dispatch = useAppDispatch();

	const getSuggestedTherapist = trpc.mainService.patient.getSuggestedTherapists.useQuery(undefined, {
		enabled: false,
	});

	const chooseTherapist =
		trpc.mainService.patient.chooseTherapist.useMutation();

	const sendChangeTherapistFeedback =
		trpc.mainService.patient.sendChangeTherapistFeedback.useMutation();

	const getTherapist = async (id: any) => {
		chooseTherapist
			.mutateAsync({therapistId: id, therapistChange: true})
			.then(() => {
				queryClient.removeQueries();
				Emitter.emit("updateTherapistChange");
			})
			.catch((e) => {
				raiseException(e);
				dispatch(
					setInfo({
						variant: "error",
						text: e.message,
						title: "Errore scelta terapeuta",
					})
				);
			});
	};

	const changeTherapist = async () => {
		try {
			const getTherapists = await getSuggestedTherapist.refetch();
			if (getTherapists?.data?.suggestedTherapists.length) {
				await sendChangeTherapistFeedback.mutateAsync({feedback: changeMotive})
				const therapistData: any = getTherapists.data?.suggestedTherapists[0];
				await getTherapist(therapistData.id);
			}
		} catch (error: any) {
			raiseException(error);
			console.error("Error", error);
		}
	}

	const closeFeedbackModal = () => {
		modalClose(false);
	}

	return (
		<div className={`${styles.modal}`}>
			<div className={styles.changePreferences}>
				<FormHeader
					closeModal={() => closeFeedbackModal()}
					title={`Sei sicur${
						gender === "male"
							? "o"
							: gender === "female"
								? "a"
								: "*"
					} di voler cambiare terapeuta?`}
					closeButton
				/>
				<div className={styles.descritpion}>
					Ti consigliamo di parlarne prima con il tuo terapeuta per capire
					se effettivamente il tuo percorso non soddisfa le tue aspettative
					e quindi provare a vedere se un altra scelta possa fare al caso
					tuo. Motiva la scelta qui sotto:
				</div>
				<div className={styles.radioWrapper}>
					{ChangeTherapistFeedback.map((item, index) => (
						<RadioInput
							key={index}
							id={`option-${index}`}
							option={item}
							style={styles.checked}
							checked={changeMotive === item.value}
							onChange={(e: any) => {
								setChangeMotive(e.target.value)
							}}
						/>
					))}
				</div>
				<div className={styles.btnWrapper}>
					<Button
						variant="secondary"
						onClick={() => closeFeedbackModal()}
					>
						Annulla
					</Button>
					<Button
						disabled={changeMotive.trim() === ""}
						onClick={() => {
							changeTherapist()
						}}
					>
						Avanti
					</Button>
				</div>
			</div>
		</div>
	)
}
