import Button from "components/atoms/Button";
import Input from "components/atoms/Input";
import GenericForm from "components/molecules/GenericForm";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { decodeB64 } from "utils/decodeB64";
import { trpc } from "utils/trpc";
import styles from "./RetrievePassword.module.scss";
import { useAppDispatch } from "customHooks/reduxHooks";
import { setInfo } from "store/slices/infoSlice";
import routes from "routes";
import { useFormik } from "formik";
import * as yup from "yup";
import { analyticsService } from "../../utils/analyticsService";
import { raiseException } from "../../utils/raiseException";
import trimString  from "../../utils/trimString";

export const RetrievePassword = ({ className }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { code } = params;

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const confirmForgotPassword =
    trpc.authService.auth.confirmForgotPassword.useMutation();

  useEffect(() => {
    analyticsService({
      'event': 'virtualPageview',
      'pageUrl': `/recupero-password/`,
      'pageTitle': 'Retrieve Password'
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      password: "",
      checkPassword: "",
    },
    validationSchema: yup.object().shape({
      password: yup
        .string()
        .matches(
          /^(?=.*[a-z])(?=.*\d)[^\s]{8,}$/,
          "La password non rispetta i criteri di sicurezza"
        )
        .required("La password è un campo obbligatorio"),
      checkPassword: yup
        .string()
        .oneOf([yup.ref("password")], "Le due password non combaciano")
        .required("Inserisci di nuovo la nuova password"),
    }),
    onSubmit: (values, actions) => {
      confirmNewPassword({
        password: values.password,
      });
    },
  });

  const confirmNewPassword = ({ password }: { password: string }) => {
    if (newPassword === confirmPassword) {
      const decodeParam = decodeB64(code!);
      confirmForgotPassword
        .mutateAsync({
          userId: decodeParam.userId,
          confirmationCode: decodeParam.confirmationCode,
          password: trimString(password),
        })
        .then((data) => {
          dispatch(
            setInfo({
              text: "La password è stata modificata con successo, effettua il login",
              variant: "confirm",
            })
          );
          navigate(routes.Login.path);
        })
        .catch((e) => {
          raiseException(e);
          console.error(e?.shape?.message)
        });
    } else {
      dispatch(
        setInfo({ text: "Le password non coincidono", variant: "error" })
      );
    }
  };
  return (
    <div className={`${styles.retrievePassword} ${className}`}>
      <GenericForm
        className={styles.form}
        description="Immetti una nuova password"
        title="Recupero password"
        answer={[
          <form className={styles.handleForm} onSubmit={formik.handleSubmit}>
            <Input
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={
                (formik.errors.password &&
                  formik.touched.password &&
                  formik.errors.password) as string
              }
              label="Nuova password"
            />
            <Input
              type="password"
              name="checkPassword"
              value={formik.values.checkPassword}
              onChange={formik.handleChange}
              error={
                (formik.errors.checkPassword &&
                  formik.touched.checkPassword &&
                  formik.errors.checkPassword) as string
              }
              label="Conferma nuova password"
            />
            <div className={styles.pwInfo}>
              La password deve contenere almeno 8 caratteri di cui:{" "}
              <p>un carattere maiuscolo e un carattere numerico</p>
            </div>
            <Button type="submit">Cambia password</Button>
          </form>,
        ]}
      />
    </div>
  );
};
