export const internationalPrefix = [{ nation:'Afghanistan', prefix: '+93'},
{ nation:'Albania', prefix: '+355'},
{ nation:'Algeria', prefix: '+213'},
{ nation:'Andorra ', prefix: '+376'},
{ nation:'Angola ', prefix: '+244'},
{ nation:'Antarctica', prefix: '+672'},
{ nation:'Argentina', prefix: '+54'},
{ nation:'Armenia ', prefix: '+374'},
{ nation:'Aruba', prefix: '+297'},
{ nation:'Ascension', prefix: '+247'},
{ nation:'Australia ', prefix: '+61'},
{ nation:'Australian External Territories', prefix: '+672'},
{ nation:'Austria', prefix: '+43'},
{ nation:'Azerbaijan', prefix: '+994'},
{ nation:'Bahrain', prefix: '+973'},
{ nation:'Bangladesh', prefix: '+880'},
{ nation:'Belarus', prefix: '+375'},
{ nation:'Belgium', prefix: '+32'},
{ nation:'Belize', prefix: '+501'},
{ nation:'Benin', prefix: '+229'},
{ nation:'Bhutan', prefix: '+975'},
{ nation:'Bolivia ', prefix: '+591'},
{ nation:'Bosnia & Herzegovina ', prefix: '+387'},
{ nation:'Botswana ', prefix: '+267'},
{ nation:'Brazil ', prefix: '+55'},
{ nation:'Brunei Darussalam', prefix: '+673'},
{ nation:'Bulgaria', prefix: '+359'},
{ nation:'Burkina Faso ', prefix: '+226'},
{ nation:'Burundi', prefix: '+257'},
{ nation:'Cambodia', prefix: '+855'},
{ nation:'Cameroon', prefix: '+237'},
{ nation:'Canada', prefix: '+1'},
{ nation:'Cape Verde Islands', prefix: '+238'},
{ nation:'Central African Republic', prefix: '+236'},
{ nation:'Chad ', prefix: '+235'},
{ nation:'Chatham Island  (nation:New Zealand)', prefix: '+64'},
{ nation:'Chile ', prefix: '+56'},
{ nation:'China (nation:PRC)', prefix: '+86'},
{ nation:'Christmas Island', prefix: '+61-8'},
{ nation:'Cocos-Keeling Islands', prefix: '+61'},
{ nation:'Colombia ', prefix: '+57'},
{ nation:'Comoros', prefix: '+269'},
{ nation:'Congo', prefix: '+242'},
{ nation:'Congo, Dem. Rep. of? (nation:former Zaire)', prefix: '+243'},
{ nation:'Cook Islands', prefix: '+682'},
{ nation:'Costa Rica', prefix: '+506'},
{ nation:'Cote d\'Ivoire () nation:Ivory Coast)', prefix: '+225'},
{ nation:'Croatia', prefix: '+385'},
{ nation:'Cuba', prefix: '+53'},
{ nation:'Cuba (nation:Guantanamo Bay)', prefix: '+5399'},
{ nation:'Curacao', prefix: '+599'},
{ nation:'Cyprus', prefix: '+357'},
{ nation:'Czech Republic', prefix: '+420'},
{ nation:'Denmark', prefix: '+45'},
{ nation:'Diego Garcia', prefix: '+246'},
{ nation:'Djibouti', prefix: '+253'},
{ nation:'East Timor', prefix: '+670'},
{ nation:'Easter Island', prefix: '+56'},
{ nation:'Ecuador ', prefix: '+593'},
{ nation:'Egypt', prefix: '+20'},
{ nation:'El Salvador', prefix: '+503'},
{ nation:'Equatorial Guinea', prefix: '+240'},
{ nation:'Eritrea', prefix: '+291'},
{ nation:'Estonia', prefix: '+372'},
{ nation:'Ethiopia', prefix: '+251'},
{ nation:'Falkland Islands  (nation:Malvinas)', prefix: '+500'},
{ nation:'Faroe Islands', prefix: '+298'},
{ nation:'Fiji Islands', prefix: '+679'},
{ nation:'Finland', prefix: '+358'},
{ nation:'France', prefix: '+33'},
{ nation:'French Antilles', prefix: '+596'},
{ nation:'French Guiana', prefix: '+594'},
{ nation:'French Polynesia', prefix: '+689'},
{ nation:'Gabonese Republic', prefix: '+241'},
{ nation:'Gambia', prefix: '+220'},
{ nation:'Georgia', prefix: '+995'},
{ nation:'Germany', prefix: '+49'},
{ nation:'Ghana ', prefix: '+233'},
{ nation:'Gibraltar ', prefix: '+350'},
{ nation:'Greece ', prefix: '+30'},
{ nation:'Greenland ', prefix: '+299'},
{ nation:'Guadeloupe', prefix: '+590'},
{ nation:'Guantanamo Bay', prefix: '+5399'},
{ nation:'Guatemala ', prefix: '+502'},
{ nation:'Guinea-Bissau ', prefix: '+245'},
{ nation:'Guinea', prefix: '+224'},
{ nation:'Guyana', prefix: '+592'},
{ nation:'Haiti ', prefix: '+509'},
{ nation:'Honduras', prefix: '+504'},
{ nation:'Hong Kong', prefix: '+852'},
{ nation:'Hungary ', prefix: '+36'},
{ nation:'Iceland', prefix: '+354'},
{ nation:'India', prefix: '+91'},
{ nation:'Indonesia', prefix: '+62'},
{ nation:'Iran', prefix: '+98'},
{ nation:'Iraq', prefix: '+964'},
{ nation:'Ireland', prefix: '+353'},
{ nation:'Israel ', prefix: '+972'},
{ nation:'Italy ', prefix: '+39'},
{ nation:'Japan ', prefix: '+81'},
{ nation:'Jordan', prefix: '+962'},
{ nation:'Kazakhstan', prefix: '+7'},
{ nation:'Kenya', prefix: '+254'},
{ nation:'Kiribati ', prefix: '+686'},
{ nation:'Korea (nation:North)', prefix: '+850'},
{ nation:'Korea (nation:South)', prefix: '+82'},
{ nation:'Kuwait ', prefix: '+965'},
{ nation:'Kyrgyz Republic', prefix: '+996'},
{ nation:'Laos', prefix: '+856'},
{ nation:'Latvia ', prefix: '+371'},
{ nation:'Lebanon', prefix: '+961'},
{ nation:'Lesotho', prefix: '+266'},
{ nation:'Liberia', prefix: '+231'},
{ nation:'Libya', prefix: '+218'},
{ nation:'Liechtenstein', prefix: '+423'},
{ nation:'Lithuania ', prefix: '+370'},
{ nation:'Luxembourg', prefix: '+352'},
{ nation:'Macao', prefix: '+853'},
{ nation:'Macedonia (nation:Former Yugoslav Rep of.)', prefix: '+389'},
{ nation:'Madagascar', prefix: '+261'},
{ nation:'Malawi ', prefix: '+265'},
{ nation:'Malaysia', prefix: '+60'},
{ nation:'Maldives', prefix: '+960'},
{ nation:'Mali Republic', prefix: '+223'},
{ nation:'Malta', prefix: '+356'},
{ nation:'Marshall Islands', prefix: '+692'},
{ nation:'Martinique', prefix: '+596'},
{ nation:'Mauritania', prefix: '+222'},
{ nation:'Mauritius', prefix: '+230'},
{ nation:'Mayotte Island', prefix: '+269'},
{ nation:'Mexico', prefix: '+52'},
{ nation:'Micronesia, (nation:Federal States of)', prefix: '+691'},
{ nation:'Moldova ', prefix: '+373'},
{ nation:'Monaco', prefix: '+377'},
{ nation:'Mongolia ', prefix: '+976'},
{ nation:'Montenegro', prefix: '+382'},
{ nation:'Morocco', prefix: '+212'},
{ nation:'Mozambique', prefix: '+258'},
{ nation:'Myanmar', prefix: '+95'},
{ nation:'Namibia', prefix: '+264'},
{ nation:'Nauru', prefix: '+674'},
{ nation:'Nepal ', prefix: '+977'},
{ nation:'Netherlands', prefix: '+31'},
{ nation:'Netherlands Antilles', prefix: '+599'},
{ nation:'New Caledonia', prefix: '+687'},
{ nation:'New Zealand', prefix: '+64'},
{ nation:'Nicaragua', prefix: '+505'},
{ nation:'Niger', prefix: '+227'},
{ nation:'Nigeria', prefix: '+234'},
{ nation:'Niue', prefix: '+683'},
{ nation:'Norfolk Island', prefix: '+672'},
{ nation:'Norway ', prefix: '+47'},
{ nation:'Oman', prefix: '+968'},
{ nation:'Pakistan', prefix: '+92'},
{ nation:'Palau', prefix: '+680'},
{ nation:'Palestinian Settlements', prefix: '+970'},
{ nation:'Panama', prefix: '+507'},
{ nation:'Papua New Guinea', prefix: '+675'},
{ nation:'Paraguay', prefix: '+595'},
{ nation:'Peru', prefix: '+51'},
{ nation:'Philippines', prefix: '+63'},
{ nation:'Poland', prefix: '+48'},
{ nation:'Portugal', prefix: '+351'},
{ nation:'Qatar', prefix: '+974'},
{ nation:'R?union Island', prefix: '+262'},
{ nation:'Romania', prefix: '+40'},
{ nation:'Russia', prefix: '+7'},
{ nation:'Rwandese Republic', prefix: '+250'},
{ nation:'St. Helena', prefix: '+290'},
{ nation:'St. Pierre & Miquelon', prefix: '+508'},
{ nation:'Samoa', prefix: '+685'},
{ nation:'San Marino', prefix: '+378'},
{ nation:'S?o Tom? and Principe', prefix: '+239'},
{ nation:'Saudi Arabia', prefix: '+966'},
{ nation:'Senegal ', prefix: '+221'},
{ nation:'Serbia', prefix: '+381'},
{ nation:'Seychelles Republic', prefix: '+248'},
{ nation:'Sierra Leone', prefix: '+232'},
{ nation:'Singapore', prefix: '+65'},
{ nation:'Slovak Republic', prefix: '+421'},
{ nation:'Slovenia ', prefix: '+386'},
{ nation:'Solomon Islands', prefix: '+677'},
{ nation:'Somali Democratic Republic', prefix: '+252'},
{ nation:'South Africa', prefix: '+27'},
{ nation:'Spain', prefix: '+34'},
{ nation:'Sri Lanka', prefix: '+94'},
{ nation:'Sudan', prefix: '+249'},
{ nation:'Suriname ', prefix: '+597'},
{ nation:'Swaziland', prefix: '+268'},
{ nation:'Sweden', prefix: '+46'},
{ nation:'Switzerland', prefix: '+41'},
{ nation:'Syria', prefix: '+963'},
{ nation:'Taiwan', prefix: '+886'},
{ nation:'Tajikistan', prefix: '+992'},
{ nation:'Tanzania', prefix: '+255'},
{ nation:'Thailand', prefix: '+66'},
{ nation:'Timor Leste', prefix: '+670'},
{ nation:'Togolese Republic', prefix: '+228'},
{ nation:'Tokelau', prefix: '+690'},
{ nation:'Tonga Islands', prefix: '+676'},
{ nation:'Tunisia', prefix: '+216'},
{ nation:'Turkey', prefix: '+90'},
{ nation:'Turkmenistan ', prefix: '+993'},
{ nation:'Tuvalu', prefix: '+688'},
{ nation:'Uganda', prefix: '+256'},
{ nation:'Ukraine', prefix: '+380'},
{ nation:'United Arab Emirates', prefix: '+971'},
{ nation:'United Kingdom', prefix: '+44'},
{ nation:'United States of America', prefix: '+1'},
{ nation:'Universal Personal Telecommunications  (nation:UPT)', prefix: '+878'},
{ nation:'Uruguay', prefix: '+598'},
{ nation:'Uzbekistan', prefix: '+998'},
{ nation:'Vanuatu', prefix: '+678'},
{ nation:'Vatican City', prefix: '+39'},
{ nation:'Venezuela', prefix: '+58'},
{ nation:'Vietnam', prefix: '+84'},
{ nation:'Wake Island', prefix: '+808'},
{ nation:'Wallis and Futuna Islands', prefix: '+681'},
{ nation:'Yemen', prefix: '+967'},
{ nation:'Zambia ', prefix: '+260'},
{ nation:'Zanzibar', prefix: '+255'},
{ nation:'Zimbabwe ', prefix: '+263'}]