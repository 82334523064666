/*
&navigate Testo cliccabile &link/pathDaNavigare&link &navigate 
utilizzato per wrappare il testo di un link 

&colored Testo da colorare &colored
utilizzato per colorare un testo (per il momento solo verde)
*/
import { FunctionComponent } from "react";
import styles from "./Text.module.scss";
import { useNavigate } from "react-router-dom";
import reactStringReplace from "react-string-replace";
import routes from "routes";
type TextType =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "span"
  | "p"
  | "label"
  | "li";

enum HtmlType {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  h6 = "h6",
  span = "span",
  p = "p",
  label = "label",
  li = "li",
  link = "link",
}

interface PropsText {
  type: TextType | string | undefined;
  children: string | any | undefined;
  className?: string;
  onClick?: () => void;
}

export const Text: FunctionComponent<PropsText> = (props) => {
  const navigate = useNavigate();
  switch (props.type) {
    case HtmlType.h1:
      return (
        <h1 className={`${styles.h2} ${props.className}`}>{props.children}</h1>
      );
    case HtmlType.h2:
      return (
        <h2 className={`${styles.h2} ${props.className}`}>{props.children}</h2>
      );
    case HtmlType.h3:
      if (props.children.includes("&navigate")) {
        const arrayString = props.children.split("&navigate");
        return arrayString.map((s: string, index: number) => {
          if ((index + 1) % 3 === 0) {
            return (
              <h3 key={index} className={`${styles.h3} ${props.className}`}>
                {arrayString[index - 2]}{" "}
                <span
                  onClick={() => navigate(s)}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontWeight: "600",
                  }}
                >
                  {arrayString[index - 1]}
                </span>
              </h3>
            );
          }
        });
      }
      return (
        <h3 className={`${styles.h3} ${props.className}`}>{props.children}</h3>
      );
    case HtmlType.h4:
      return (
        <h4 className={`${styles.h4} ${props.className}`}>{props.children}</h4>
      );
    case HtmlType.h5:
      return (
        <h5 className={`${styles.h5} ${props.className}`}>{props.children}</h5>
      );
    case HtmlType.h6:
      return (
        <h6 className={`${styles.h6} ${props.className}`}>{props.children}</h6>
      );
    case HtmlType.span:
      return (
        <span
          onClick={() => props.onClick && props.onClick()}
          className={`${styles.span} ${props.className}`}
        >
          {props.children}
        </span>
      );
    case HtmlType.link:
      return (
        <a
          onClick={() => props.onClick && props.onClick()}
          className={`${styles.link} ${props.className}`}
        >
          {props.children}
        </a>
      );
    case HtmlType.p:
      if (props.children.includes("&bold")) {
        const textInBold = props.children.split("&bold")[1];
        return (
          <p className={`${styles.p} ${props.className}`}>
            {props.children.split("&bold")[0]}
            <span
              className={`${styles.p} ${props.className}`}
              style={{ fontWeight: "600" }}
            >
              {textInBold}
            </span>
            {props.children.split("&bold")[2]}
          </p>
        );
      }
      if (props.children.includes("&navigate")) {
        const arrayString = props.children.split("&navigate");
        return (
          <p className={`${styles.p} ${props.className}`}>
            {arrayString.map((s: string, index: number) => {
              if (s.includes("&link")) {
                const link = s.split("&link");
                const linkText = link[0];
                const linkPath = link[1];
                return (
                  <span
                    key={index}
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontWeight: "500",
                    }}
                    onClick={() => window.open(linkPath)}
                  >
                    {linkText}
                  </span>
                );
              }
              if (s.includes("@")) {
                const email = s.split(" ").find((s) => s.includes("@"));
                console.log("EMAIL", email);
                return (
                  <span>
                    {reactStringReplace(s, email, (match, index) => {
                      const mail = JSON.stringify(match);
                      return (
                        <a key={index} href={`mailto:${mail}`}>
                          {match}
                        </a>
                      );
                    })}
                  </span>
                );
              }
              return <span>{s}</span>;
            })}
          </p>
        );
      }
      if (props.children.includes("@")) {
        const email = props.children.split(" ").find((s) => s.includes("@"));
        console.log("EMAIL", email);

        return (
          <p className={`${styles.p} ${props.className}`}>
            {reactStringReplace(props.children, email, (match, index) => {
              const mail = JSON.stringify(match);
              return (
                <a key={index} href={`mailto:${mail}`}>
                  {match}.
                </a>
              );
            })}
          </p>
        );
      }
      if (typeof props.children === "object") {
        return props.children.map((s: string, index: number) => {
          if (s.includes("&navigate")) {
            const arrayString = s.split("&navigate");
            return (
              <p key={index} className={`${styles.p} ${props.className}`}>
                {arrayString.map((s: string, index: number) => {
                  if (s.includes("&link")) {
                    const link = s.split("&link");
                    const linkText = link[0];
                    const linkPath = link[1];
                    return (
                      <span
                        key={index}
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                          fontWeight: "500",
                        }}
                        onClick={() => window.open(linkPath)}
                      >
                        {linkText}
                      </span>
                    );
                  }
                  if (s.includes("@")) {
                    const email = s.split(" ").find((sub) => sub.includes("@"));
                    console.log(email);
                    return (
                      <span>
                        {reactStringReplace(s, email, (match, index) => {
                          const mail = JSON.stringify(match);
                          return (
                            <a key={index} href={`mailto:${mail}`}>
                              {match}.
                            </a>
                          );
                        })}
                      </span>
                    );
                  }
                  return <span>{s}</span>;
                })}
              </p>
            );
          }
          if (s.includes("@")) {
            const email = s.split(" ").find((sub) => sub.includes("@"));
            console.log(email);
            return (
              <p className={`${styles.p} ${props.className}`}>
                {reactStringReplace(s, email, (match, index) => {
                  const mail = JSON.stringify(match);
                  return (
                    <a key={index} href={`mailto:${mail}`}>
                      {match}.
                    </a>
                  );
                })}
              </p>
            );
          }
          if (s.includes("&bold")) {
            const textInBold = s.split("&bold")[1];
            return (
              <p
                className={`${styles.p} ${props.className}`}
                key={index}
                style={
                  props.children.length !== index + 1
                    ? { marginBottom: 0, fontWeight: "600" }
                    : { fontWeight: "600" }
                }
              >
                {textInBold}
              </p>
            );
          }
          if (s.includes("&indent")) {
            const textInBold = s.split("&indent")[1];
            return (
              <p
                className={`${styles.p} ${props.className}`}
                key={index}
                style={
                  props.children.length !== index + 1
                    ? { marginBottom: 0, marginLeft: "8px" }
                    : { marginLeft: "8px" }
                }
              >
                {textInBold}
              </p>
            );
          }
          return (
            <p
              className={`${styles.p} ${props.className}`}
              key={index}
              style={
                props.children.length !== index + 1 ? { marginBottom: 0 } : {}
              }
            >
              {s}
            </p>
          );
        });
      }
      return (
        <p className={`${styles.p} ${props.className}`}>{props.children}</p>
      );
    case HtmlType.label:
      return (
        <label className={`${styles.label} ${props.className}`}>
          {props.children}
        </label>
      );
    case HtmlType.li:
      if (props.children.includes("&navigate")) {
        const arrayString = props.children.split("&navigate");
        return arrayString.map((s: string, index: number) => {
          if ((index + 1) % 3 === 0) {
            return (
              <li key={index} className={`${styles.li} ${props.className}`}>
                {arrayString[index - 2]}{" "}
                <span
                  onClick={() => navigate(s)}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {arrayString[index - 1]}
                </span>
              </li>
            );
          }
        });
      }
      if (props.children.includes("&colored")) {
        const arrayString = props.children.split("&colored");
        return arrayString.map((s: string, index: number) => {
          if ((index + 1) % 3 === 0) {
            return (
              <li key={index} className={`${styles.li} ${props.className}`}>
                {arrayString[index - 2]}
                <span
                  style={{
                    color: "green",
                  }}
                >
                  {arrayString[index - 1]}
                </span>
              </li>
            );
          }
        });
      }
      if (props.children === "Privacy Policy") {
        return (
          <li
            style={{ cursor: "pointer" }}
            onClick={() => navigate(routes.GeneralPrivacy.path)}
            className={`${styles.li} ${props.className}`}
          >
            {props.children}
          </li>
        );
      }
      if (props.children === "Cookie") {
        return (
          <li
            style={{ cursor: "pointer" }}
            onClick={() => {
              // @ts-ignore
              window?.___pubtech_open_cmp();
            }}
            className={`${styles.li} ${props.className}`}
          >
            {props.children}
          </li>
        );
      }
      return (
        <li
          onClick={() => props.onClick && props.onClick()}
          className={`${styles.li} ${props.className}`}
        >
          {props.children}
        </li>
      );
    default:
      return <span>INVALID_TYPE_TEXT</span>;
  }
};
