import { useEffect, useRef } from "react";
import Footer from "../../components/landingPageComponents/components/Footer";
import { EmergencyNumbers } from "../../components/landingPageComponents/sections/EmergencyNumbers/EmergencyNumbers";
import IntroductorySection from "../../components/landingPageComponents/sections/IntroductorySection";
import styles from "./ContactUs.module.scss";
import IntroductoryContactUsContent from "./ContactUsText/index";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { envs } from "utils/envs";
import routes from "routes";
import { analyticsService } from "../../utils/analyticsService";
export const ContactUs = () => {

  useEffect(() => {
    analyticsService({'event': 'virtualPageview', 'pageUrl': '/contatti', 'pageTitle': 'Contact Us'});
  }, []);

  return (
    <main>
      <Helmet>
        <title>Contatti</title>
        <meta
          name="description"
          content="Se hai domande o curiosità riguardo al servizio offerto da Mymentis, alla terapia psicologica online o ai nostri psicologi, ti invitiamo a consultare la sezione “domande frequenti”. Speriamo di riuscire a darti la risposta che cerchi, ma nel caso in cui avessi bisogno del nostro supporto Contattaci. Il nostro team di assistenza ti risponderà il prima possibile."
        />
        <meta property="og:title" content="Contatti" />
        <meta
          property="og:description"
          content="Se hai domande o curiosità riguardo al servizio offerto da Mymentis, alla terapia psicologica online o ai nostri psicologi, ti invitiamo a consultare la sezione “domande frequenti”. Speriamo di riuscire a darti la risposta che cerchi, ma nel caso in cui avessi bisogno del nostro supporto Contattaci. Il nostro team di assistenza ti risponderà il prima possibile."
        />
        {envs.REACT_APP_ENVIRONMENT === "Prod" ? (
          <link
            rel="canonical"
            href={`https://mymentis.it${routes.ContactUs.path}`}
          />
        ) : null}
      </Helmet>
      <div className={styles.contactUs}>
        <IntroductorySection content={IntroductoryContactUsContent} hasForm />
        <EmergencyNumbers />
        <Footer />
      </div>
    </main>
  );
};
