export const hoursSlot = [
  {
    slot: "morning",
    hours: [
      "08:00:00",
      "08:15:00",
      "08:30:00",
      "08:45:00",
      "09:00:00",
      "09:15:00",
      "09:30:00",
      "09:45:00",
      "10:00:00",
      "10:15:00",
      "10:30:00",
      "10:45:00",
      "11:00:00",
      "11:15:00",
      "11:30:00",
      "11:45:00",
      "12:00:00",
      "12:15:00",
      "12:30:00",
      "12:45:00",
    ],
  },
  {
    slot: "afternoon",
    hours: [
      "13:00:00",
      "13:15:00",
      "13:30:00",
      "13:45:00",
      "14:00:00",
      "14:15:00",
      "14:30:00",
      "14:45:00",
      "15:00:00",
      "15:15:00",
      "15:30:00",
      "15:45:00",
      "16:00:00",
      "16:15:00",
      "16:30:00",
      "16:45:00",
      "17:00:00",
      "17:15:00",
      "17:30:00",
      "17:45:00",
    ],
  },
  {
    slot: "late_afternoon",
    hours: [
      "18:00:00",
      "18:15:00",
      "18:30:00",
      "18:45:00",
      "19:00:00",
      "19:15:00",
      "19:30:00",
      "19:45:00",
      "20:00:00",
    ],
  },
  {
    slot: "evening",
    hours: [
      "20:15:00",
      "20:30:00",
      "20:45:00",
      "21:00:00",
      "21:15:00",
      "21:30:00",
      "21:45:00",
      "22:00:00",
      "22:15:00",
      "22:30:00",
      "22:45:00",
    ],
  },
];
