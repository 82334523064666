import styles from "./Privacy.module.scss";
import { analyticsService } from "../../utils/analyticsService";
import { useEffect } from "react";

export const PatientTherapistPrivacy = ({ className }) => {

  useEffect(() => {
    analyticsService({
      'event': 'virtualPageview',
      'pageUrl': '/privacy-paziente-terapeuta',
      'pageTitle': 'Patient Therapist Privacy'
    });
  },[]);

  return (
    <div className={`${styles.privacy} ${className}`}>
      <h2>INFORMATIVA PRIVACY</h2>
      <div className={styles.subtitle}>
        La presente informativa privacy ha lo scopo di fornire tutte le
        informazioni sul trattamento dei dati personali effettuato da{" "}
        <span>Mymentis S.r.l.</span> e il <span>Professionista</span> quando
        l’Utente compila il questionario Mymentis (come di seguito meglio
        indicato).
      </div>
      <br />
      <div className={styles.articlesWrapper}>
        <div className={styles.article}>
          <div className={styles.articleTitle}>
            1. INTRODUZIONE - CHI SIAMO?
          </div>
          <div className={styles.articleDescription}>
            <div>
              Il Professionista, come identificato nella dichiarazione di
              “Consenso Informato per la prestazione professionale psicologica”
              consegnata al Paziente unitamente alla presente informativa (di
              seguito, il <span>“Titolare”</span> o il{" "}
              <span>“Professionista”</span>), in qualità di
              <span>titolare del trattamento dei dati personali</span> dei
              Pazienti che usufruiscono delle prestazioni del Professionista (di
              seguito,
              <span>“Pazienti”</span>) fornisce qui di seguito l’informativa
              privacy ai sensi dell’art. 13 del Regolamento UE 2016/679 del 27
              aprile 2016 (di seguito, <span>“Regolamento”</span>, o{" "}
              <span>“Normativa Applicabile”</span>).
            </div>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>
            2. COME CONTATTARE IL PROFESSIONISTA?
          </div>
          <div className={styles.articleDescription}>
            <div>
              Il Professionista tiene nella massima considerazione il diritto
              alla privacy ed alla protezione dei dati personali dei propri
              Pazienti. Per ogni informazione in relazione alla presente
              informativa privacy, i Pazienti possono{" "}
              <span>contattare il Titolare</span> in qualsiasi momento,
              utilizzando le seguenti modalità:
            </div>
            <ul>
              <li>
                Inviando un messaggio al professionista tramite la chat della
                piattaforma Mymentis
              </li>
              <li>
                Inviando un messaggio di posta elettronica all’indirizzo:{" "}
                <a href="mailto:privacy@mymentis.it">privacy@mymentis.it</a>{" "}
                chiedendo di essere messi in contatti con il titolare
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>3. FINALITÀ DEL TRATTAMENTO</div>
          <div className={styles.articleDescription}>
            <div>
              Il Paziente può rivolgersi al Professionista per partecipare a
              percorsi di sostegno psicologico e di psicoterapia, individuali o
              di coppia. Il supporto terapeutico sarà prestato tramite colloqui
              in modalità di videochiamata da remoto attraverso la piattaforma
              “Mymentis” alla quale il Paziente è iscritto (di seguito,
              <span>“Servizio”</span>).
            </div>
            <br />
            <div>
              In relazione alle attività che possono essere svolte tramite il
              Servizio, il Professionista raccoglie dati personali relativi ai
              Pazienti.
            </div>
            <br />
            <div>
              In particolare, i dati personali dei Pazienti saranno trattati
              lecitamente dal Professionista per le seguenti finalità di
              trattamento:
            </div>
            <ol className={styles.listLetter}>
              <li>
                <span className={styles.underline}>
                  obblighi contrattuali e fornitura del servizio
                </span>
                , per fornire la prestazione professionale al Paziente;
                svolgendo la propria attività con professionalità, correttezza e
                buona fede, senza divulgare in nessun caso informazioni
                confidenziali o riservate riguardanti i pazienti nel pieno
                rispetto della legge professionale e del Codice Deontologico
                degli Psicologi italiani. I dati dell’utente raccolti dal
                Professionista ai fini dell’erogazione del Servizio includono:
                il nome, il cognome e il nickname volontariamente scelto, l’età,
                la provincia di residenza/domicilio, il sesso, l’indirizzo
                e-mail, nonché tutte le informazioni personali del Paziente
                eventualmente e volontariamente comunicate. Inoltre, il
                Professionista tratterà dati personali appartenenti a categorie
                particolari, come definiti dall’art. 9 del Regolamento e, nello
                specifico, dati relativi alla salute, la vita sessuale o
                l’orientamento sessuale dell’Utente. Nessun altro trattamento
                verrà effettuato dal Professionista in relazione ai dati
                personali del Paziente. Fermo restando quanto previsto altrove
                in questa informativa privacy, in nessun caso il Professionista
                renderà accessibili agli altri pazienti e/o a terzi i dati
                personali del Paziente.
              </li>
              <li>
                <span className={styles.underline}>
                  finalità amministrativo-contabili
                </span>
                , ovvero per svolgere attività di natura organizzativa,
                amministrativa, finanziaria e contabile, quali attività
                organizzative interne ed attività funzionali all’adempimento di
                obblighi contrattuali e precontrattuali;
              </li>
              <li>
                <span className={styles.underline}>obblighi di legge</span>,
                ovvero per adempiere ad obblighi previsti dalla legge, da
                un’autorità, da un regolamento o dalla normativa europea.
              </li>
            </ol>
            <div>
              Il conferimento dei dati personali per le finalità di trattamento
              sopra indicate è facoltativo ma necessario, poiché il mancato
              conferimento degli stessi comporterà l’impossibilità per il
              Paziente di usufruire del Servizio.
            </div>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>5. BASE GIURIDICA</div>
          <div className={styles.articleDescription}>
            <div>
              <span className={styles.underline}>
                Obblighi contrattuali e fornitura del servizio
              </span>{" "}
              (così come descritto dal precedente par. 3, lett. a)): la base
              giuridica consiste nell’art. 6, comma 1, lett. b) del Regolamento,
              ovvero il trattamento è necessario all’esecuzione di un contratto
              di cui il Paziente è parte o all’esecuzione di misure
              precontrattuali adottate su richiesta dello stesso.
            </div>
            <br />
            <div>
              Con riguardo al trattamento dei dati appartenenti a categorie
              particolari, come i dati relativi alla salute, alla vita sessuale
              o all’orientamento sessuale, la base giuridica del trattamento
              consiste nell’art. 9, comma 2, lett. h) del Regolamento, ovvero il
              trattamento è necessario per finalità di assistenza o terapia
              sanitaria conformemente al contratto con un professionista
              soggetto al segreto professionale conformemente al diritto
              dell’Unione o degli Stati membri.
            </div>
            <br />
            <div>
              <span className={styles.underline}>
                Finalità amministrativo-contabili
              </span>{" "}
              (così come descritto dal precedente par. 3, lett. b)): la base
              giuridica consiste nell’art. 6, comma 1, lett. b) del Regolamento,
              in quanto il trattamento è necessario all’esecuzione di un
              contratto e/o all’esecuzione di misure precontrattuali adottate su
              richiesta del Paziente.
            </div>
            <br />
            <div>
              <span className={styles.underline}>Obblighi di legge</span> (così
              come descritto dal precedente par. 3, lett. c)): la base giuridica
              consiste nell’art. 6, comma 1, lett. c) del Regolamento, in quanto
              il trattamento è necessario per adempiere un obbligo legale al
              quale è soggetto il Titolare del trattamento.
            </div>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>
            6. MODALITÀ DI TRATTAMENTO E TEMPI DI CONSERVAZIONE DEI DATI
          </div>
          <div className={styles.articleDescription}>
            <div>
              Il Professionista tratterà i dati personali dei Pazienti mediante
              strumenti manuali ed informatici, con logiche strettamente
              correlate alle finalità stesse e, comunque, in modo da garantire
              la sicurezza e la riservatezza dei dati stessi.
            </div>
            <br />
            <div>
              I dati personali del Paziente saranno conservati per i tempi
              strettamente necessari ad espletare le finalità primarie
              illustrate al precedente paragrafo 3, o comunque secondo quanto
              necessario per la tutela in sede civilistica degli interessi sia
              del Pazienti che del Professionista.
            </div>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>
            7. AMBITO DI COMUNICAZIONE E DIFFUSIONE DEI DATI
          </div>
          <div className={styles.articleDescription}>
            <div>
              Potranno venire a conoscenza dei dati personali del Paziente i
              collaboratori del Titolare incaricati appositamente incaricati.
              Tali soggetti, che sono stati istruiti in tal senso dal Titolare
              ai sensi dell’art. 29 del Regolamento, tratteranno i dati del
              Paziente esclusivamente per le finalità indicate nella presente
              informativa e nel rispetto delle previsioni della Normativa
              Applicabile.
            </div>
            <br />
            <div>
              Potranno inoltre venire a conoscenza dei dati personali del
              Paziente i soggetti terzi che potranno trattare dati personali per
              conto del Titolare in qualità di{" "}
              <span>Responsabili del Trattamento</span>, quali, a titolo
              esemplificativo, fornitori di servizi informatici e logistici,
              fornitori di servizi in outsourcing o cloud computing,
              professionisti e consulenti.
            </div>
          </div>
        </div>
        <div className={styles.article}>
          <div className={styles.articleTitle}>
            7. AMBITO DI COMUNICAZIONE E DIFFUSIONE DEI DATI
          </div>
          <div className={styles.articleDescription}>
            <div>
              I Pazienti potranno esercitare i diritti garantiti loro dalla
              Normativa Applicabile, contattando il Professionista con le
              seguenti modalità:
            </div>
            <ul>
              <li>
                Inviando un messaggio al professionista tramite la chat della
                piattaforma Mymentis
              </li>
              <li>
                Inviando un messaggio di posta elettronica all’indirizzo:{" "}
                <a href="mailto:privacy@mymentis.it">privacy@mymentis.it</a>{" "}
                chiedendo di essere messi in contatti con il titolare{" "}
              </li>
            </ul>
            <br />
            <div>
              Ai sensi della Normativa Applicabile, il Titolare informa che i
              Pazienti hanno il diritto di ottenere l’indicazione (i)
              dell’origine dei dati personali; (ii) delle finalità e modalità
              del trattamento; (iii) della logica applicata in caso di
              trattamento effettuato con l’ausilio di strumenti elettronici;
              (iv) degli estremi identificativi del titolare e dei responsabili;
              (v) dei soggetti o delle categorie di soggetti ai quali i dati
              personali possono essere comunicati o che possono venirne a
              conoscenza in qualità di responsabili o incaricati.
            </div>
            <br />
            <div>Inoltre, i Pazienti hanno il diritto di ottenere:</div>
            <ol className={styles.listLetter}>
              <li>
                l’<span>accesso</span>, l’<span>aggiornamento</span>, la
                rettificazione ovvero, quando vi hanno interesse, l’
                <span>integrazione</span> dei dati;
              </li>
              <li>
                la <span>cancellazione</span>, la{" "}
                <span>trasformazione in forma anonima</span> o la{" "}
                <span>limitazione</span> dei dati trattati in violazione di
                legge, compresi quelli di cui non è necessaria la conservazione
                in relazione agli scopi per i quali i dati sono stati raccolti o
                successivamente trattati;
              </li>
              <li>
                l’attestazione che le operazioni di cui alle lettere a) e b)
                sono state portate a conoscenza, anche per quanto riguarda il
                loro contenuto, di coloro ai quali i dati sono stati comunicati
                o diffusi, eccettuato il caso in cui tale adempimento si rivela
                impossibile o comporta un impiego di mezzi manifestamente
                sproporzionato rispetto al diritto tutelato.
              </li>
            </ol>
            <br />
            <div>Inoltre, i Pazienti hanno:</div>
            <ol className={styles.listLetter}>
              <li>
                il diritto di <span>revocare</span> il consenso in qualsiasi
                momento, qualora il trattamento si basi sul loro consenso;
              </li>
              <li>
                il diritto alla <span>portabilità dei dati</span> (diritto di
                ricevere tutti i dati personali che li riguardano in un formato
                strutturato, di uso comune e leggibile da dispositivo
                automatico);
              </li>
              <li>
                il <span>diritto di opporsi</span>:
                <ol className={styles.listRomanNumber}>
                  <li>
                    in tutto o in parte, per motivi legittimi al trattamento dei
                    dati personali che li riguardano, ancorché pertinenti allo
                    scopo della raccolta;
                  </li>
                  <li>
                    in tutto o in parte, al trattamento di dati personali che li
                    riguardano a fini di invio di materiale pubblicitario o di
                    vendita diretta o per il compimento di ricerche di mercato o
                    di comunicazione commerciale;
                  </li>
                </ol>
              </li>
              <li>
                qualora ritenessero che il trattamento che li riguarda violi il
                Regolamento, il diritto di{" "}
                <span>proporre reclamo a un’Autorità di controllo</span> (nello
                Stato membro in cui risiedono abitualmente, in quello in cui
                lavorano oppure in quello in cui si è verificata la presunta
                violazione). L’Autorità di controllo italiana è il{" "}
                <span>Garante per la protezione dei dati personali</span>, con
                sede in Piazza Venezia n. 11, 00187 – Roma (
                <a target='_blank' href="http://www.garanteprivacy.it/">
                  http://www.garanteprivacy.it/
                </a>
                ).
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        Il Titolare non è responsabile per l’aggiornamento di tutti i link
        visualizzabili nella presente Informativa; pertanto, ogni qualvolta un
        link non sia funzionante e/o aggiornato, i Pazienti riconoscono ed
        accettano che dovranno sempre far riferimento al documento e 
      </div>
    </div>
  );
};

export default PatientTherapistPrivacy;
