import { FunctionComponent } from "react";
import Card from "../../components/Card";
import { TextContainer } from "../../components/TextContainer";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { PropsCard } from "../../components/Card";
import styles from "./SimpleSection.module.scss";

export interface SimpleSectionProps extends PropsCard {
  content: any;
  handlerLabel?: (label: string) => void;
}

export const SimpleSection: FunctionComponent<SimpleSectionProps> = (props) => {
  const contentCard = props.content;
  return (
    <section
      className={`${styles.simpleSection} ${styles[contentCard.section]}`}
    >
      {(contentCard.section === "onlineTherapy" ||
        contentCard.section === "ourHistory" ||
        contentCard.section === "ourStructure") && (
        <Card
          titleType={contentCard.section === "onlineTherapy" ? "h3" : "h4"}
          title={contentCard.title}
          subtitleType="h6"
          subtitle={contentCard.subtitle}
          paragraphs={contentCard.paragraphs}
          link={contentCard.link}
          iconRigthLink={<ChevronRightIcon />}
          src={contentCard.images[0]}
          listPoints={contentCard.lists}
          titleListType="h6"
          imageStains={props.imageStains}
          textStains={props.textStains}
          cardStains={props.cardStains}
          onClick={props.onClick}
        />
      )}
      {contentCard.section === "doubleCard" &&
        contentCard.cards.map((card: any, index: number) => {
          return (
            <Card
              key={index}
              titleType="h4"
              title={card.title}
              paragraphs={card.paragraphs}
              link={card.link}
              src={card.images}
              iconRigthLink={<ChevronRightIcon />}
              textRight
              isHalf
              onClick={
                index === 0
                  ? props.onClick
                  : 'school'
              }
            />
          );
        })}
      {contentCard.section === "labels" && (
        <TextContainer
          handlerLabel={props.handlerLabel}
          listPoints={[contentCard.lists[0]]}
        />
      )}
    </section>
  );
};
