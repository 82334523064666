import * as Sentry from "@sentry/react";
import { envs } from "./envs";

const environment = envs.REACT_APP_SENTRY_ENVIRONMENT;
const dsn = envs.REACT_APP_SENTRY_DSN;

export const initSentry = () => {
  if (environment !== 'development') {
    Sentry.init({
      environment,
      dsn,
      ignoreErrors: [/\b(componentWillUpdate|setState|componentDidUpdate)\b/, 'Expired link', 'Wrong code', 'Email or password are wrong'],
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
}

