import { InfoCircleOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { Fragment, useEffect } from "react";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import Header from "./components/molecules/Header";
import Menu from "./components/organisms/Menu";
import { useAppDispatch, useAppSelector } from "./customHooks/reduxHooks";
import { getSocket } from "./socket";
import { delInfo } from "./store/slices/infoSlice";
import circleShape from "./theme/images/circleShape.png";
import purpleTriangle from "./theme/images/purpleTriangle.png";
import greenTriangle from "./theme/images/triangleShape.png";
import routes from "routes";
import ScrollToTop from "components/landingPageComponents/components/ScrollToTop";
import { queryClient } from "index";
import { envs } from "utils/envs";
import { isDevEnvironment } from "utils/general";
import { Helmet } from "react-helmet";

const version = envs.REACT_APP_VERSION;

function App() {
    const info = useAppSelector((store) => store.info.info);
    const dispatch = useAppDispatch();
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        queryClient.removeQueries();
        const appVersion = localStorage.getItem('version');
        const accessToken = localStorage.getItem('accessToken');
        if ((!appVersion && accessToken) || (version !== appVersion)) {
            localStorage.clear();
        }
        if (version) {
            localStorage.setItem('version', version);
        }
    }, []);

    const openNotification = (variant: any, index: number) => {
        api.open({
            message: (
                <span style={{ fontWeight: 600, fontSize: "14px" }}>
          {info[index]?.title}
        </span>
            ),
            description: (
                <span style={{ fontWeight: 400, fontSize: "14px" }}>
          {info[index]?.text}
        </span>
            ),
            className: `info ${variant}`,
            duration: 3,
            icon: <InfoCircleOutlined />,
            closeIcon: null,
        });
    };

    useEffect(() => {
        if (info.length > 0) {
            info.map((i, index) => openNotification(i.variant, index));
        }
    }, [info]);

    useEffect(() => {
        if (info.length > 0) {
            setTimeout(() => {
                dispatch(delInfo([]));
            }, 1500);
        }
    }, [info]);

    const router = createBrowserRouter([
        {
            path: "/",
            element: (
                <div id="publicRoot">
                    <ScrollToTop />
                    <Header />
                    <Outlet />
                </div>
            ),
            children: [
                {
                    path: routes.TermsAndCondition.path,
                    element: routes.TermsAndCondition.element,
                },
                {
                    path: routes.PatientTherapistPrivacy.path,
                    element: routes.PatientTherapistPrivacy.element,
                },
                {
                    path: routes.GeneralPrivacy.path,
                    element: routes.GeneralPrivacy.element,
                },
                {
                    path: routes.InformedConsentView.path,
                    element: routes.InformedConsentView.element,
                },
                {
                    path: routes.SurveyPrivacy.path,
                    element: routes.SurveyPrivacy.element,
                },
                {
                    path: routes.InformedConsent.path,
                    element: routes.InformedConsent.element,
                },
                {
                    path: routes.RetrievePassword.path,
                    element: routes.RetrievePassword.element,
                },
                {
                    path: routes.VerifyEmail.path,
                    element: routes.VerifyEmail.element,
                },
                {
                    path: routes.ChangePassword.path,
                    element: (
                        <Fragment>
                            <img
                                src={circleShape}
                                alt="circle shape"
                                className="circleShape"
                            />
                            <img
                                src={purpleTriangle}
                                alt="purple triangle"
                                className="purpleTriangle"
                            />
                            <img
                                src={greenTriangle}
                                alt="green triangle"
                                className="greenTriangle"
                            />
                            {routes.ChangePassword.element}
                        </Fragment>
                    ),
                },
                {
                    path: routes.Login.path,
                    element: (
                        <Fragment>
                            <img
                                src={circleShape}
                                alt="circle shape"
                                className="circleShape"
                            />
                            <img
                                src={purpleTriangle}
                                alt="purple triangle"
                                className="purpleTriangle"
                            />
                            <img
                                src={greenTriangle}
                                alt="green triangle"
                                className="greenTriangle"
                            />
                            {routes.Login.element}
                        </Fragment>
                    ),
                },
                {
                    path: routes.ConfirmTherapist.path,
                    element: routes.ConfirmTherapist.element,
                },
                {
                    path: routes.Home.path,
                    element: routes.Home.element,
                },
                {
                    path: routes.AboutUs.path,
                    element: routes.AboutUs.element,
                },
                {
                    path: routes.OurApproach.path,
                    element: routes.OurApproach.element,
                },
                {
                    path: routes.Reviews.path,
                    element: routes.Reviews.element,
                },
                {
                    path: routes.FaqPage.path,
                    element: routes.FaqPage.element,
                },
                {
                    path: routes.ContactUs.path,
                    element: routes.ContactUs.element,
                },
                {
                    path: routes.Survey.path,
                    element: (
                        <Fragment>
                            <img
                                src={purpleTriangle}
                                alt="purple triangle"
                                className="purpleTriangle"
                            />
                            <img
                                src={greenTriangle}
                                alt="green triangle"
                                className="greenTriangle"
                            />
                            {routes.Survey.element}
                        </Fragment>
                    ),
                },
                {
                    path: routes.TherapistRegister.path,
                    element: routes.TherapistRegister.element,
                },
                {
                    path: routes.SupervisedPatientRegister.path,
                    element: routes.SupervisedPatientRegister.element,
                },
                {
                    path: routes.PatientRegister.path,
                    element: routes.PatientRegister.element,
                },
                {
                    path: routes.ChooseTherapist.path,
                    element: routes.ChooseTherapist.element,
                },
                {
                    path: routes.TherapistDetail.path,
                    element: routes.TherapistDetail.element,
                },
            ],
        },
        {
            loader: async () => {
                return getSocket();
            },
            id: "root",
            path: "/dashboard",
            element: (
                <div className="authRoot">
                    <ScrollToTop />
                    <Menu closeMenu={() => {}} />
                    <Outlet />
                </div>
            ),
            children: [
                {
                    path: routes.Chat.path,
                    element: routes.Chat.element,
                },
                {
                    path: routes.Appointments.path,
                    element: routes.Appointments.element,
                },
                {
                    path: routes.Profile.path,
                    element: routes.Profile.element,
                },
                {
                    path: routes.ProfileSection.path,
                    element: routes.ProfileSection.element,
                },
                {
                    path: routes.DashboardFaq.path,
                    element: routes.DashboardFaq.element,
                },
            ],
        },
        {
            loader: async () => {
                return getSocket();
            },
            id: "firstAppointmentRoot",
            path: "/",
            element: (
              <div id="firstAppointmentRoot">
                  <ScrollToTop/>
                  <Header/>
                  <Outlet/>
              </div>
            ),
            children: [
                {
                    path: routes.PatientBookingFirstAppointment.path,
                    element: routes.PatientBookingFirstAppointment.element,
                },
            ],
        },
        {
            path: routes.WaitingRoom.path,
            element: routes.WaitingRoom.element,
        },
    ]);

    return (
        <div className="App">
            {contextHolder}
            <div
                className={
                    isDevEnvironment() ? "visibleVersionNumber" : "hiddenVersionNumber"
                }
            >
                {envs.REACT_APP_VERSION}
            </div>
            <Helmet>
                <title>Mymentis - Il futuro del benessere mentale online</title>
                <meta
                    name="description"
                    content="Il servizio di psicologia online che trasforma il supporto a distanza in un'esperienza di eccellenza."
                />
                <meta
                    property="og:title"
                    content="Mymentis - Il futuro del benessere mentale online"
                />
                <meta
                    property="og:description"
                    content="Il servizio di psicologia online che trasforma il supporto a distanza in un'esperienza di eccellenza."
                />
                {envs.REACT_APP_ENVIRONMENT === "Prod" ? (
                    <link rel="canonical" href="https://mymentis.it/" />
                ) : null}
            </Helmet>
            <RouterProvider router={router} />
        </div>
    );
}

export default App;
