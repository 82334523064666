import * as Sentry from "@sentry/react";
import { envs } from "./envs";

const environment = envs.REACT_APP_SENTRY_ENVIRONMENT;

export const raiseException = (e: any) => {
  const userId = localStorage.getItem('userId')
  if (environment !== 'development') {
    try {
      Sentry.captureMessage(e, {level: 'error',  user: {id: userId || ''}});
    } catch {}
  }
}
