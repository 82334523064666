import React, { FC } from "react";
import styles from "./Checkbox.module.scss";

interface CheckboxProps {
  className?: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
}

export const Checkbox: FC<CheckboxProps> = ({
  className,
  onChange,
  checked = false,
  name,
}) => {
  return (
    <input
      name={name}
      className={`${styles.checkbox} ${className ?? ""}`}
      type="checkbox"
      checked={checked}
      onChange={onChange}
    />
  );
};
