import { XMarkIcon } from "@heroicons/react/24/solid";
import styles from "./FormHeader.module.scss";

interface Props {
  className?: string;
  classNameCloseButton?: string;
  closeModal: () => void;
  title: string | undefined;
  closeButton?: boolean;
}

export const FormHeader = ({
  className,
  closeModal,
  title,
  closeButton,
  classNameCloseButton
}: Props) => {
  return (
    <div className={`${styles.header} ${className}`}>
      <p>{title}</p>
      {closeButton && (
        <div className={`${styles.closeBtn} ${classNameCloseButton}`} onClick={closeModal}>
          <XMarkIcon />
        </div>
      )}
    </div>
  );
};
