import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface InfoState {
  title?: string;
  text: string;
  variant?: "info" | "error" | "warn" | "confirm";
}
const initialState = {
  info: [] as InfoState[],
  stream: [] as string[],
};

const infoSlice = createSlice({
  name: "info",
  initialState,
  reducers: {
    setInfo(state, action: PayloadAction<InfoState>) {
      state.info = [...state.info, action.payload];
    },
    delInfo(state, action: PayloadAction<any>) {
      state.info = [...action.payload];
    },
    setStream(state, action: PayloadAction<string>) {
      state.stream = [...state.stream, action.payload];
    },
    delStream(state, action: PayloadAction<string>) {
      state.stream = state.stream.filter((s) => s !== action.payload);
    },
  },
});
export default infoSlice.reducer;
export const { setInfo, delInfo, setStream, delStream } = infoSlice.actions;
