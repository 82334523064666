import { StarIcon as FilledStar } from "@heroicons/react/24/solid";
import { StarIcon as EmptyStar } from "@heroicons/react/24/outline";
import styles from "./CardReview.module.scss";
import Avatar from "../../atoms/Avatar";
import DocAvatar from "../../../theme/images/docAvatar.png";

interface Props {
  className?: string;
  vote: number;
  reviewTitle: string;
  reviewText: string;
}

export const CardReview = ({
  className = "",
  vote,
  reviewTitle,
  reviewText,
}: Props) => {
  return (
    <div className={`${styles.cardReview} ${className}`}>
      <div className={styles.topWrapper}>
        <Avatar className={styles.avatar} avatarImg={DocAvatar} />
        <div className={styles.titleStarWrapper}>
          <div className={styles.starVote}>
            {Array(5)
              .fill(<FilledStar />)
              .map((i: any, index: number) => {
                if (index >= vote) {
                  return <EmptyStar key={index}/>;
                }
                return <FilledStar key={index}/>;
              })}
          </div>
          <div className={styles.reviewTitle}>{reviewTitle}</div>
        </div>
      </div>
      <div className={styles.reviewText}>{reviewText}</div>
    </div>
  );
};
