export const getAccessToken = () => {
  // let accessToken = document.cookie
  //   .split(";")
  //   .find((i) => i.includes("accessToken"));

  // const indexFromCut = accessToken?.indexOf("=")! + 1;
  // accessToken = accessToken?.substring(indexFromCut!);
  const accessToken = localStorage.getItem("accessToken");
  return accessToken;
};
