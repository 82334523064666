const priceWithDiscount = async (newPrice: number, therapyType: string, vatNumberType: string) => {
  const transferAmount = therapyType === 'other' ? 4000 : 5000;

  let finalAmount: number;

  if (vatNumberType === 'ordinaria') {
    const amountWithoutEnpap = Math.ceil(transferAmount / 1.02);
    finalAmount = transferAmount - Math.floor(amountWithoutEnpap * 0.2);
  } else {
    finalAmount = transferAmount;
  }

  const transferToTherapistValue = finalAmount > newPrice ? newPrice : finalAmount;

  let additionalTransferToTherapist = 0;

  if ((finalAmount - newPrice) > 0) {
    additionalTransferToTherapist = finalAmount - newPrice;
  }

  return { patientPayValue: newPrice, transferToTherapistValue , additionalTransferToTherapist }
}

export default priceWithDiscount;
