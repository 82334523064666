import IntroductorySection from "../../components/landingPageComponents/sections/IntroductorySection";
import ImprovedQualityOfLife from "../../components/landingPageComponents/sections/ImprovedQualityOfLife";
import Footer from "../../components/landingPageComponents/components/Footer";
import { IntroductoryAboutUsContent } from "./OurApproachText";
import { OurApproachStains } from "./OurApproachText/index";
import styles from "./OurApproach.module.scss";
import { Helmet } from "react-helmet";
import { envs } from "utils/envs";
import routes from "routes";
import { analyticsService } from "../../utils/analyticsService";
import { useEffect } from "react";
export const OurApproach = () => {

  useEffect(() => {
    analyticsService({'event': 'virtualPageview', 'pageUrl': '/il-nostro-approccio', 'pageTitle': 'Our Approach'});
  }, []);

  return (
    <main>
      <Helmet>
        <title>Il nostro approccio</title>
        <meta
          name="description"
          content="I nostri professionisti sono pronti ad ascoltarti e a comprendere le tue necessità con profonda empatia e rispetto, creando uno spazio sicuro in cui potrai esprimerti liberamente e condividere i tuoi pensieri e le tue emozioni. Per noi è fondamentale farti sentire a tuo agio, senza il timore di essere giudicato."
        />
        <meta property="og:title" content="Il nostro approccio" />
        <meta
          property="og:description"
          content="I nostri professionisti sono pronti ad ascoltarti e a comprendere le tue necessità con profonda empatia e rispetto, creando uno spazio sicuro in cui potrai esprimerti liberamente e condividere i tuoi pensieri e le tue emozioni. Per noi è fondamentale farti sentire a tuo agio, senza il timore di essere giudicato."
        />
        {envs.REACT_APP_ENVIRONMENT === "Prod" ? (
          <link
            rel="canonical"
            href={`https://mymentis.it${routes.OurApproach.path}`}
          />
        ) : null}
      </Helmet>
      <div className={styles.ourApproach}>
        <IntroductorySection
          content={IntroductoryAboutUsContent}
          sectionStains={OurApproachStains.introductorySection.sectionStains}
          hasLabel
        />
        <ImprovedQualityOfLife
          imageStains={OurApproachStains.ImprovedQualityOfLife.imageStains}
        />
        <Footer />
      </div>
    </main>
  );
};
