import styles from "./InfoBox.module.scss";
import { InfoCircleOutlined } from "@ant-design/icons";
import { XMarkIcon } from "@heroicons/react/24/solid";

interface Props {
  className?: string;
  variant?: "warn" | "info" | "confirm" | "error" | "infoPayment";
  title?: string | JSX.Element;
  text: string | JSX.Element;
  closeModal?: () => void;
  closeButton?: boolean;
  onClick?: () => void;
}

export const InfoBox = ({
  className,
  variant = "info",
  title,
  text,
  closeModal,
  closeButton,
  onClick,
}: Props) => {
  return (
    <div className={`${styles.info} ${styles[variant]} ${className}`}>
      <div className={`${styles.infoWrapper} ${title ? "" : styles.withTitle}`}>
        <div className={closeButton ? styles.infoTitleClose : styles.infoTitle}>
          <div>
            <InfoCircleOutlined/> {title}</div>
          {closeButton && (
            <div className={styles.closeBtn} onClick={closeModal}>
              <XMarkIcon className={styles.closeIcon}/>
            </div>
          )}
        </div>
        <div
          onClick={onClick}
          className={`${styles.infoMessage} ${
            onClick ? styles.infoMessageClickable : ""
          }`}
        >
          {text}
        </div>
      </div>
    </div>
  );
};
