import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import Button from "components/atoms/Button";
import FormHeader from "components/atoms/FormHeader";
import Input from "components/atoms/Input";
import { Dispatch, FC, useEffect, useRef, useState } from "react";
import styles from "./Faq.module.scss";
import Modal from "components/organisms/Modal";
import { trpc } from "utils/trpc";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { setInfo } from "store/slices/infoSlice";
import { useAppDispatch } from "customHooks/reduxHooks";
import { analyticsService } from "../../utils/analyticsService";
import { raiseException } from "../../utils/raiseException";

const therapistContactReasons = [
  "Sono un terapeuta e vorrei aprire una segnalazione",
  // "Per eliminare il profilo è necessario chiedere al Supporto.",
  "Altro",
];
const patientContactReasons = [
  "Ho bisogno di assistenza per problemi relativi a pagamenti o fatturazione",
  "Ho bisogno di assistenza per problemi tecnici",
  "Ho bisogno di assistenza per ottenere dei chiarimenti riguardo ai servizi che offrite",
  "Ho bisogno di assistenza per ottenere dettagli sul processo di utilizzo dei servizi",
  "Vorrei lasciare feedback e/o condividere suggerimenti",
  "Vorrei presentare un reclamo",
  "Altro",
];

const ContactUsForm = ({
  setModalIsOpen,
}: {
  setModalIsOpen?: Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [reason, setReason] = useState("");
  const [content, setContent] = useState("");
  const dispatch = useAppDispatch();

  const getCurrentUserInfoResponse =
    trpc.mainService.user.getCurrentUserInfo.useQuery();

  const requestSupport = trpc.mainService.requestSupport.useMutation();

  return (
    <div className={styles.contactUs}>
      {requestSupport.isLoading && (
        <Modal className="loadingModal">
          <Spin
            indicator={
              <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
            }
          />
        </Modal>
      )}
      {getCurrentUserInfoResponse.data && (
        <div className={styles.inputContainer}>
          <Input
            select
            options={
              getCurrentUserInfoResponse.data.user.role === "therapist"
                ? therapistContactReasons.map((reason) => ({
                    value: reason,
                    name: reason,
                  }))
                : patientContactReasons.map((reason) => ({
                    value: reason,
                    name: reason,
                  }))
            }
            onChange={(e) => {
              setReason(e.target.value);
            }}
          />
          <Input
            textarea
            value={content}
            onChange={(e) => {
              setContent(e.target.value);
            }}
          />
          <Button
            disabled={!content}
            onClick={async () => {
              try {
                await requestSupport.mutateAsync({
                  givenName: getCurrentUserInfoResponse.data?.user.given_name!,
                  familyName:
                    getCurrentUserInfoResponse.data?.user.family_name!,
                  email: getCurrentUserInfoResponse.data?.user.email!,
                  reason,
                  content,
                  fromWhere:
                    getCurrentUserInfoResponse.data?.user?.role === "therapist"
                      ? "therapistDashboard"
                      : "patientDashboard",
                });
                setContent("");
                if (setModalIsOpen) {
                  setModalIsOpen(false);
                }
                dispatch(
                  setInfo({
                    variant: "confirm",
                    text: "Richiesta di supporto inviata con successo",
                  })
                );
              } catch (e: any) {
                raiseException(e);
                console.error("ERROR", e?.shape?.message);
                dispatch(
                  setInfo({
                    variant: "error",
                    text: "Si è verificato un errore durante l'invio della richiesta di supporto",
                  })
                );
              }
            }}
          >
            Invia richiesta
          </Button>
        </div>
      )}
    </div>
  );
};

export const Faq = () => {
  const contactUsRef = useRef<any>(null);

  const [actaualQuestion, setActualQuestion] = useState("");
  const [actaualAnswer, setActualAnswer] = useState<any>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    analyticsService({ 'event': 'virtualPageview', 'pageUrl': '/dashboard/faq', 'pageTitle': 'Dashboard Faq' });
  }, []);

  const faq = [
    {
      question: "Cos’è Mymentis e come funziona?",
      answer:
        "Mymentis è una startup che offre un servizio di supporto psicologico e di psicoterapia online. Ti aiutiamo a trovare il professionista adatto a te, sulla base delle tue esigenze e preferenze, selezionandolo tra centinaia di profili diversi. Gli psicologi/psicoterapeuti non sono tutti uguali:  esistono aree di intervento diverse in cui un professionista del settore può essere specializzato ed esistono anche tipi diversi di psicoterapia, che possono essere più o meno adatti a seconda del tipo di necessità che ha la persona che vuole affrontare un percorso di terapeutico.   Se vuoi trovare il professionista che fa al caso tuo, ti basterà compilare il nostro questionario (gratuitamente) e rispondere alle domande con onestà (tali dati sono protetti…). Il sistema di matching di Mymentis, sulla base di quello che emerge dalle tue risposte, selezionerà il terapeuta più adatto alle tue esigenze. Successivamente, con il professionista scelto per te potrai fissare ed effettuare il primo colloquio gratuito. Dopo aver effettuato il primo colloquio, se lo desideri, potrai proseguire con il percorso senza alcun vincolo e fissare con il tuo terapeuta la seduta successiva.",
    },
    {
      question: "Qual è il prezzo di una seduta di Mymentis?",
      answer: (
        <div>
          <p>
            Il prezzo per ogni seduta individuale (adulti - minori) è di 50
            euro.
          </p>
          <p>Il prezzo per ogni seduta di coppia è di 60 euro.</p>
          <br />
          <p>
            In Mymentis riteniamo che la fiducia e la trasparenza siano la base
            della nostra relazione con te. Attraverso questa fiducia e
            trasparenza speriamo di costruire una connessione genuina che
            favorisca il tuo benessere. Per tale motivazione ci impegniamo a
            condividere con te ogni informazione rilevante e promettiamo di
            essere trasparenti. Il costo di ogni seduta è fisso e senza
            sorprese. Non avrai alcun vincolo, potrai interrompere in qualsiasi
            momento desideri. -La compilazione del questionario, il servizio di
            matching che consente di trovare il terapeuta giusto e il primo
            incontro conoscitivo sono gratuiti e senza impegno
          </p>
        </div>
      ),
    },
    {
      question: "Come si pagano le sedute? ",
      answer: (
        <div>
          <p>
            Potrai pagare le tue sedute con il massimo della sicurezza dal tuo
            account sulla Web-app di Mymentis, tramite il sistema di pagamenti
            Stripe. Tra i pagamenti accettati da Stripe rientrano tutte le
            principali carte di credito e di debito (ricaricabili) e bancomat
            con Visa, Mastercard, Maestro, American Express, PostePay ed Apple
            Pay.
          </p>
          <br />
          <p>Dove?</p>
          <ol>
            <li>
              Dalla sezione “Chat” cliccando il tasto “Procedi al pagamento”che
              trovi all’interno del box dell’appuntamento concordato con il
              terapeuta dopo che hai confermato l’orario e il giorno
            </li>
            <li>
              Dalla sezione “Appuntamenti” cliccando il tasto “Procedi al
              pagamento” relativo all’appuntamento di interesse. Ricorda: È
              necessario chiedere l’annullamento dell’appuntamento fino a 24 ore
              prima per poter ottenere il rimborso completo del pagamento.
            </li>
          </ol>
          Le transazioni sono interamente gestite dal sistema Stripe, che
          garantisce la massima sicurezza. Potrai effettuare i pagamenti e, se
          desideri, salvare il metodo di pagamento inserendo e salvando i dati
          della carta in totale sicurezza. In questo caso i pagamenti delle
          sedute successive avverranno in automatico, attraverso il metodo che
          hai precedentemente salvato, 24 ore prima dell’orario previsto
          dell’appuntamento. Se hai problemi con i pagamenti puoi rivolgerti al
          nostro{" "}
          <p
            onClick={() => {
              contactUsRef.current &&
                contactUsRef.current.scrollIntoView({
                  inline: "end",
                  block: "end",
                });
              // contactUsRef.current.setAttribute(
              //   "style",
              //   "box-shadow: 0 0 8px 8px #fff, 0 0 10px 10px #7595e0, 0 0 10px 10px #7595e0;"
              // );
              // setTimeout(() => {
              //   contactUsRef.current.removeAttribute("style");
              // }, 800);
            }}
            style={{
              textDecoration: "underline",
              display: "inline",
              cursor: "pointer",
            }}
          >
            servizio assistenza
          </p>{" "}
          o all’email{" "}
          <a href="mailto:clientsupport@mymentis.it">
            clientsupport@mymentis.it
          </a>
          . Se vuoi rimuovere o cambiare il metodo di pagamento che avevi
          precedentemente salvato, accedi al tuo account sulla Web-app, entra
          nella sezione “Profilo” e infine nella sezione "Metodi di pagamento".
        </div>
      ),
    },
    {
      question: "Come faccio a inserire o a cambiare il metodo di pagamento?",
      answer: (
        <div>
          <p>
            Se vuoi inserire, salvare, cambiare o rimuovere i dati della tua
            carta o il metodo di pagamento, puoi farlo dal tuo account sulla
            Web-app, entrando nella sezione “Profilo” del menù a tendina sulla
            sinistra e infine selezionando la sezione "Metodo di pagamento". Il
            sistema Stripe si occuperà della gestione delle transazioni,
            garantendoti la massima sicurezza. Se hai problemi puoi rivolgerti
            al nostro{" "}
            <p
              onClick={() => {
                contactUsRef.current &&
                  contactUsRef.current.scrollIntoView({
                    inline: "end",
                    block: "end",
                  });
                // contactUsRef.current.setAttribute(
                //   "style",
                //   "box-shadow: 0 0 8px 8px #fff, 0 0 10px 10px #7595e0, 0 0 10px 10px #7595e0;"
                // );
                // setTimeout(() => {
                //   contactUsRef.current.removeAttribute("style");
                // }, 800);
              }}
              style={{
                textDecoration: "underline",
                display: "inline",
                cursor: "pointer",
              }}
            >
              servizio assistenza
            </p>{" "}
            o all’email{" "}
            <a href="mailto:clientsupport@mymentis.it">
              clientsupport@mymentis.it
            </a>
            .
          </p>
        </div>
      ),
    },
    {
      question:
        "Posso detrarre dalle tasse le spese delle sedute che faccio con gli psicologi di Mymentis?",
      answer:
        "Tutte le spese mediche, comprese le sedute di psicoterapia che farai con il tuo terapeuta Mymentis, possono essere detratte dalla dichiarazione dei redditi se accompagnate da fattura, quest’ultima però deve essere richiesta esplicitamente. Per ottenerla è sufficiente accedere al proprio “Profilo“, andare nella sezione “Metodo di pagamento”, inserire i “Dati di fatturazione” e acconsentire alla condivisione delle informazioni richieste.  Tutte le fatture, se richieste, ti saranno inviate sulla tua email, una volta effettuato il pagamento della seduta ed entro le 24 ore dopo. Altrimenti puoi trovarle e scaricarle alla sezione “Appuntamenti”, “Passati”, della tua area personale.",
    },
  ];
  return (
    <div className={styles.faqContainer}>
      {modalIsOpen && (
        <Modal>
          <div className={styles.contactUsForm}>
            <FormHeader
              closeModal={() => setModalIsOpen(false)}
              title="Contattaci"
              closeButton
            />
            <ContactUsForm setModalIsOpen={setModalIsOpen} />
          </div>
        </Modal>
      )}
      {!actaualAnswer && actaualQuestion.trim() === "" ? (
        <div className={styles.faq}>
          <h4 className={styles.title}>Come possiamo aiutarti?</h4>
          <div className={styles.faqContainer}>
            <h6 className={styles.subtitle}>Domande frequenti</h6>
            <div className={styles.faqList}>
              {faq.map((q, index) => (
                <Button
                  key={index}
                  variant="tertiary"
                  rightIcon={<ChevronRightIcon />}
                  onClick={() => {
                    const question = faq[index].question;
                    const answer = faq[index].answer;
                    setActualQuestion(question);
                    setActualAnswer(answer);
                  }}
                >
                  {q.question}
                </Button>
              ))}
            </div>
          </div>
          <div>
            <h6 className={styles.subtitle}>
              Non hai trovato quello che cercavi? Contattaci
            </h6>
            <ContactUsForm />
          </div>
        </div>
      ) : (
        <div className={styles.faqAnswer}>
          <Button
            leftIcon={<ChevronLeftIcon />}
            variant="tertiary"
            className={styles.backBtn}
            onClick={() => {
              setActualQuestion("");
              setActualAnswer("");
            }}
          >
            Torna alle Domande Frequenti
          </Button>
          <div className={styles.answerContainer}>
            <h5 className={styles.actualQuestion}>{actaualQuestion}</h5>
            <div className={styles.actaualAnswer}>{actaualAnswer}</div>
          </div>
          <div className={styles.contactUsInfoBox} ref={contactUsRef}>
            <h6>Non hai trovato quello che cercavi?</h6>
            <Button onClick={() => setModalIsOpen(true)}>Contattaci</Button>
          </div>
        </div>
      )}
    </div>
  );
};
