import { io } from "socket.io-client";
import { getToken } from "./utils/getToken";
import { envs } from "utils/envs";

const URL = envs.REACT_APP_SERVER_URL;

export const getSocket = () =>
  io(URL, {
    auth: {
      token: getToken("accessToken"),
    },
  });
