import { Pagination } from "antd";
import CardReview from "components/molecules/CardReview";
import { useEffect, useState } from "react";
import { trpc } from "utils/trpc";
import styles from "./TherapistReview.module.scss";
import { analyticsService } from "../../../utils/analyticsService";

export const TherapistReview = () => {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    analyticsService({
      'event': 'virtualPageview',
      'pageUrl': '/dashboard/profilo/recensioni',
      'pageTitle': 'Therapist Reviews'
    });
  }, []);

  const getCurrentUserInfoResponse =
    trpc.mainService.user.getCurrentUserInfo.useQuery();
  const getReviewsResponse = trpc.mainService.review.getReviews.useQuery(
    { therapistId: getCurrentUserInfoResponse.data?.user.id },
    { enabled: Boolean(getCurrentUserInfoResponse.data?.user.id) }
  );

  return (
    <div className={styles.therapistReview}>
      <h1 className={styles.title}>Recensioni ricevute</h1>
      <div className={styles.reviewContainer}>
        {getReviewsResponse.data &&
          getReviewsResponse.data.reviews
            .slice((currentPage - 1) * 6, currentPage * 6)
            .map((review, key) => (
              <CardReview
                className={styles.reviewCard}
                key={key}
                reviewText={review.content}
                reviewTitle={review.title}
                vote={review.rating}
              />
            ))}

        {/* <Carousel className={styles.reviewsCarousel}>
          {reviews.map((r, index) => {
            if (index % 6 === 0) {
              return (
                <div className={styles.cardWrapper}>
                  <div className={styles.cardsRow}>
                    <CardReview
                      className={styles.reviewCard}
                      key={index}
                      reviewText={r.content}
                      reviewTitle={r.content}
                      vote={r.rating}
                    />
                    {reviews[index + 1] && (
                      <CardReview
                        key={index + 1}
                        className={styles.reviewCard}
                        reviewText={reviews[index + 1].content}
                        reviewTitle={reviews[index + 1].content}
                        vote={reviews[index + 1].rating}
                      />
                    )}
                  </div>
                  <div className={styles.cardsRow}>
                    {reviews[index + 2] && (
                      <CardReview
                        key={index + 2}
                        className={styles.reviewCard}
                        reviewText={reviews[index + 2].content}
                        reviewTitle={reviews[index + 2].content}
                        vote={reviews[index + 2].rating}
                      />
                    )}
                    {reviews[index + 3] && (
                      <CardReview
                        key={index + 3}
                        className={styles.reviewCard}
                        reviewText={reviews[index + 3].content}
                        reviewTitle={reviews[index + 3].content}
                        vote={reviews[index + 3].rating}
                      />
                    )}
                  </div>
                  <div className={styles.cardsRow}>
                    {reviews[index + 4] && (
                      <CardReview
                        key={index + 4}
                        className={styles.reviewCard}
                        reviewText={reviews[index + 4].content}
                        reviewTitle={reviews[index + 4].content}
                        vote={reviews[index + 4].rating}
                      />
                    )}
                    {reviews[index + 5] && (
                      <CardReview
                        key={index + 5}
                        className={styles.reviewCard}
                        reviewText={reviews[index + 5].content}
                        reviewTitle={reviews[index + 5].content}
                        vote={reviews[index + 5].rating}
                      />
                    )}
                  </div>
                </div>
              );
            }
          })}
        </Carousel> */}
      </div>
      {getReviewsResponse.data && (
        <div className={styles.paginationContainer}>
          <Pagination
            className={styles.pagination}
            current={currentPage}
            total={Math.ceil(getReviewsResponse.data.reviews.length / 6) * 10}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
      )}
    </div>
  );
};
