import {
  MicrophoneIcon,
  VideoCameraIcon,
  VideoCameraSlashIcon,
  ViewfinderCircleIcon,
} from "@heroicons/react/24/solid";
import OT, { AudioOutputDevice, Publisher } from "@opentok/client";
import VideoCallButton from "components/atoms/VideoCallButton";
import GenericForm from "components/molecules/GenericForm";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { envs } from "utils/envs";
import logo from "../../theme/images/logo.png";
import { trpc } from "../../utils/trpc";
import VideoCallRoom from "../VideoCallRoom";
import Button from "../atoms/Button";
import styles from "./WaitingRoom.module.scss";
import routes from "routes";
import Modal from "components/organisms/Modal";
import FormHeader from "components/atoms/FormHeader";
import { SpeakerXMarkIcon } from "@heroicons/react/24/outline";
import { analyticsService } from "../../utils/analyticsService";
import { raiseException } from "../../utils/raiseException";

interface Credentials {
  apiKey: string | undefined;
  sessionId: string;
  token: string;
}

export const WaitingRoom = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { appointmentId } = params;

  const [audioSource, setAudioSource] = useState<string>();
  const [settingModalIsOpen, setSettingModalIsOpen] = useState(false);
  const [permissionControl, setPermissionControll] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [session, setsession] = useState<OT.Session>();
  const [getCredentialsToggle, setGetCredentialsToggle] = useState(true);
  const [previewPublisher, setPreviewPublisher] = useState<OT.Publisher>();
  const [pubOptions, setPubOptions] = useState<OT.PublisherProperties>({
    publishAudio: true,
    publishVideo: true,
    videoFilter: undefined,
    // audioSource: "default",
    // videoSource: "",
  });
  const [callIsStarted, setCallIsStarted] = useState(false);
  const [outputAudioDeviceList, setOutputAudioDeviceList] = useState<
    AudioOutputDevice[]
  >([]);
  const [inputAudioDeviceList, setInputAudioDeviceList] = useState<
    AudioOutputDevice[]
  >([]);
  const [inputVideoDeviceList, setInputVideoDeviceList] = useState<
    AudioOutputDevice[]
  >([]);

  const publisherPreview = useRef<null | HTMLDivElement>(null);
  const getCredentials =
    trpc.mainService.appointment.getVideoCallCredentials.useMutation();

  const updateUserFirstEntrance= trpc.mainService.appointment.updateUserFirstEntrance.useMutation();

  useEffect(() => {
    analyticsService({'event': 'virtualPageview', 'pageUrl': `/seduta/`, 'pageTitle': 'Waiting Room'});
  }, []);

  useEffect(() => {
    // create publisher
    const main = async () => {
      try {
        const credentials = await getCredentials.mutateAsync({
          appointmentId: appointmentId!,
        });
        const { appointmentVideoCallCredentials } = credentials;
        const { videoCallSessionId, videoCallToken } =
          appointmentVideoCallCredentials;
        setsession(
          OT.initSession(envs.REACT_APP_VONAGE_API_KEY!, videoCallSessionId!)
        );
        setToken(videoCallToken);
      } catch (e: any) {
        raiseException(e)
        console.error(e);
      }
    };
    main();
  }, []);

  useEffect(() => {
    if (getCredentials.isSuccess) {
      setPreviewPublisher(
        OT.initPublisher(
          "previewPublisher",
          {
            insertMode: "replace",
            width: "100%",
            height: "100%",
            style: {
              buttonDisplayMode: "off",
            },
            // ...pubOptions,
          },
          (err) => {
            if (err) {
              if (err.name === "OT_USER_MEDIA_ACCESS_DENIED") {
                setPermissionControll(false);
                alert(
                  "Non hai dato al browser il permesso di utilizzare webcam e microfono, concedi le autorizazioni e ricarica la pagina per continuare"
                );
              } else {
                alert(err.message);
              }
            }
            setPermissionControll(true);
          }
        )
      );
    }
  }, [getCredentials.data]);

  useEffect(() => {
    const OTGetDevices = (): Promise<OT.Device[] | undefined> =>
      new Promise((resolve, reject) => {
        OT.getDevices((error, devices) => {
          if (error) {
            return reject(error);
          }
          resolve(devices);
        });
      });

    const getDevices = async () => {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        console.warn("enumerateDevices() not supported.");
        return;
      }
      try {
        const devices = await OTGetDevices();
        let audioOutputDevices = await OT.getAudioOutputDevices();
        audioOutputDevices = audioOutputDevices.map((audiooutput) =>
          audiooutput.deviceId === "default"
            ? { ...audiooutput, label: "System Default" }
            : audiooutput
        );
        const audioInputDevices = devices?.filter(
          (d) => d.kind.toLowerCase() === "audioinput"
        );
        const videoInputDevices = devices?.filter(
          (d) => d.kind.toLowerCase() === "videoinput"
        );
        // setDeviceInfo({
        //   audioInputDevices,
        //   videoInputDevices,
        //   audioOutputDevices,
        // });
        setOutputAudioDeviceList(audioOutputDevices);
        audioInputDevices && setInputAudioDeviceList(audioInputDevices);
        videoInputDevices && setInputVideoDeviceList(videoInputDevices);
      } catch (err: any) {
        raiseException(err);
        console.log("[loadDevices] - ", err);
      }
    };

    getDevices();

    // OT.getAudioOutputDevices().then((audioDevice) => {
    //   if (
    //     (audioDevice.length < 1 && audioDeviceList.length < 1,
    //     permissionControl)
    //   ) {
    //     navigator.mediaDevices.enumerateDevices().then((deviceData) => {
    //       setAudioDeviceList(deviceData);
    //     });
    //   } else if (audioDeviceList.length < 1) {
    //     setAudioDeviceList(audioDevice);
    //   }
    // });
  }, [permissionControl]);

  return callIsStarted && session && token ? (
    <VideoCallRoom
      audioSource={audioSource!}
      outputAudioDeviceList={outputAudioDeviceList}
      inputAudioDeviceList={inputAudioDeviceList}
      inputVideoDeviceList={inputVideoDeviceList}
      appointmentId={appointmentId!}
      token={token}
      session={session}
      pubOptions={pubOptions}
    />
  ) : (
    <div className={styles.waitingRoom}>
      {settingModalIsOpen && (
        <Modal>
          <GenericForm
            className={styles.settingModal}
            title={
              <FormHeader
                closeModal={() => setSettingModalIsOpen(false)}
                title="Impostazioni"
                closeButton
              />
            }
            description="Di solito il nostro sistema riconosce automaticamente gli asset di default, ma se vuoi, puoi modificarli!"
            cta={[
              <div className={styles.selectInput}>
                <span>Videocamera</span>
                <select
                  value={pubOptions.videoSource as string}
                  className={styles.videoCallSelect}
                  onChange={(e) => {
                    e.preventDefault();
                    // OT.setAudioOutputDevice(e.target.value);
                    setPubOptions({
                      ...pubOptions,
                      videoSource: e.target.value,
                    });
                    previewPublisher?.setVideoSource(e.target.value);
                  }}
                >
                  {inputVideoDeviceList?.map((i, index) => {
                    return (
                      <option key={index} value={i.deviceId!}>
                        {i.label}
                      </option>
                    );
                  })}
                </select>
              </div>,
              <div className={styles.selectInput}>
                <span>Microfono</span>
                <select
                  value={pubOptions.audioSource as string}
                  className={styles.videoCallSelect}
                  onChange={(e) => {
                    e.preventDefault();
                    // OT.setAudioOutputDevice(e.target.value);
                    setPubOptions({
                      ...pubOptions,
                      audioSource: e.target.value,
                    });
                    previewPublisher?.setAudioSource(e.target.value);
                  }}
                >
                  {inputAudioDeviceList?.map((i, index) => (
                    <option key={index} value={i.deviceId!}>
                      {i.label}
                    </option>
                  ))}
                </select>
              </div>,
              <div className={styles.selectInput}>
                <span>Altoparlante</span>
                <select
                  value={audioSource}
                  className={styles.videoCallSelect}
                  onChange={(e) => {
                    e.preventDefault();
                    OT.setAudioOutputDevice(e.target.value);
                    setAudioSource(e.target.value);
                  }}
                >
                  {outputAudioDeviceList?.map((i, index) => (
                    <option key={index} value={i.deviceId!}>
                      {i.label}
                    </option>
                  ))}
                </select>
              </div>,
            ]}
          />
        </Modal>
      )}
      <div className={styles.logoContainer}>
        <img className={styles.logo} src={logo} alt="logo" />
      </div>
      {getCredentials.isError ? (
        getCredentials?.error?.data?.httpStatus === 401 ? null : (
          <GenericForm
            className={styles.errorForm}
            title={(() => {
              if (
                getCredentials?.error?.shape?.errorCode ===
                "AppointmentNotStarted"
              ) {
                return "La seduta non è ancora iniziata";
              } else if (
                getCredentials?.error?.shape?.errorCode ===
                "AppointmentNotFound"
              ) {
                return "Seduta non trovata";
              } else if (
                getCredentials?.error?.shape?.errorCode ===
                "AppointmentCancelled"
              ) {
                return "La seduta è stata cancellata";
              } else if (
                getCredentials?.error?.shape?.errorCode === "AppointmentExpired"
              ) {
                return "Seduta non disponibile";
              } else if (
                getCredentials?.error?.shape?.errorCode === "AppointmentMoved"
              ) {
                return "La seduta è stata spostata";
              } else if (
                getCredentials?.error?.shape?.errorCode === "AppointmentEnded"
              ) {
                return "La seduta è terminata";
              } else if (
                getCredentials?.error?.shape?.errorCode ===
                "InformedConsentMissing"
              ) {
                return "Consenso informato non compilato";
              } else {
                return "Errore inaspettato";
              }
            })()}
            description={(() => {
              if (
                getCredentials?.error?.shape?.errorCode ===
                "AppointmentNotStarted"
              ) {
                return "Controlla l'orario del tuo appuntamento per collegarti all'orario concordato.";
              } else if (
                getCredentials?.error?.shape?.errorCode ===
                "AppointmentNotFound"
              ) {
                return "Ops, sembra che questa non sia la tua seduta!";
              } else if (
                getCredentials?.error?.shape?.errorCode ===
                "AppointmentCancelled"
              ) {
                return (
                  <span>
                    Questa seduta è stata cancellata. Chiudi la pagina e ritorna
                    alla tua{" "}
                    <span
                      onClick={() => navigate(routes.Chat.path)}
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                    >
                      area privata
                    </span>
                  </span>
                );
              } else if (
                getCredentials?.error?.shape?.errorCode === "AppointmentExpired"
              ) {
                return (
                  <span>
                    Questa seduta non è stata confermata entro il tempo limite.
                    Chiudi la pagina e ritorna alla tua{" "}
                    <span
                      onClick={() => navigate(routes.Chat.path)}
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                    >
                      area privata
                    </span>
                  </span>
                );
              } else if (
                getCredentials?.error?.shape?.errorCode === "AppointmentMoved"
              ) {
                return (
                  <span>
                    Questa seduta è stata spostata. Chiudi la pagina e ritorna
                    alla tua{" "}
                    <span
                      onClick={() => navigate(routes.Chat.path)}
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                    >
                      area privata
                    </span>
                  </span>
                );
              } else if (
                getCredentials?.error?.shape?.errorCode === "AppointmentEnded"
              ) {
                return (
                  <span>
                    Questa video seduta è terminata. Torna alla tua{" "}
                    <span
                      onClick={() => navigate(routes.Chat.path)}
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                    >
                      area privata
                    </span>{" "}
                    per visualizzare le sedute future
                  </span>
                );
              } else if (
                getCredentials?.error?.shape?.errorCode ===
                "InformedConsentMissing"
              ) {
                if (getCredentials?.error?.message === "other-parent") {
                  return (
                    <span>
                      Per poter partecipare alle sedute il consenso deve essere
                      firmato anche dall'altro genitore, tramite il link
                      ricevuto sulla sua casella postale. Se non hai ricevuto il
                      link, puoi richiederne un altro tramite{" "}
                      <span
                        onClick={() =>
                          navigate(routes.ProfileSection.links.preferences)
                        }
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        questa pagina.
                      </span>
                    </span>
                  );
                } else {
                  return (
                    <span>
                      Per poter continuare con la seduta devi prima compilare il{" "}
                      <span
                        onClick={() =>
                          navigate(routes.ProfileSection.links.preferences)
                        }
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        consenso informato
                      </span>
                    </span>
                  );
                }
              } else {
                return "Errore inaspettato";
              }
            })()}
          />
        )
      ) : (
        getCredentials.isSuccess && (
          <div className={styles.card}>
            <div className={styles.headerCard}>
              <h5 className={styles.title}>Tutto pronto?</h5>{" "}
              <span onClick={() => setSettingModalIsOpen(true)}>
                Impostazioni <span>videochiamata</span>
              </span>
            </div>
            <div className={styles.previewWrapper}>
              <div
                className={styles.publisher}
                ref={publisherPreview}
                id="previewPublisher"
              ></div>

              <div className={styles.optionWrapper}>
                <VideoCallButton
                  text="Camera"
                  subtext={
                    pubOptions.publishVideo
                      ? "La camera è attiva"
                      : "La camera è spenta"
                  }
                  icon={
                    pubOptions.publishVideo ? (
                      <VideoCameraIcon />
                    ) : (
                      <VideoCameraSlashIcon />
                    )
                  }
                  onClick={() => {
                    previewPublisher?.publishVideo(!pubOptions.publishVideo);
                    setPubOptions({
                      ...pubOptions,
                      publishVideo: !pubOptions.publishVideo,
                    });
                  }}
                />
                <VideoCallButton
                  data-test="audioMenage"
                  icon={
                    pubOptions.publishAudio ? (
                      <MicrophoneIcon />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={1.5}
                        view-box="0 0 24 24"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 18.75C13.5913 18.75 15.1174 18.1179 16.2426 16.9926C17.3679 15.8674 18 14.3413 18 12.75V11.25M12 18.75C10.4087 18.75 8.88258 18.1179 7.75736 16.9926C6.63214 15.8674 6 14.3413 6 12.75V11.25M12 18.75V22.5M8.25 22.5H15.75M12 15.75C11.2044 15.75 10.4413 15.4339 9.87868 14.8713C9.31607 14.3087 9 13.5456 9 12.75V4.5C9 3.70435 9.31607 2.94129 9.87868 2.37868C10.4413 1.81607 11.2044 1.5 12 1.5C12.7956 1.5 13.5587 1.81607 14.1213 2.37868C14.6839 2.94129 15 3.70435 15 4.5V12.75C15 13.5456 14.6839 14.3087 14.1213 14.8713C13.5587 15.4339 12.7956 15.75 12 15.75Z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19 19L17.591 17.591L5.409 5.409L4 4"
                          strokeWidth={3.5}
                          color="#dfeaff"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19 19L17.591 17.591L5.409 5.409L4 4"
                        />
                      </svg>
                    )
                  }
                  text="Microfono"
                  subtext={
                    pubOptions.publishAudio
                      ? "Il microfono è attivo"
                      : "Il microfono è spento"
                  }
                  onClick={() => {
                    previewPublisher?.publishAudio(!pubOptions.publishAudio);
                    setPubOptions({
                      ...pubOptions,
                      publishAudio: !pubOptions.publishAudio,
                    });
                  }}
                />
                {navigator.userAgent.includes("Chrome") && (
                  <VideoCallButton
                    onClick={() => {
                      if (previewPublisher?.getVideoFilter() === null) {
                        previewPublisher.applyVideoFilter({
                          blurStrength: "high",
                          type: "backgroundBlur",
                        });
                        setPubOptions({
                          ...pubOptions,
                          videoFilter: {
                            blurStrength: "high",
                            type: "backgroundBlur",
                          },
                        });
                      } else {
                        previewPublisher?.clearVideoFilter();
                        setPubOptions({
                          ...pubOptions,
                          videoFilter: undefined,
                        });
                      }
                    }}
                    icon={<ViewfinderCircleIcon />}
                    text="Filtro"
                    subtext={
                      pubOptions.videoFilter
                        ? "Hai attivato il filtro"
                        : "Non hai attivato il filtro"
                    }
                  />
                )}
                <Button
                  className={styles.desktopBtn}
                  data-test="startCall"
                  onClick={() => {
                    setCallIsStarted(true);
                    updateUserFirstEntrance.mutateAsync({ appointmentId: appointmentId! });
                    previewPublisher?.destroy();
                  }}
                >
                  Partecipa alla seduta
                </Button>
              </div>
            </div>
          </div>
        )
      )}
      {getCredentials.isSuccess && (
        <div className={styles.mobileBtn}>
          <Button
            data-test="startCall"
            onClick={() => {
              setCallIsStarted(true);
              updateUserFirstEntrance.mutateAsync({ appointmentId: appointmentId! });
              previewPublisher?.destroy();
            }}
          >
            Partecipa alla seduta
          </Button>
        </div>
      )}
    </div>
  );
};
