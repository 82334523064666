import { FunctionComponent, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import logo from "../../../../theme/images/logo.png";
import Text from "../../../atoms/Text";
import { ImageContainer } from "../ImageContainer";
import { TextContainer } from "../TextContainer";
import ContentFooter from "./Footer.json";
import styles from "./Footer.module.scss";
import { envs } from "../../../../utils/envs";

const appSiteUrl = envs.REACT_APP_SITE_URL;

enum Links {
  "Il nostro approccio" = "/il-nostro-approccio",
  "Chi siamo" = "/chi-siamo",
  // "Recensioni"="/reviews", TODO: recensioni nascoste (quando le faremo vedere modificare il file Footer.json inserendo "Recensioni" nell'array insieme a "Naviga", "Il nostro approccio", "Chi siamo")
  "Contatti" = "/contatti",
  "Numeri di emergenza" = "/contatti#emergencyNumbers",
  "Domande Frequenti" = "/faq",
}

export const Footer: FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const linksRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (linksRef.current) {
      const links = linksRef.current.querySelectorAll("li");

      links.forEach((link) => {
        link.addEventListener("click", () => {
          const linkText = link.textContent || "";
          const linkPath = Links[linkText as keyof typeof Links];
          if (`${location.pathname}${location.hash}` === linkPath) {
            window.location.reload();
          }
          window.location.href = appSiteUrl + linkPath
          // navigate(linkPath);
        });
      });
    }
  }, []);

  return (
    <div
      className={`${styles.footer} ${
        location.pathname === "/" ? styles.footerMargin : ""
      }`}
    >
      <div className={styles.footerTop}>
        <div className={styles.footerTopLeft}>
          <ImageContainer src={logo} />
          <TextContainer paragraphs={ContentFooter.paragraphs} />
        </div>
        <div ref={linksRef} className={styles.footerTopRight}>
          <TextContainer listPoints={[ContentFooter.lists[0]]} />
          <TextContainer listPoints={[ContentFooter.lists[1]]} />
        </div>
      </div>
      <div className={styles.footerBottom}>
        <div className={styles.footerBottomLeft}>
          <TextContainer
            listPoints={[
              {
                points: [
                  ...ContentFooter.lists[2].points,
                  // `v${envs.REACT_APP_VERSION}`,
                ],
              },
            ]}
          />
        </div>
        <div className={styles.footerBottomRight}>
          <Text type="span" children={ContentFooter.followUs} />
          <ImageContainer srcList={ContentFooter.icons} />
        </div>
      </div>
    </div>
  );
};
