import Footer from '../../components/landingPageComponents/components/Footer';
import {IntroductoryReviewsContent}  from "./ReviewsText";

import styles from './Reviews.module.scss';
import IntroductorySection from '../../components/landingPageComponents/sections/IntroductorySection';
import ReviewsContainer from '../../components/landingPageComponents/sections/ReviewsContainer';
import { analyticsService } from "../../utils/analyticsService";
import { useEffect } from "react";

export const Reviews = () => {

  useEffect(() => {
    analyticsService({'event': 'virtualPageview', 'pageUrl': '/recensioni', 'pageTitle': 'Reviews'});
  }, []);

  return (
    <main>
      <div className={styles.reviews}>
        <IntroductorySection content={IntroductoryReviewsContent} hasLabel/>
        <ReviewsContainer/>
        <Footer/>
      </div>
    </main>
  )
}