import { configureStore } from "@reduxjs/toolkit";
import myTherapistSlice from "./slices/myTherapistSlice";
import userSlice from "./slices/userSlice";
import infoSlice from "./slices/infoSlice";
import surveySlice from "./slices/surveySlice";
import openMobileMenuSlice from "./slices/openMobileMenuSlice";
import patientInChatSlice from "./slices/patientInChatSlice";
import onBoardingDoneSlice from "./slices/onBoardingDoneSlice";
import tabAppointmentSlice from "./slices/tabAppointmentSlice";

export const store = configureStore({
  reducer: {
    myTherapist: myTherapistSlice,
    user: userSlice,
    info: infoSlice,
    survey: surveySlice,
    menuIsOpen: openMobileMenuSlice,
    patientInChat: patientInChatSlice,
    onBoardingDone: onBoardingDoneSlice,
    tabAppointment: tabAppointmentSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
