export const getToken = (token: string) => {
  // let tokenToGet = document.cookie
  //   .split(";")
  //   .find((i) => i.includes(token));

  // const indexFromCut = tokenToGet?.indexOf("=")! + 1;
  // tokenToGet = tokenToGet?.substring(indexFromCut!);

  // return tokenToGet;
  const accessToken = localStorage.getItem(token);
  return accessToken;
};
