import { InfoCircleOutlined } from "@ant-design/icons";
import { VideoCameraIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Dispatch, useEffect, useState } from "react";
import { trpc } from "../../../utils/trpc";
import Button from "../../atoms/Button";
import ModifyAppointmentForm from "../ModifyAppointmentForm";
import styles from "./AppointmentCard.module.scss";
import FormHeader from "components/atoms/FormHeader";
import { setInfo } from "store/slices/infoSlice";
import { useAppDispatch } from "customHooks/reduxHooks";
import { raiseException } from "../../../utils/raiseException";
import { analyticsService } from "../../../utils/analyticsService";
import PatientPayment from "../../organisms/PatientPayment";

interface Props {
  className?: string;
  appointment: any;
  role: "patient" | "therapist";
  fromCalendar?: boolean;
  closeCard?: () => void;
  setModalIsVisible: Dispatch<React.SetStateAction<boolean>>;
  setModalChildren: Dispatch<any>;
  setModal?: any;
}

export const AppointmentCard = ({
  className,
  appointment,
  role,
  fromCalendar,
  closeCard,
  setModalIsVisible,
  setModalChildren,
  setModal
}: Props) => {
  const startTime = new Date(appointment?.startTime).getTime();

  const [chatiId, setChatId] = useState<string | undefined>("");
  const dispatch = useAppDispatch();

  const { patient } = appointment!;

  const myChats = trpc.mainService.chat.getChats.useQuery().data;
  const getAppointments = trpc.mainService.appointment.getAppointments.useQuery(
    {}
  );

  const getCurrentUserInfoResponse =
    trpc.mainService.user.getCurrentUserInfo.useQuery();
  const getPaymentIntent =
    trpc.mainService.payments.stripe.getPaymentIntent.useMutation();

  const myTherapist = trpc.mainService.patient.getMyTherapist.useQuery(
    undefined,
    {
      enabled: (role === "patient"),
    }
  ).data?.therapist;

  const dateDay = new Date(startTime).toLocaleString("IT", {
    weekday: "short",
    day: "2-digit",
    month: "long",
  });

  const dateStartTime = new Date(startTime).toLocaleString("IT", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const dateEndTime = new Date(appointment?.endTime).toLocaleString("IT", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const lastDayToPay = new Date(startTime).toLocaleString("IT", {
    day: "2-digit",
    month: "long",
  });

  const lastTimeToPay = new Date(startTime - 1000 * 60 * 15).toLocaleString(
    "IT",
    {
      hour: "2-digit",
      minute: "2-digit",
    }
  );

  const onPatientPayment = async (appointment: any, messageId: number) => {
    const onPayAppointment = async () => {
      getAppointments.refetch();
      // messagesList.map((i) => {
      //   if (i.appointmentId === id) {
      //     i.appointment.status = "ready";
      //   }
      //   return i;
      // });
      // setModalIsVisible(false);
      // setModalChildren(<div></div>);
    };
    const response = await getPaymentIntent.mutateAsync({
      appointmentId: appointment.id,
    });

    setModalChildren(
      <PatientPayment
        isModal
        closeModal={() => setModalIsVisible(false)}
        userInfo={getCurrentUserInfoResponse}
        myTherapist={myTherapist}
        savedPayment={
          getCurrentUserInfoResponse.data?.user?.stripeDefaultPaymentMethodId ??
          undefined
        }
        setupFutureUsage={Boolean(response.setupFutureUsage)}
        onPayAppointment={onPayAppointment}
        paymentIntentId={response.paymentIntentId}
        clientSecret={response.clientSecret!}
        amount={response.amount / 100}
        appointment={appointment}
      />
    );
    setModalIsVisible(true);
  };

  const acceptAppointment =
    trpc.mainService.appointment.acceptFreeAppointment.useMutation();

  const errorHandler = (error: any) => {
    raiseException(error);
    dispatch(
      setInfo({
        text: "Si è verificato un errore",
        variant: "error",
      })
    );
  };

  const onAccept = (appointmentId: string, messageId: number) => {
    acceptAppointment
      .mutateAsync({ appointmentId })
      .then((data) => {
        getAppointments.refetch();
        if (data.appointment) {
          const date = new Date(data.appointment.startTime).toLocaleDateString(
            "IT",
            {
              day: "2-digit",
              month: "long",
              year: "numeric",
            }
          );
          const time = new Date(data.appointment.startTime).toLocaleString(
            "IT",
            {
              hour: "2-digit",
              minute: "2-digit",
            }
          );
          setModal(date, time)
          // socket.emit("updateWidgetMessage", messageId, chatId);
        }
      })
      .catch((e) => {
        errorHandler(e);
      });
  };

  const onModify = () => {
    setModalChildren(
      <ModifyAppointmentForm
        closeModal={() => setModalIsVisible(false)}
        chatId={chatiId!}
        patientId={patient?.id}
        appointment={appointment}
        onRequestMove={() => {
          getAppointments.refetch();
          closeCard && closeCard();
        }}
        onMove={() => {
          // onUpdate();
          getAppointments.refetch();
          closeCard && closeCard();
        }}
        onCancel={() => {
          // onUpdate();
          getAppointments.refetch();
        }}
      />
    );
    setModalIsVisible(true);
  };

  useEffect(() => {
    if (myChats) {
      setChatId(myChats.chats.find((c) => c.patient?.id === patient?.id)?.id);
    }
  }, [myChats]);

  return (
    <div className={`${styles.appointmentCard} ${className}`}>
      {fromCalendar && (
        <div className={styles.closeBtnWrapper}>
          <XMarkIcon className={styles.closeBtn} onClick={closeCard} />
        </div>
      )}
      {role === "therapist" && (
        <div className={styles.patientName}>
          {patient?.given_name} {patient?.family_name}{" "}
          {!patient && "Paziente eliminato"}
        </div>
      )}
      <div className={styles.topPartMobile}>
        <div className={styles.nameDateWrapper}>
          <div className={styles.date}>
            <div className={styles.dateWrapper}>
              <span>{dateDay}</span>
              <span>
                {dateStartTime} - {dateEndTime}
              </span>
            </div>
            <div className={styles.appointmentStatusMobile}>
              {appointment.status === "ready" ? (
                appointment.isFirstAppointment ? (
                  <div className={styles.paid}>
                    <InfoCircleOutlined /> PRIMO APPUNTAMENTO
                  </div>
                ) : (
                  <div className={styles.paid}>
                    <InfoCircleOutlined /> PAGATO
                  </div>
                )
              ) : role === "patient" ? (
                appointment.isFirstAppointment ? (
                  <div className={styles.toPay}>
                    <InfoCircleOutlined /> ACCETTA ENTRO LE {lastTimeToPay} DEL{" "}
                    {lastDayToPay}
                  </div>
                ) : (
                  <div className={styles.toPay}>
                    <InfoCircleOutlined /> PAGA ENTRO LE {lastTimeToPay} DEL{" "}
                    {lastDayToPay}
                  </div>
                )
              ) : (
                <div className={styles.toPay}>
                  <InfoCircleOutlined /> DA CONFERMARE
                </div>
              )}
              {appointment?.moveRequest && (
                <div
                  className={`${styles.moveRequest} ${styles.moveRequestMobile}`}
                >
                  <InfoCircleOutlined /> RICHIESTO SPOSTAMENTO
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.topPart}>
        <div className={styles.nameDateWrapper}>
          <div className={styles.date}>
            <div>
              {dateDay} | {dateStartTime} {appointment.isFirstAppointment && role === 'patient' ? '' : ' - ' + dateEndTime}
            </div>
            {appointment.status === "ready" ? (
              appointment.isFirstAppointment ? (
                <div className={styles.paid}>
                  <InfoCircleOutlined /> PRIMO APPUNTAMENTO
                </div>
              ) : (
                <div className={styles.paid}>
                  <InfoCircleOutlined /> PAGATO
                </div>
              )
            ) : role === "patient" ? (
              appointment.isFirstAppointment ? (
                <div className={styles.toPay}>
                  <InfoCircleOutlined /> ACCETTA ENTRO LE {lastTimeToPay} DEL{" "}
                  {lastDayToPay}
                </div>
              ) : (
                <div className={styles.toPay}>
                  <InfoCircleOutlined /> PAGA ENTRO LE {lastTimeToPay} DEL{" "}
                  {lastDayToPay}
                </div>
              )
            ) : (
              <div className={styles.toPay}>
                <InfoCircleOutlined /> DA CONFERMARE
              </div>
            )}
          </div>
        </div>
        {appointment.status === "ready" ? (
          <Button
            onClick={() => {
              analyticsService({ 'event': 'paid_session_room_patient', 'appointmentID': appointment.id });
              window.open(`/seduta/${appointment.id}`, "_blank");
            }}
            leftIcon={<VideoCameraIcon />}
          >
            Collegati
          </Button>
        ) : role === "therapist" && !appointment?.createdByPatient ? (
          <Button
            disabled={appointment.status !== "ready"}
            onClick={() => {
              analyticsService({ 'event': 'paid_session_room_therapist', 'appointmentID': appointment.id });
              window.open(`/seduta/${appointment.id}`, "_blank");
            }}
            leftIcon={<VideoCameraIcon />}
          >
            Collegati
          </Button>
        ) : role === 'therapist' && appointment?.createdByPatient ? (
          <Button onClick={() => onAccept(appointment.id, 0)}>Accetta</Button>
        ) : appointment.isFirstAppointment ? (
          <Button disabled={appointment?.createdByPatient} onClick={() => onAccept(appointment.id, 0)}>Accetta</Button>
        ) : (
          <Button onClick={() => onPatientPayment(appointment, 0)}>
            Paga subito
          </Button>
        )}
      </div>
      {appointment.moveRequest && (
        <div className={`${styles.moveRequest} ${styles.moveRequestDesktop}`}>
          <InfoCircleOutlined /> RICHIESTO SPOSTAMENTO
        </div>
      )}
      <div className={styles.bottomPart}>
        <div className={styles.titleInfoCard}>Hai un imprevisto?</div>
        <div className={styles.textInfoCard}>
          Se non riesci a partecipare all’appuntamento puoi spostarlo o
          cancellarlo.
        </div>
        {appointment.status !== "completed" && (
          <Button onClick={() => onModify()} variant="tertiary">
            Sposta o cancella appuntamento
          </Button>
        )}
      </div>
      {appointment.status === "ready" ? (
        <Button
          className={styles.mobileBtn}
          onClick={() => {
            analyticsService({'event': 'paid_session_room_patient', 'appointmentID': appointment.id});
            window.open(`/seduta/${appointment.id}`, "_blank");
          }}
          leftIcon={<VideoCameraIcon/>}
        >
          Collegati
        </Button>
      ) : role === "therapist" && !appointment?.createdByPatient ? (
        <Button
          className={styles.mobileBtn}
          disabled={appointment.status !== "ready"}
          onClick={() => {
            analyticsService({'event': 'paid_session_room_therapist', 'appointmentID': appointment.id});
            window.open(`/seduta/${appointment.id}`, "_blank");
          }}
          leftIcon={<VideoCameraIcon/>}
        >
          Collegati
        </Button>
      ) : role === 'therapist' && appointment?.createdByPatient ? (
        <Button className={styles.mobileBtn} onClick={() => onAccept(appointment.id, 0)}>Accetta</Button>
      ) : appointment.isFirstAppointment ? (
        <Button className={styles.mobileBtn} disabled={appointment?.createdByPatient}
          onClick={() => onAccept(appointment.id, 0)}>Accetta</Button>
      ) : (
        <Button
          className={styles.mobileBtn}
          onClick={() => onPatientPayment(appointment, 0)}
        >
          Paga subito
        </Button>
      )}
    </div>
  );
};
