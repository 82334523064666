import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface openMobileMenuState {
  isOpen: boolean;
}
const initialState: openMobileMenuState = {
  isOpen: false,
};

const openMobileMenuSlice = createSlice({
  name: "openMobileMenu",
  initialState,
  reducers: {
    setOpenMobileMenu(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },
  },
});
export default openMobileMenuSlice.reducer;
export const { setOpenMobileMenu } = openMobileMenuSlice.actions;
