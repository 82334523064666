import { PayloadAction, createSlice } from "@reduxjs/toolkit";
export interface User {
  description: string;
  email: string;
  family_name: string;
  gender: string;
  given_name: string;
  id: string;
  phone_number: string;
  role: string;
}
interface messageState {
  user: User | undefined | null;
}
const initialState: messageState = {
  user: null,
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<any>) {
      state.user = action.payload;
    },
  },
});
export default userSlice.reducer;
export const { setUser } = userSlice.actions;