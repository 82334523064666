import InfoBox from "../InfoBox";
import styles from "./MessageWrapper.module.scss";

interface Props {
  className?: string;
  dateIsVisible: boolean;
  isWidget?: boolean;
  sender: string;
  text: string | JSX.Element | null;
  date: string;
  hour: string;
}

export const MessageWrapper = ({
  className,
  dateIsVisible,
  isWidget,
  sender,
  text,
  date,
  hour,
}: Props) => {
  const decodeText = () => {
    if (typeof text === "string" && text.includes("&moved")) {
      const infoMessage = text.split("&moved");
      return <InfoBox className={styles.movedMessage} text={infoMessage[1] + infoMessage[2]} variant="warn" />;
    }
    return (
      <div className={`${styles.message}`} data-test="message">
        {text}
      </div>
    );
  };

  return (
    <div className={`${styles.messageWrapper} ${className}`}>
      {dateIsVisible && <div className={styles.date}>{date}</div>}
      <div className={`${styles[sender]} ${isWidget && styles.widget}`}>
        {decodeText()}
        <div className={styles.hour}>{hour}</div>
      </div>
    </div>
  );
};
