import { FunctionComponent, useState } from "react";
import Button from "../../../atoms/Button";
import Input from "../../../atoms/Input";
import Text from "../../../atoms/Text";
import styles from "./Form.module.scss";
import { trpc } from "utils/trpc";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import GenericForm from "components/molecules/GenericForm";
import { useFormik } from "formik";
import * as yup from "yup";
import { raiseException } from "../../../../utils/raiseException";

export const Form: FunctionComponent = () => {
  const [isRequestSupportError, setIsRequestSupportError] = useState(false);
  const [isRequestSupportSuccess, setIsRequestSuportSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      givenName: "",
      familyName: "",
      email: "",
      text: "",
    },
    validationSchema: yup.object().shape({
      givenName: yup.string().required("Il nome è un campo obbligatorio"),
      familyName: yup.string().required("Il cognome è un campo obbligatorio"),
      email: yup
        .string()
        .trim()
        .matches(
          /^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i,
          "L'email inserita ha un formato non valido"
        )
        .required("L'email è un campo obbligatorio"),
      text: yup
        .string()
        .required(
          "Specifica un problema per poter inoltrare la richiesta di supporto"
        ),
    }),
    onSubmit: async (values, actions) => {
      try {
        await requestSupport.mutateAsync({
          givenName: values.givenName,
          familyName: values.familyName,
          email: values.email,
          content: values.text,
          fromWhere: "homepage",
        });
        setIsRequestSuportSuccess(true);
      } catch (e: any) {
        raiseException(e);
        setIsRequestSupportError(true);
      }
    },
  });

  const requestSupport = trpc.mainService.requestSupport.useMutation();
  if (requestSupport.isLoading) {
    return (
      <div className={styles.form}>
        <div className={styles.spinner}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} />
        </div>
      </div>
    );
  } else if (requestSupport.isError && isRequestSupportError) {
    return (
      <div className={styles.form}>
        <div className={styles.errorForm}>
          <GenericForm
            description={"Qualcosa è andato storto, ti preghiamo di riprovare"}
            title={"Invio messaggio"}
            cta={[
              <Button onClick={() => setIsRequestSupportError(false)}>
                Riprova
              </Button>,
            ]}
          />
        </div>
      </div>
    );
  } else if (isRequestSupportSuccess) {
    return (
      <div className={styles.form}>
        <div className={styles.errorForm}>
          <GenericForm
            description={"La tua richiesta di supporto è andata a buon fine."}
            title={"Congratulazioni!"}
          />
        </div>
      </div>
    );
  } else {
    return (
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <div className={styles.inputName}>
          <Text type="span" children="Nome" />
          <Input
            placeholder="Nome"
            name="givenName"
            value={formik.values.givenName}
            onChange={formik.handleChange}
            error={
              (formik.errors.givenName &&
                formik.touched.givenName &&
                formik.errors.givenName) as string
            }
          />
        </div>
        <div className={styles.inputSurname}>
          <Text type="span" children="Cognome" />
          <Input
            placeholder="Cognome"
            name="familyName"
            value={formik.values.familyName}
            onChange={formik.handleChange}
            error={
              (formik.errors.familyName &&
                formik.touched.familyName &&
                formik.errors.familyName) as string
            }
          />
        </div>
        <div className={styles.inputEmail}>
          <Text type="span" children="Email" />
          <Input
            placeholder="Email"
            name="email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={
              (formik.errors.email &&
                formik.touched.email &&
                formik.errors.email) as string
            }
          />
        </div>
        <div className={styles.textArea}>
          <Input
            placeholder="Scrivi qui il tuo problema"
            textarea
            name="text"
            value={formik.values.text}
            onChange={formik.handleChange}
            error={
              (formik.errors.text &&
                formik.touched.text &&
                formik.errors.text) as string
            }
          />
        </div>
        <Button children="Invia richiesta" />
      </form>
    );
  }
};
