import { forwardRef } from "react";
import styles from "./Button.module.scss";

interface Props {
  children?: any;
  type?: "button" | "submit" | "reset" | undefined;
  className?: string;
  variant?: "primary" | "secondary" | "tertiary";
  onClick?: any;
  leftIcon?: any;
  rightIcon?: any;
  disabled?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      type,
      className = "",
      variant = "primary",
      onClick,
      leftIcon,
      rightIcon,
      disabled,
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        disabled={disabled}
        onClick={onClick}
        className={`${styles.button} ${styles[variant]} ${className}`}
      >
        {leftIcon && <div className={styles.icon}>{leftIcon}</div>} {children}
        {rightIcon && <div className={styles.icon}>{rightIcon}</div>}
      </button>
    );
  }
);
