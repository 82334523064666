import { FunctionComponent } from "react";
import { PropsCard } from "../../components/Card";
import Image from "../../../atoms/Image";
import Card from "../../components/Card";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import styles from "./IntroductorySection.module.scss";

enum ClassNames {
  text = "text-area-only",
  img = "text-area-withImg",
  form = "text-area-withForm",
}

interface PropsIntroductorySection extends PropsCard {
  content: any;
  hasImage?: boolean;
  onlyText?: boolean;
  hasButton?: boolean;
  hasLabel?: boolean;
  sectionStains?: string[] | undefined;
}

export const IntroductorySection: FunctionComponent<
  PropsIntroductorySection
> = (props) => {
  const contentSection = props.content;
  const stains = props.sectionStains;
  const setClassesNames = (props: PropsIntroductorySection) => {
    if (props.onlyText || (!props.hasImage && !props.hasForm)) {
      return ClassNames.text;
    } else if (props.hasImage) {
      return ClassNames.img;
    } else {
      return ClassNames.form;
    }
  };
  return (
    <section
      className={`${styles.introductorySection} ${setClassesNames(props)}`}
    >
      <Card
        intro
        titleType="h1"
        title={contentSection.title}
        paragraphs={contentSection.paragraphs}
        label={props.hasLabel ? contentSection.label : ""}
        srcList={props.hasImage ? contentSection.images : undefined}
        hasForm={props.hasForm}
        buttonTxt={props.hasButton ? contentSection.buttonTxt : ""}
        rightIconButton={<ChevronRightIcon />}
        imageStains={props.imageStains}
        textStains={props.textStains}
        onClick={props.onClick}
        cardStains={props.cardStains}
      />
      {props.sectionStains &&
        stains?.map((stain, index) => {
          return (
            <Image key={index} className={styles.stain} src={stain} alt="" />
          );
        })}
    </section>
  );
};
