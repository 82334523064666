import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { httpLink, splitLink } from "@trpc/client";
import { ConfigProvider } from "antd";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import * as R from "ramda";
import App from "./App";
import "./index.scss";
import { store } from "./store/store";
import { getToken } from "./utils/getToken";
import { trpc } from "./utils/trpc";
import { envs } from "utils/envs";
import routes from "routes";
import { initSentry } from "./utils/sentry";
import ErrorBoundary from "./components/errorBoundary/ErrorBoundary";
import { raiseException } from "./utils/raiseException";

// const queryClient = new QueryClient();

const lastPath = btoa(window.location.pathname);
export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: async (_, query) => {
      if ((query.state.error as any)?.data?.httpStatus === 401) {
        let username: string;
        try {
          // const accessToken = localStorage.getItem('accessToken');
          const accessToken = getToken("accessToken");
          const middle = accessToken?.split(".")[1];
          username = JSON.parse(atob(middle!)).username!;
        } catch (e: any) {
          raiseException(e);
          // document.cookie = `accessToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
          // document.cookie = `refreshToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
          localStorage.removeItem("greyBlock");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("userId");

          window.location.href = `${routes.Login.path}#${lastPath}`;
          return;
        }
        const refreshToken = getToken("refreshToken");
        const response = await fetch(
          `${envs.REACT_APP_AUTH_SERVICE_URL}/trpc/authService.auth.refreshToken`,
          {
            method: "post",
            body: JSON.stringify({
              username,
              refreshToken,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status !== 200) {
          // document.cookie = `accessToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
          // document.cookie = `refreshToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
          localStorage.removeItem("greyBlock");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("userId");

          window.location.href = `${routes.Login.path}#${lastPath}`;
          return;
        }
        const result = await response.json();
        // document.cookie = `accessToken=${result.result.data.accessToken}; Path=/;`;
        localStorage.setItem("accessToken", result.result.data.accessToken);
        query.fetch();
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: async (error, variables, context, mutation) => {
      if ((error as any)?.data?.path === "authService.auth.login") {
        return;
      }
      if ((error as any)?.data?.httpStatus === 401) {
        let username: string;
        try {
          // const accessToken = localStorage.getItem('accessToken');
          const accessToken = getToken("accessToken");
          const middle = accessToken?.split(".")[1];
          username = JSON.parse(atob(middle!)).username!;
        } catch (e: any) {
          raiseException(e);
          // document.cookie = `accessToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
          // document.cookie = `refreshToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
          localStorage.removeItem("greyBlock");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("userId");

          window.location.href = `${routes.Login.path}#${lastPath}`;
          return;
        }
        const refreshToken = getToken("refreshToken");
        const response = await fetch(
          `${envs.REACT_APP_AUTH_SERVICE_URL}/trpc/authService.auth.refreshToken`,
          {
            method: "post",
            body: JSON.stringify({
              username,
              refreshToken,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status !== 200) {
          // document.cookie = `accessToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
          // document.cookie = `refreshToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
          localStorage.removeItem("greyBlock");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("userId");

          window.location.href = `${routes.Login.path}#${lastPath}`;
          return;
        }
        const result = await response.json();
        // document.cookie = `accessToken=${result.result.data.accessToken}; Path=/;`;
        localStorage.setItem("accessToken", result.result.data.accessToken);
        window.location.reload();
      }
    },
  }),

  defaultOptions: {
    queries: {
      retry: (number, error: any) => {
        if (error?.shape?.data?.httpStatus >= 500) {
          return number < 3;
        }
        return false;
      },
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
});

const cookie = () => {
  const accessToken = getToken("accessToken");

  if (accessToken) {
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  }
  return { Authorization: `Bearer ` };
};

const publicRoutes = [
  'healthcheck',
  'getAppointmentPrice',
  'getAllSpecializations',
  'getSurveys',
  'getSurvey',
  'submitSentInformedConsent',
  'getReviews',
  'generateUploadPicUrl',
  'findTrackingCampaign',
  'sendTrackingCampaign',
  'updateTrackingCampaign',
  'verifyEmail',
  'requestSupport'
];

const isPublic = (inputRoute: URL | RequestInfo) => {
  for (const route of publicRoutes) {
    if (inputRoute.toString().includes(route)) {
      return true;
    }
  }
};

const beforeFetchMiddleware: typeof fetch = async (input, init) => {
  if (isPublic(input)) {
    return fetch(input, init);
  }

  const token = R.pathOr('', ['headers', 'Authorization'], init).split(' ')[1];

  if (!token) {
    window.location.href = `${routes.Login.path}#${lastPath}`;
    throw new Error('The access token is missing');
  }

  if (!init) {
    init = {};
  }
  if (!init.headers) {
    init.headers = {};
  }

  return fetch(input, init);
};

const trpcClient = trpc.createClient({
  links: [
    splitLink({
      condition(op) {
        return op.path?.split(".")[0] === "authService";
      },
      true: httpLink({
        url: `${envs.REACT_APP_AUTH_SERVICE_URL}/trpc`,
        headers: () => cookie(),
      }),
      false: httpLink({
        url: `${envs.REACT_APP_SERVER_URL}/trpc`, // /trpc for apple pay testing on ngrok (add also "proxy": "https://3cc9-101-56-194-55.ngrok.io/" to package.json)
        headers: () => cookie(),
        fetch: beforeFetchMiddleware
      }),
    }),
  ],
});

initSentry();

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container!);

root.render(
  <trpc.Provider client={trpcClient} queryClient={queryClient}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ConfigProvider
          theme={{
            components: {
              Collapse: {
                headerBg: "#F9F9F9",
                headerPadding: 5,
              },
            },
          }}
        >
          {/* <React.StrictMode> */}
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
          {/* </React.StrictMode> */}
        </ConfigProvider>
      </Provider>
    </QueryClientProvider>
  </trpc.Provider>
);
