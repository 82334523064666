import { FunctionComponent } from "react";
import Image from "../../../atoms/Image";
import styles from "./ImageContainer.module.scss";

export interface PropsImage {
  countImages?: number;
  src?: string | undefined;
  srcList?: string[] | undefined;
  alt?: string | string[];
  imageStains?: string[] | undefined;
}
export const ImageContainer: FunctionComponent<PropsImage> = (props) => {
  const stains = props.imageStains;
  return (
    <>
      {props.src && (
        <div className={styles.imageContainer}>
          <Image src={props.src} alt="image" />
          {props.imageStains &&
            stains?.map((stain, index) => {
              return (
                <Image
                  key={index}
                  className={styles.stain}
                  src={stain}
                  alt=""
                />
              );
            })}
        </div>
      )}
      {props.srcList && (
        <div className={styles.imagesGroupContainer}>
          {props.srcList.map((src, index) => {
            if (src.includes("instagram")) {
              return (
                <div
                  key={index}
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/mymentis.it?igsh=MTd4djVqNzI1dzZ1bQ==",
                      "_blank"
                    )
                  }
                >
                  <Image src={src} alt="image" />
                </div>
              );
            } else if (src.includes("facebook")) {
              return (
                <div
                  key={index}
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/people/Mymentis/61553124825839/",
                      "_blank"
                    )
                  }
                >
                  <Image src={src} alt="image" />
                </div>
              );
            } else if (src.includes("linkedin")) {
              return (
                <div
                  key={index}
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/mymentis/mycompany/?viewAsMember=true",
                      "_blank"
                    )
                  }
                >
                  <Image src={src} alt="image" />
                </div>
              );
            }
            return (
              <div key={index}>
                <Image src={src} alt="image" />
              </div>
            );
          })}
          {props.imageStains &&
            stains?.map((stain, index) => {
              return (
                <Image
                  key={index}
                  className={styles.stain}
                  src={stain}
                  alt=""
                />
              );
            })}
        </div>
      )}
    </>
  );
};
