import { FC, useState } from "react";
import styles from "./AddCardForm.module.scss";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { envs } from "utils/envs";
import Input from "components/atoms/Input";
import { trpc } from "utils/trpc";
import Button from "components/atoms/Button";
import { raiseException } from "../../../utils/raiseException";

interface AddCardFormProps {
  clientSecret: string;
  onSuccess?: () => void;
  onError?: () => void;
}

export const AddCardForm: FC<AddCardFormProps> = ({
  clientSecret,
  onSuccess,
  onError,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getCurrentUserInfoResponse =
    trpc.mainService.user.getCurrentUserInfo.useQuery();

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      if (stripe === null || elements === null) {
        throw new Error("Empty stripe or elements");
      }

      const { error: submitError } = await elements.submit();
      if (submitError) {
        return;
      }

      if (name.trim() === "") {
        // checkName();
        return;
      }

      const response = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          return_url: envs.REACT_APP_FRONTEND_URL,
        },
        redirect: "if_required",
      });

      if (response.error) {
        throw response.error;
      }

      if (onSuccess) {
        await onSuccess();
      }
    } catch (e: any) {
      raiseException(e);
      console.error(e);
      if (onError) {
        onError();
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className={styles.AddCardForm}>
        <Input
          type="text"
          label="Intestatario"
          onChange={(e) => setName(e.target.value)}
        />
        <PaymentElement
          options={{
            paymentMethodOrder: ["card"],
            defaultValues: {
              billingDetails: {
                name,
                email: getCurrentUserInfoResponse.data?.user.email,
              },
            },
          }}
        />
        <Button disabled={!name?.trim()} onClick={handleSubmit}>
          Aggiungi
        </Button>
      </div>
    </>
  );
};
