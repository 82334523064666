import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  survey: {},
};

const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    setSurvey(state, action: PayloadAction<{}>) {
      state.survey = action.payload;
    },
  },
});
export default surveySlice.reducer;
export const { setSurvey } = surveySlice.actions;
