import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./ChangePassword.module.scss";
import GenericForm from "../../components/molecules/GenericForm";
import Input from "../../components/atoms/Input";
import Button from "../../components/atoms/Button";
import { trpc } from "../../utils/trpc";
import { useAppDispatch } from "../../customHooks/reduxHooks";
import { setInfo } from "../../store/slices/infoSlice";
import routes from "routes";
import { useFormik } from "formik";
import * as yup from "yup";
import { analyticsService } from "../../utils/analyticsService";

import { raiseException } from "../../utils/raiseException";
import trimString from "../../utils/trimString";

export const ChangePassword = ({ className = "" }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [oldPasswordType, setOldPasswordType] = useState(true);
  const [newPasswordType, setNewPasswordType] = useState(true);
  const [confirmPasswordType, setConfirmPasswordType] = useState(true);

  let [searchParams] = useSearchParams();

  const [email] = useState<string | null>(searchParams.get("email"));
  const [role] = useState<string | null>(searchParams.get("role"));

  const confirmTherapist = trpc.authService.auth.confirmTherapist.useMutation();
  const confirmSupervisedPatient =
    trpc.authService.auth.confirmSupervisedPatient.useMutation();

  useEffect(() => {
    analyticsService({'event': 'virtualPageview', 'pageUrl': '/cambio-password', 'pageTitle': 'Change Password'});
  }, []);

  const confirmTherapistFunction = ({
    oldPassword,
    newPassword,
  }: {
    oldPassword: string;
    newPassword: string;
  }) => {
    if (role === "therapist") {
      confirmTherapist
        .mutateAsync({
          email: trimString(email!),
          newPassword: trimString(newPassword),
          temporaryPassword: trimString(oldPassword),
        })
        .then((data) => {
          dispatch(
            setInfo({
              variant: "confirm",
              text: "Password aggiornata con successo",
              title: "Aggiornamento password",
            })
          );
          navigate(routes.Login.path);
        })
        .catch((e) => {
          raiseException(e);
          dispatch(
            setInfo({
              variant: "error",
              text: e.message,
              title: "Errore aggiornamento password",
            })
          )
          }
        );
    } else {
      confirmSupervisedPatient
        .mutateAsync({
          email: trimString(email!),
          newPassword: trimString(newPassword),
          temporaryPassword: trimString(oldPassword),
        })
        .then((data) => {
          dispatch(
            setInfo({
              variant: "confirm",
              text: "Password aggiornata con successo",
              title: "Aggiornamento password",
            })
          );
          navigate(routes.Login.path);
        })
        .catch((e) => {
          raiseException(e);
          dispatch(
            setInfo({
              variant: "error",
              text: e.message,
              title: "Errore aggiornamento password",
            })
          )
          }
        );
    }
  };

  useEffect(() => {
    if (!email) {
      navigate(routes.Login.path);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      checkNewPassword: "",
    },
    validationSchema: yup.object().shape({
      password: yup
        .string()
        .required("La password temporanea è un campo obbligatorio"),
      newPassword: yup
        .string()
        .matches(
          /^(?=.*[a-z])(?=.*\d)[^\s]{8,}$/,
          "La password non rispetta i criteri di sicurezza"
        )
        .required("La nuova password è un campo obbligatorio"),
      checkNewPassword: yup
        .string()
        .oneOf([yup.ref("newPassword")], "Le due password non combaciano")
        .required("Inserisci di nuovo la nuova password"),
    }),
    onSubmit: (values, actions) => {
      confirmTherapistFunction({
        oldPassword: values.password,
        newPassword: values.newPassword,
      });
    },
  });

  return (
    <div className={`${styles.changePassword} ${className}`}>
      <GenericForm
        className={styles.form}
        answer={[
          <form
            className={styles.changePasswordForm}
            onSubmit={formik.handleSubmit}
          >
            <Input
              type={oldPasswordType ? "password" : "text"}
              label="Password temporanea"
              placeholder="Password temporanea"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={
                (formik.errors.password &&
                  formik.touched.password &&
                  formik.errors.password) as string
              }
              className={styles.password}
              rightIcon={
                <p
                  className={styles.showPw}
                  onClick={() => {
                    setOldPasswordType(!oldPasswordType);
                  }}
                >
                  {oldPasswordType ? "Mostra" : "Nascondi"}
                </p>
              }
            />
            <Input
              type={newPasswordType ? "password" : "text"}
              label="Nuova password"
              placeholder="Nuova password"
              name="newPassword"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              error={
                (formik.errors.newPassword &&
                  formik.touched.newPassword &&
                  formik.errors.newPassword) as string
              }
              className={styles.password}
              rightIcon={
                <p
                  className={styles.showPw}
                  onClick={() => {
                    setNewPasswordType(!newPasswordType);
                  }}
                >
                  {newPasswordType ? "Mostra" : "Nascondi"}
                </p>
              }
            />
            <Input
              type={confirmPasswordType ? "password" : "text"}
              label="Conferma nuova password"
              placeholder="Conferma password"
              name="checkNewPassword"
              value={formik.values.checkNewPassword}
              onChange={formik.handleChange}
              error={
                (formik.errors.checkNewPassword &&
                  formik.touched.checkNewPassword &&
                  formik.errors.checkNewPassword) as string
              }
              className={styles.password}
              rightIcon={
                <p
                  className={styles.showPw}
                  onClick={() => {
                    setConfirmPasswordType(!confirmPasswordType);
                  }}
                >
                  {confirmPasswordType ? "Mostra" : "Nascondi"}
                </p>
              }
            />
            <div className={styles.pwInfo}>
              La password deve contenere almeno 8 caratteri di cui:{" "}
              <p>un carattere maiuscolo e un carattere numerico</p>
            </div>
            <Button className={styles.confirmBtn} type="submit">
              Conferma
            </Button>
          </form>,
        ]}
        title={"Imposta la tua nuova password"}
        description={"Come primo accesso imposta la tua nuova password"}
      />
    </div>
  );
};
