import Menu from "components/organisms/Menu";
import { useEffect, useState } from "react";
import Avatar from "../../atoms/Avatar";
import styles from "./MobileHeader.module.scss";
import Modal from "components/organisms/Modal";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "customHooks/reduxHooks";
import { setPatientName } from "store/slices/patientInChatSlice";
import { UserIcon } from "@heroicons/react/24/solid";
import routes from "routes";
import Tabs from "components/atoms/Tabs";
import { CalendarIcon, ListBulletIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { setTabAppointment } from "store/slices/tabAppointmentSlice";
import { envs } from "../../../utils/envs";

interface Props {
  openProfile?: any;
  profileIsOpen?: boolean;
  chatWith?: string;
  profileDataId: string;
  goBack?: () => void;
}
export const MobileHeader = ({
  openProfile,
  profileIsOpen,
  chatWith,
  profileDataId,
  goBack,
}: Props) => {
  const appointmentType = useAppSelector(
    (store) => store.tabAppointment.listType
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search } = location;
  const patientName = useAppSelector((store) => store.patientInChat);
  chatWith = chatWith ? chatWith : "Chat";

  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);

  return (
    <div
      className={`${styles.mobileHeader} ${
        mobileMenuIsOpen && styles.incraseZindex
      }`}
    >
      <div
        className={styles.menuIconWrapper}
        onClick={() => {
          if (profileIsOpen) {
            openProfile(false);
            return;
          }
          if (
            pathname.includes(routes.Profile.path) &&
            pathname !== routes.Profile.path
          ) {
            navigate(routes.Profile.path);
          } else if (patientName.name?.trim() !== "") {
            dispatch(setPatientName({ name: "", surname: "", id: "" }));
          } else {
            setMobileMenuIsOpen(true);
          }
        }}
      >
        {(pathname.includes(routes.Profile.path) &&
          pathname !== routes.Profile.path) ||
        patientName.name?.trim() !== "" ? (
          <ChevronLeftIcon width={35} />
        ) : (
          <>
            <div className={styles.icon}></div>
            <div className={styles.word}>Menu</div>
          </>
        )}
      </div>
      <div className={styles.username}>
        {pathname === routes.Chat.path && patientName.name?.trim() !== ""
          ? `${patientName.name ?? ""} ${patientName.surname ?? ""}`
          : pathname === routes.Chat.path
          ? chatWith
          : pathname === routes.Appointments.path
          ? "Appuntamenti"
          : pathname === routes.Profile.path
          ? "Profilo"
          : pathname === routes.ProfileSection.links.info
          ? "Informazioni personali"
          : pathname === routes.ProfileSection.links.payment
          ? "Metodo di pagamento"
          : pathname === routes.ProfileSection.links.preferences
          ? "Gestione preferenze"
          : pathname === routes.ProfileSection.links.reviews
          ? "Recensioni"
          : pathname === routes.ProfileSection.links.billing
          ? "Dati di fatturazione"
          : pathname === routes.FaqPage.path
          ? "Assistenza"
          : ""}
      </div>
      {pathname === routes.Appointments.path && (
        <div className={styles.therapistTabWrapper}>
          <Tabs
            className={styles.therapistTab}
            activeLabelClassName={styles.customActiveLabel}
            labelClassName={styles.customLabelWrapper}
            items={[
              {
                key: 1,
                active: appointmentType === "list",
                label: (
                  <div
                    className={styles.tabLabel}
                    onClick={() => dispatch(setTabAppointment("list"))}
                  >
                    <div className={styles.icon}>
                      <ListBulletIcon />
                    </div>
                    <span>Lista</span>
                  </div>
                ),
                content: <div></div>,
              },
              {
                key: 2,
                active: appointmentType === "calendar",
                label: (
                  <div
                    className={styles.tabLabel}
                    onClick={() => dispatch(setTabAppointment("calendar"))}
                  >
                    <div className={styles.icon}>
                      <CalendarIcon />
                    </div>
                    <span>Calendario</span>
                  </div>
                ),
                content: <div></div>,
              },
            ]}
          ></Tabs>
        </div>
      )}
      {(chatWith !== "Chat" || patientName.name?.trim() !== "") &&
      pathname === routes.Chat.path && (
        <div
          onClick={() => {
            openProfile(true);
            // dispatch(
            //   setPatientName({
            //     name: patientName.name,
            //     surname: patientName.surname,
            //     id: patientName.id,
            //   })
            // );
          }}
        >
          {
            chatWith !== "Chat" ?
              < Avatar className={styles.profileIcon}
                       avatarImg={`${envs.REACT_APP_S3_ENDPOINT}/pic-${profileDataId}`}/> :
              <div className={styles.iconWrapper}>
                <InformationCircleIcon/>
              </div>
          }
        </div>
        )}
      {/* {mobileMenuIsOpen && (
        <Modal> */}
      <Menu
        closeMenu={() => setMobileMenuIsOpen(false)}
        className={
          mobileMenuIsOpen ? styles.openMobileMenu : styles.closeMobileMenu
        }
      />
      {/* </Modal>
      )} */}
    </div>
  );
};
