import Button from "components/atoms/Button";
import styles from "./TherapistPreferences.module.scss";
import Input from "components/atoms/Input";
import { trpc } from "utils/trpc";
import { useEffect, useRef, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { getCPS } from "utils/general";
import { useAppDispatch } from "customHooks/reduxHooks";
import { setInfo } from "store/slices/infoSlice";
import Modal from "../Modal";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import GenericForm from "components/molecules/GenericForm";
import FormHeader from "components/atoms/FormHeader";
import { analyticsService } from "../../../utils/analyticsService";
import { raiseException } from "../../../utils/raiseException";
import { envs } from "../../../utils/envs";

export const TherapistPreferences = () => {
  const [stopNewMatches, setStopNewMatches] = useState(false);
  const [stopNewMatchesConfirmModal, setStopNewMatchesConfirmModal] =
    useState(false);
  const [timeSlots, setTimeSlots] = useState<any[]>([]);
  const [daySlots, setDaySlots] = useState<any[]>([]);
  const [specializations, setSpecializations] = useState<any[]>([]);
  const [guidelineName, setGuidelineName] = useState<string>('');
  const [maxWeeklyHoursAvailability, setMaxWeeklyHoursAvailability] =
    useState(0);
  const [minWeeklyHoursAvailability, setMinWeeklyHoursAvailability] =
    useState(0);
  const dispatch = useAppDispatch();

  useEffect(() => {
    analyticsService({
      'event': 'virtualPageview',
      'pageUrl': '/dashboard/profilo/preferenze',
      'pageTitle': 'Therapist Preferences'
    });
  }, []);

  const updatePreferences =
    trpc.mainService.therapist.updatePreferences.useMutation();

  const { data: therapistPreferences, refetch: refetchPreferences } =
    trpc.mainService.therapist.getPreferences.useQuery();

  const getAllSpecializationsResponse =
    trpc.mainService.therapist.getAllSpecializations.useQuery();

  const onUpdatePreferences = () => {
    updatePreferences
      .mutateAsync({
        stopNewMatches,
        timeSlots,
        daySlots,
        specializations,
        maxWeeklyHoursAvailability,
        minWeeklyHoursAvailability,
      })
      .then((data) => {
        refetchPreferences();
        setDaySlots(data.preferences.daySlots!);
        setSpecializations(data.preferences.specializations!);
        setTimeSlots(data.preferences.timeSlots!);
        setMaxWeeklyHoursAvailability(
          data.preferences.maxWeeklyHoursAvailability!
        );
        setMinWeeklyHoursAvailability(
          data.preferences.minWeeklyHoursAvailability!
        );
        setStopNewMatches(data.preferences.stopNewMatches!);
        dispatch(
          setInfo({ text: "Dati aggiornati con successo", variant: "confirm" })
        );
      })
      .catch((e) => {
        dispatch(
          setInfo({
            variant: "error",
            text: "Si è verificato un errore durante il salvataggio delle informazioni",
          })
        );
        raiseException(e);
        console.error("ERROR", e?.shape?.message);
      });
  };

  const updateStopMatch = () => {
    updatePreferences
      .mutateAsync({
        stopNewMatches: !stopNewMatches,
      })
      .then((data) => {
        refetchPreferences();
        setStopNewMatches(data.preferences.stopNewMatches!);
        setStopNewMatchesConfirmModal(false);
      })
      .catch((e) => {
        setStopNewMatchesConfirmModal(false);
        dispatch(
          setInfo({
            variant: "error",
            text: "Si è verificato un errore durante il salvataggio delle informazioni",
          })
        );
        raiseException(e);
        console.error("ERROR", e?.shape?.message);
      });
  };

  useEffect(() => {
    if (therapistPreferences) {
      setStopNewMatches(therapistPreferences.preferences?.stopNewMatches!);
      setMinWeeklyHoursAvailability(
        therapistPreferences.preferences?.minWeeklyHoursAvailability!
      );
      setMaxWeeklyHoursAvailability(
        therapistPreferences.preferences?.maxWeeklyHoursAvailability!
      );
      setDaySlots(therapistPreferences.preferences?.daySlots!);
      setSpecializations(therapistPreferences.preferences?.specializations!);
      setTimeSlots(therapistPreferences.preferences?.timeSlots!);
      setGuidelineName(therapistPreferences.preferences?.['guideline_name']);
    }
  }, [therapistPreferences]);

  return (
    <div className={styles.therapistPreferences}>
      {updatePreferences.isLoading && (
        <Modal className="loadingModal">
          <Spin
            indicator={
              <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
            }
          />
        </Modal>
      )}
      {stopNewMatchesConfirmModal && (
        <Modal>
          <GenericForm
            className={styles.stopNewMatchesModal}
            title={
              <FormHeader
                closeModal={() => setStopNewMatchesConfirmModal(false)}
                title={stopNewMatches ? "Riprendi match" : "Interrompi match"}
                closeButton
              />
            }
            description={
              stopNewMatches ? (
                <div>
                  <p>Sei sicuro di voler riabilitare i match?</p>
                  <p>Nuovi pazienti potranno essere associati a te</p>
                </div>
              ) : (
                <div>
                  <p>Sei sicuro di voler interrompere i match?</p>
                  <p>
                    Non potrai più ricevere pazienti fino a quando non lo
                    riattiverai
                  </p>
                </div>
              )
            }
            cta={[
              <Button
                onClick={() => setStopNewMatchesConfirmModal(false)}
                variant="secondary"
              >
                Annulla
              </Button>,
              <Button onClick={() => updateStopMatch()}>Prosegui</Button>,
            ]}
          />
        </Modal>
      )}
      <h1 className={styles.title}>Gestione preferenze</h1>
      <div className={styles.guidelinesWrapper}>
        <span>Linee guida:</span>
        <a href={`${envs.REACT_APP_S3_ENDPOINT}/${guidelineName}`} target="_blank" rel="noreferrer">
          <Button
            variant="primary"
          >
            Scarica il PDF
          </Button>
        </a>
      </div>
      <div className={styles.infoBox}>
        <div className={styles.textPart}>
          <div className={styles.textTitle}>Interrompi nuovi "match"</div>
          <div className={styles.description}>
            Nel caso non potessi più gestire nuovi match, potrai interrompere
            tramite questo pulsante. Interrompendo, non ti verranno più
            assegnati nuovi pazienti. Potrai riprendere quando vuoi.
          </div>
        </div>
        <Button
          className={stopNewMatches ? styles.resumeBtn : styles.stopBtn}
          variant="secondary"
          onClick={() => setStopNewMatchesConfirmModal(true)}
        >
          {stopNewMatches ? "Riprendi" : "Interrompi"}
        </Button>
      </div>
      <div className={styles.preferenceCard}>
        <div className={styles.preferenceTitle}>Competenze cliniche</div>
        <div className={styles.preferenceDescription}>
          Questa è la lista delle competenze cliniche che hai indicato al
          momento della registrazione. Se necessario puoi aggiornarla, ma cerca
          di farlo il meno possibile al fine di rendere più efficace l’algoritmo
          di matching
        </div>
        <div className={styles.cpMultiselect}>
          <Multiselect
            onSelect={(_, selectedSlot) => {
              setSpecializations([...specializations, selectedSlot.value]);
            }}
            onRemove={(_, deselectedSlot) => {
              setSpecializations(
                specializations.filter((s) => s !== deselectedSlot.value)
              );
            }}
            displayValue="name"
            showCheckbox
            placeholder="Competenze cliniche"
            selectedValues={therapistPreferences?.preferences?.specializations?.map(
              (specialization) => ({
                name: getCPS([specialization]).join(", "),
                value: specialization,
              })
            )}
            options={getAllSpecializationsResponse.data?.specializations.map(
              (specialization) => ({
                name: getCPS([specialization.id]).join(", "),
                value: specialization.id,
              })
            )}
          />
          <XMarkIcon className={styles.closeIcon} />
        </div>
      </div>
      <div className={styles.preferenceCard}>
        <div className={styles.preferenceTitle}>
          Disponibilità per fasce orarie
        </div>
        <div className={styles.preferenceDescription}>
          Queste sono le fasce orarie di disponibilità che hai indicato in fase
          di registrazione. È importante tenerle aggiornate nel caso di
          variazioni. Potrai modificarle ogni volta che vuoi, ma cerca di
          variarle il meno possibile al fine di rendere più efficace l'algoritmo
          di matching.
        </div>

        <Multiselect
          onSelect={(_, selectedSlot) => {
            setTimeSlots([...timeSlots, selectedSlot.value]);
          }}
          onRemove={(_, deselectedSlot) => {
            setTimeSlots(timeSlots.filter((s) => s !== deselectedSlot.value));
          }}
          displayValue="name"
          showCheckbox
          placeholder=""
          showArrow
          customArrow={<ChevronDownIcon />}
          selectedValues={timeSlots.map((d) => {
            if (d === "morning") {
              return { name: "Mattina (8:00 - 13:00)", value: "morning" };
            }
            if (d === "afternoon") {
              return { name: "Pomeriggio (13:00 - 18:00)", value: "afternoon" };
            }
            if (d === "late_afternoon") {
              return { name: "Tardo pomeriggio (18:00 - 20:00)", value: "late_afternoon" };
            }
            if (d === "evening") {
              return { name: "Sera (20:00 - 22:00)", value: "evening" };
            }
          })}
          options={[
            { name: "Mattina (8:00 - 13:00)", value: "morning" },
            { name: "Pomeriggio (13:00 - 18:00)", value: "afternoon" },
            { name: "Tardo pomeriggio (18:00 - 20:00)", value: "late_afternoon" },
            { name: "Sera (20:00 - 22:00)", value: "evening" }
          ]}
        />
      </div>
      <div className={styles.preferenceCard}>
        <div className={styles.preferenceTitle}>Disponibilità giorni</div>
        <div className={styles.preferenceDescription}>
          Questi sono i giorni in cui preferisci incontrare i pazienti. È
          importante tenere aggiornate le tue disponibilità in caso di
          variazioni. Potrai modificarle ogni volta che vuoi, ma cerca di farlo
          il meno possibile al fine di rendere più efficace l’algoritmo di
          matching
        </div>
        <Multiselect
          onSelect={(_, selectedSlot) => {
            setDaySlots([...daySlots, selectedSlot.value]);
          }}
          onRemove={(_, deselectedSlot) => {
            setDaySlots(daySlots.filter((s) => s !== deselectedSlot.value));
          }}
          displayValue="name"
          showCheckbox
          placeholder=""
          showArrow
          customArrow={<ChevronDownIcon />}
          selectedValues={daySlots.map((d) => {
            if (d === "week") {
              return { name: "Lun - Ven", value: "week" };
            }
            if (d === "saturday") {
              return { name: "Sabato", value: "saturday" };
            }
            if (d === "sunday") {
              return { name: "Domenica", value: "sunday" };
            }
          })}
          options={[
            { name: "Lun - Ven", value: "week" },
            { name: "Sabato", value: "saturday" },
            { name: "Domenica", value: "sunday" },
          ]}
        />
      </div>
      <div className={styles.preferenceCard}>
        <div className={styles.preferenceTitle}>
          Ore settimanali disponibili (Min/Max)
        </div>
        <div className={styles.preferenceDescription}>
          Queste sono le ore minime e massime che potrai svolgere nelle terapie
          settimanali. Potrai aggiornare questo dato ogni volta che vuoi.
        </div>
        <Input
          select
          onChange={(e) => {
            const value = JSON.parse(e.target.value);
            setMinWeeklyHoursAvailability(value.min);
            setMaxWeeklyHoursAvailability(value.max);
          }}
          value={`{ "min": ${minWeeklyHoursAvailability}, "max": ${maxWeeklyHoursAvailability} }`}
          options={[
            { name: "6 ore - 12 ore", value: '{ "min": 6, "max": 12 }' },
            { name: "12 ore - 18 ore", value: '{ "min": 12, "max": 18 }' },
            { name: "18 ore - 24 ore", value: '{ "min": 18, "max": 24 }' },
            { name: "24 ore - 30 ore", value: '{ "min": 24, "max": 30 }' },
            { name: "30 ore - 36 ore", value: '{ "min": 30, "max": 36 }' },
          ]}
        />
      </div>
      <div className={styles.btnWrapper}>
        <Button
          variant="secondary"
          onClick={() => {
            if (therapistPreferences) {
              setStopNewMatches(
                therapistPreferences.preferences?.stopNewMatches!
              );
              setMinWeeklyHoursAvailability(
                therapistPreferences.preferences?.minWeeklyHoursAvailability!
              );
              setMaxWeeklyHoursAvailability(
                therapistPreferences.preferences?.maxWeeklyHoursAvailability!
              );
              setDaySlots(therapistPreferences.preferences?.daySlots!);
              setSpecializations(
                therapistPreferences.preferences?.specializations!
              );
              setTimeSlots(therapistPreferences.preferences?.timeSlots!);
            }
          }}
        >
          Annulla
        </Button>
        <Button
          disabled={updatePreferences.isLoading}
          onClick={() => onUpdatePreferences()}
        >
          Salva preferenze
        </Button>
      </div>
    </div>
  );
};
