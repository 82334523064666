import Button from "../../atoms/Button";
import styles from "./PatientPastAppointment.module.scss";

interface Props {
  invoiceDate: string;
  appointmentDate: string;
  therapist: string;
  price: string;
}

export const PatientPastAppointment = ({
  invoiceDate,
  appointmentDate,
  therapist,
  price,
}: Props) => {
  invoiceDate = new Date(invoiceDate).toLocaleDateString("it", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });

  appointmentDate = new Date(appointmentDate).toLocaleDateString("it", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });

  return (
    <div className={styles.patientPastAppointment}>
      <div className={styles.date}>
        <div className={styles.title}>Data seduta</div>
        <div className={styles.descrition}>{appointmentDate}</div>
      </div>
      <div className={styles.therapist}>
        <div className={styles.title}>Terapeuta</div>
        <div className={styles.descrition}>{therapist}</div>
      </div>
      <div className={styles.price}>
        <div className={styles.title}>Costo</div>
        <div className={styles.descrition}>
          {price ? `${price} €` : "Appuntamento gratuito"}
        </div>
      </div>
      <div>
        {/* <Button variant="tertiary">Scarica</Button>  TODO: nascondere in prima fase*/}
      </div>
    </div>
  );
};
