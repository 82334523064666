import { ReactNode } from "react";
import styles from "./VideoCallButton.module.scss";

interface Props {
  className?: string;
  icon: string | Element | any;
  text: string;
  subtext: string;
  onClick?: () => void;
}

export const VideoCallButton = ({
  className = "",
  icon,
  text,
  subtext,
  onClick,
}: Props) => {
  return (
    <div className={`${styles.videoCallButton} ${className}`} onClick={onClick}>
      <div className={styles.iconWrapper}>
        {typeof icon === "string" && <img src={icon} className={styles.icon} />}
        {typeof icon === 'object' && <span>{icon as any}</span>}
      </div>
      <div className={styles.textWrapper}>
        <div className={styles.text}>{text}</div>
        <div className={styles.subtext}>{subtext}</div>
      </div>
    </div>
  );
};
