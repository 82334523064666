import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  listType: "list" as "calendar" | "list",
};

const tabAppointemntslice = createSlice({
  name: "tabAppointemnt",
  initialState,
  reducers: {
    setTabAppointment(state, action: PayloadAction<"calendar" | "list">) {
      state.listType = action.payload;
    },
  },
});
export default tabAppointemntslice.reducer;
export const { setTabAppointment } = tabAppointemntslice.actions;
