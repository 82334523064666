import { FunctionComponent, useState } from "react";
import Text from "../../../atoms/Text";
import CardReview from "../../../molecules/CardReview";
import ContentReviewContainer from "./ReviewsContainer.json";
import styles from "./ReviewsContainer.module.scss";

export const ReviewsContainer: FunctionComponent = () => {
	const [reviewsShown, setReviewsShown] = useState(9);

  const takeDecimalNumber = (float:number):number => {
    const result = float.toFixed(1);
    return parseFloat(result);
  }

	return (
		<section className={styles.reviewsContainer}>
			<div>
      <div className={styles.columns}>
					<div className={styles.reviews}>
						{ContentReviewContainer.reviews.map(
							(review: any, index: number) => {
								if (index < reviewsShown && index % 3 === 0) {
									return (
										<CardReview
											key={index}
											vote={review.vote}
											reviewText={review.reviewText}
											reviewTitle={review.reviewTitle}
										/>
									);
								}
							}
						)}
					</div>
				<div className={styles.reviews}>
          {ContentReviewContainer.reviews.map(
							(review: any, index: number) => {
								if (index < reviewsShown && takeDecimalNumber((index / 3) % 1)===0.3) {
									return (
										<CardReview
											key={index}
											vote={review.vote}
											reviewText={review.reviewText}
											reviewTitle={review.reviewTitle}
										/>
									);
								}
							}
						)}
        </div>
				<div className={styles.reviews}>
          {ContentReviewContainer.reviews.map(
							(review: any, index: number) => {
								if (index < reviewsShown && takeDecimalNumber((index / 3) % 1) === 0.7) {
									return (
										<CardReview
											key={index}
											vote={review.vote}
											reviewText={review.reviewText}
											reviewTitle={review.reviewTitle}
										/>
									);
								}
							}
						)}
        </div>
			</div>
      </div>
			<div onClick={() => setReviewsShown(reviewsShown + 3)}>
				<Text type="span" children="Carica altre" />
			</div>
		</section>
	);
};
