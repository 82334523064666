export const analyticsService = (event: object): any => {
  // @ts-ignore
  if (Array.isArray(window.dataLayer)) {

    // @ts-ignore
    window.dataLayer = window.dataLayer || [];

    // @ts-ignore
    window.dataLayer.push(event);
  }
}
